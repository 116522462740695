/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	InternshipCompetenceDTO,
	InternshipCompetenceDTOFromJSON,
	InternshipCompetenceDTOFromJSONTyped,
	InternshipCompetenceDTOToJSON,
} from "./InternshipCompetenceDTO";
import {
	InternshipEngagementType,
	InternshipEngagementTypeFromJSON,
	InternshipEngagementTypeFromJSONTyped,
	InternshipEngagementTypeToJSON,
} from "./InternshipEngagementType";
import {
	StudentYearEnum,
	StudentYearEnumFromJSON,
	StudentYearEnumFromJSONTyped,
	StudentYearEnumToJSON,
} from "./StudentYearEnum";

/**
 *
 * @export
 * @interface CompanyInternshipAddUpdateDTO
 */
export interface CompanyInternshipAddUpdateDTO {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	name?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	companyDepartment?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	description?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	shortDescription?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	period?: number;
	/**
	 *
	 * @type {number}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	totalPracticeHours?: number;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	availableFrom: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	availableTo: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	startingDate: Date;
	/**
	 *
	 * @type {number}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	initialAvailablePositions: number;
	/**
	 *
	 * @type {boolean}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	isPaid: boolean;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	skillsRequired?: Array<string> | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	category?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	location?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	placeId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	tutorId?: string;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	faculties?: Array<string> | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	departments?: Array<string> | null;
	/**
	 *
	 * @type {Array<string>}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	studyPrograms?: Array<string> | null;
	/**
	 *
	 * @type {Array<StudentYearEnum>}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	years?: Array<StudentYearEnum> | null;
	/**
	 *
	 * @type {InternshipEngagementType}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	engagementType: InternshipEngagementType;
	/**
	 *
	 * @type {Array<InternshipCompetenceDTO>}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	internshipCompetences?: Array<InternshipCompetenceDTO> | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	facultyCoordinatorId?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	viewCount?: number;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternshipAddUpdateDTO
	 */
	internshipLink?: string | null;
}

export function CompanyInternshipAddUpdateDTOFromJSON(
	json: any
): CompanyInternshipAddUpdateDTO {
	return CompanyInternshipAddUpdateDTOFromJSONTyped(json, false);
}

export function CompanyInternshipAddUpdateDTOFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyInternshipAddUpdateDTO {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		name: !exists(json, "name") ? undefined : json["name"],
		companyDepartment: !exists(json, "companyDepartment")
			? undefined
			: json["companyDepartment"],
		description: !exists(json, "description") ? undefined : json["description"],
		shortDescription: !exists(json, "shortDescription")
			? undefined
			: json["shortDescription"],
		period: !exists(json, "period") ? undefined : json["period"],
		totalPracticeHours: !exists(json, "totalPracticeHours")
			? undefined
			: json["totalPracticeHours"],
		availableFrom: new Date(json["availableFrom"]),
		availableTo: new Date(json["availableTo"]),
		startingDate: new Date(json["startingDate"]),
		initialAvailablePositions: json["initialAvailablePositions"],
		isPaid: json["isPaid"],
		skillsRequired: !exists(json, "skillsRequired")
			? undefined
			: json["skillsRequired"],
		category: !exists(json, "category") ? undefined : json["category"],
		location: !exists(json, "location") ? undefined : json["location"],
		placeId: !exists(json, "placeId") ? undefined : json["placeId"],
		tutorId: !exists(json, "tutorId") ? undefined : json["tutorId"],
		faculties: !exists(json, "faculties") ? undefined : json["faculties"],
		departments: !exists(json, "departments") ? undefined : json["departments"],
		studyPrograms: !exists(json, "studyPrograms")
			? undefined
			: json["studyPrograms"],
		years: !exists(json, "years")
			? undefined
			: json["years"] === null
			? null
			: (json["years"] as Array<any>).map(StudentYearEnumFromJSON),
		engagementType: InternshipEngagementTypeFromJSON(json["engagementType"]),
		internshipCompetences: !exists(json, "internshipCompetences")
			? undefined
			: json["internshipCompetences"] === null
			? null
			: (json["internshipCompetences"] as Array<any>).map(
					InternshipCompetenceDTOFromJSON
			  ),
		facultyCoordinatorId: !exists(json, "facultyCoordinatorId")
			? undefined
			: json["facultyCoordinatorId"],
		viewCount: !exists(json, "viewCount") ? undefined : json["viewCount"],
		internshipLink: !exists(json, "internshipLink")
			? undefined
			: json["internshipLink"],
	};
}

export function CompanyInternshipAddUpdateDTOToJSON(
	value?: CompanyInternshipAddUpdateDTO | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		name: value.name,
		companyDepartment: value.companyDepartment,
		description: value.description,
		shortDescription: value.shortDescription,
		period: value.period,
		totalPracticeHours: value.totalPracticeHours,
		availableFrom: value.availableFrom.toISOString(),
		availableTo: value.availableTo.toISOString(),
		startingDate: value.startingDate.toISOString(),
		initialAvailablePositions: value.initialAvailablePositions,
		isPaid: value.isPaid,
		skillsRequired: value.skillsRequired,
		category: value.category,
		location: value.location,
		placeId: value.placeId,
		tutorId: value.tutorId,
		faculties: value.faculties,
		departments: value.departments,
		studyPrograms: value.studyPrograms,
		years:
			value.years === undefined
				? undefined
				: value.years === null
				? null
				: (value.years as Array<any>).map(StudentYearEnumToJSON),
		engagementType: InternshipEngagementTypeToJSON(value.engagementType),
		internshipCompetences:
			value.internshipCompetences === undefined
				? undefined
				: value.internshipCompetences === null
				? null
				: (value.internshipCompetences as Array<any>).map(
						InternshipCompetenceDTOToJSON
				  ),
		facultyCoordinatorId: value.facultyCoordinatorId,
		viewCount: value.viewCount,
		internshipLink: value.internshipLink,
	};
}
