import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
	useIsAdministrativeAccordsResponsible,
	useIsDean,
	useIsFacultyContactsResponsible,
	useIsRector,
} from "../../../utils/utilFunctions";
import {
	deleteCompanyUser,
	getAllCompanyUsersV2,
} from "../../../Requests/company-users-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import CustomButton from "../../../CustomComponents/CustomButton";
import { theme } from "../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useQuery, useQueryClient } from "react-query";
import { getCompanyUsers } from "../../../utils/reactQueriesConstants";
import { Pagination, Row, Table, Typography } from "antd";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import { CompanyUserDTO } from "../../../Api";
import { FilterByTextInput } from "../../BaseComponents";
import { usePagination } from "../../../Hooks";
import { AddUpdateCompanyUserModalForm } from "../../Forms";
import { CompanyContactViewProps } from "./CompanyContactView.types";
import useQueryFilters from "../../../Hooks/useQueryFilters";

export const CompanyContactsView = (props: CompanyContactViewProps) => {
	const { t } = useTranslation();
	const { currentPage, pageSize, handleTablePaginationChange } = usePagination(
		1,
		10
	);
	const [searchTerm, setSearchTerm] = useState("");
	const { companyId } = useParams<{ companyId: string }>();
	const [selectedUser, setSelectedUser] = useState<CompanyUserDTO | null>(null);
	const [removeUserModalVisibility, setRemoveUserModalVisibility] =
		useState(false);
	const [spinning, setSpinning] = useState(false);
	const isRector = useIsRector();
	const isDean = useIsDean();
	const isAdministrativeAccordsResponsible =
		useIsAdministrativeAccordsResponsible();
	const isFacultyContactsResponsible = useIsFacultyContactsResponsible();
	const query = useQueryFilters({});

	const locale = {
		emptyText: t("tableText.noCompanies"),
	};

	const queryClient = useQueryClient();
	const invalidateUsersQuery = async () => {
		await queryClient.invalidateQueries(getCompanyUsers);
	};

	const handleDelete = (id: string) => {
		setSpinning(true);

		deleteCompanyUser(id)
			.then(() => {
				invalidateUsersQuery();

				openNotification(
					t("account.userDeleted"),
					t("account.userDeletedMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((ex: any) => {
				if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
					openNotification(
						t("account.conflict"),
						t("account.notFoundUser"),
						NOTIFICATION_TYPES.ERROR
					);
				} else if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
					openNotification(
						t("account.error"),
						t("account.notAllowed"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("account.error"),
						t("account.unknownError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			})
			.finally(() => {
				setSpinning(false);
				setRemoveUserModalVisibility(false);
			});
	};

	const columns = [
		{
			title: t("account.name"),
			dataIndex: "name",
			key: "name",
			fixed: "left" as "left",
			// sorter: (a: CompanyUserDTO, b: CompanyUserDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
		},
		{
			title: t("account.email"),
			dataIndex: "email",
			key: "email",
			// sorter: (a: CompanyUserDTO, b: CompanyUserDTO) => {
			// 	if (!a.email || !b.email) return -1;
			// 	return a.email.localeCompare(b.email);
			// },
		},
		{
			title: t("account.phone"),
			dataIndex: "phone",
			key: "phone",
			// sorter: (a: CompanyUserDTO, b: CompanyUserDTO) => {
			// 	if (!a.phone || !b.phone) return -1;
			// 	return a.phone.localeCompare(b.phone);
			// },
		},
		{
			title: t("account.function"),
			dataIndex: "_function",
			key: "function",
			// sorter: (a: CompanyUserDTO, b: CompanyUserDTO) => {
			// 	if (!a._function || !b._function) return -1;
			// 	return a._function.localeCompare(b._function);
			// },
		},
		{
			title: t("account.department"),
			dataIndex: "department",
			key: "department",
			// sorter: (a: CompanyUserDTO, b: CompanyUserDTO) => {
			// 	if (!a.department || !b.department) return -1;
			// 	return a.department.localeCompare(b.department);
			// },
		},
		{
			title: t("account.actions"),
			fixed: "right" as "right",
			width: 85,
			render: (_text: string | undefined, record: any) => (
				<div style={{ display: "inline-flex" }}>
					{record.isContact && (
						<AddUpdateCompanyUserModalForm type={"edit"} userId={record.id} />
					)}
					{record.isContact && (
						<CustomButton
							fontSize={"0.9rem"}
							style={{
								background: "transparent",
								border: "none",
								outline: "none",
								color: theme.black,
								boxShadow: "none",
								padding: 0,
								marginTop: 0,
							}}
							onClick={() => {
								setSelectedUser(record);
								setRemoveUserModalVisibility(true);
							}}
							icon={<FontAwesomeIcon icon={solid("trash")} />}
							title={t("account.deleteUser")}
						/>
					)}
				</div>
			),
		},
	];

	const { data: users, isLoading } = useQuery(
		[getCompanyUsers, query.filters],
		() => {
			return getAllCompanyUsersV2({
				companyId,
				...query.filters,
			}).catch(() =>
				openNotification(
					t("account.error"),
					t("usersText.errorTexts.failedUserGet"),
					NOTIFICATION_TYPES.ERROR
				)
			);
		}
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 pb-10 flex flex-col">
			<ConfirmationModal
				modalText={t("account.deleteUserMessage") + selectedUser?.name + "?"}
				handleFunction={() => handleDelete(selectedUser?.id ?? "")}
				modalVisibility={removeUserModalVisibility}
				title={selectedUser?.name ?? ""}
				changeModalVisibility={() => setRemoveUserModalVisibility(false)}
				spinning={spinning}
			/>
			{(isRector ||
				isAdministrativeAccordsResponsible ||
				isDean ||
				isFacultyContactsResponsible) && (
				<div style={{ width: "100%" }}>
					<Row
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<AddUpdateCompanyUserModalForm type={"add"} userId={companyId} />
						{props.location && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginTop: "4%",
								}}
							>
								<Link
									to={{
										pathname: "/companii-contact",
										state: {
											existingFilters: props.location.state.filters,
											oldSearchTerm: props.location.state.searchTerm,
											currentPage: props.location.state.currentPage,
											pageSize: props.location.state.pageSize,
										},
									}}
								>
									<CustomButton
										paddingvertical={"19px"}
										boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
									>
										{t("profile.goBack")}
									</CustomButton>
								</Link>
							</div>
						)}
					</Row>
					<div className="flex flex-col gap-10">
						<div className="flex flex-col md:flex-row justify-between items-center">
							<Typography.Title level={3} className="my-0">
								{users?.totalCount} rezultate
							</Typography.Title>
							{!isLoading && (
								<Pagination
									defaultCurrent={users?.page}
									defaultPageSize={users?.pageSize}
									total={users?.totalCount}
									onChange={onPageUpdate}
									locale={{ items_per_page: t("pagination") }}
								/>
							)}
						</div>

						<Table
							locale={locale}
							columns={columns}
							dataSource={users?.data ?? []}
							pagination={false}
							scroll={{ x: "calc(700px + 50%)", y: 420 }}
							rowKey={(record) => record.id!}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
