/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	Company,
	CompanyFromJSON,
	CompanyFromJSONTyped,
	CompanyToJSON,
} from "./Company";
import {
	CompanyDocument,
	CompanyDocumentFromJSON,
	CompanyDocumentFromJSONTyped,
	CompanyDocumentToJSON,
} from "./CompanyDocument";
import {
	CompanyFacultyAccord,
	CompanyFacultyAccordFromJSON,
	CompanyFacultyAccordFromJSONTyped,
	CompanyFacultyAccordToJSON,
} from "./CompanyFacultyAccord";
import {
	Department,
	DepartmentFromJSON,
	DepartmentFromJSONTyped,
	DepartmentToJSON,
} from "./Department";
import {
	FacultyInternship,
	FacultyInternshipFromJSON,
	FacultyInternshipFromJSONTyped,
	FacultyInternshipToJSON,
} from "./FacultyInternship";
import {
	InternshipFaculty,
	InternshipFacultyFromJSON,
	InternshipFacultyFromJSONTyped,
	InternshipFacultyToJSON,
} from "./InternshipFaculty";
import {
	InternshipLimits,
	InternshipLimitsFromJSON,
	InternshipLimitsFromJSONTyped,
	InternshipLimitsToJSON,
} from "./InternshipLimits";
import {
	PracticeInternshipPeriod,
	PracticeInternshipPeriodFromJSON,
	PracticeInternshipPeriodFromJSONTyped,
	PracticeInternshipPeriodToJSON,
} from "./PracticeInternshipPeriod";
import {
	StudyProgram,
	StudyProgramFromJSON,
	StudyProgramFromJSONTyped,
	StudyProgramToJSON,
} from "./StudyProgram";

/**
 *
 * @export
 * @interface Faculty
 */
export interface Faculty {
	/**
	 *
	 * @type {string}
	 * @memberof Faculty
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof Faculty
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof Faculty
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof Faculty
	 */
	nameRo?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof Faculty
	 */
	nameEn?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof Faculty
	 */
	acronym?: string | null;
	/**
	 *
	 * @type {number}
	 * @memberof Faculty
	 */
	externalId?: number;
	/**
	 *
	 * @type {string}
	 * @memberof Faculty
	 */
	decisionNumber?: string | null;
	/**
	 *
	 * @type {boolean}
	 * @memberof Faculty
	 */
	active?: boolean;
	/**
	 *
	 * @type {Array<Department>}
	 * @memberof Faculty
	 */
	departments?: Array<Department> | null;
	/**
	 *
	 * @type {Array<FacultyInternship>}
	 * @memberof Faculty
	 */
	facultyInternships?: Array<FacultyInternship> | null;
	/**
	 *
	 * @type {Array<CompanyFacultyAccord>}
	 * @memberof Faculty
	 */
	companyFacultyAccords?: Array<CompanyFacultyAccord> | null;
	/**
	 *
	 * @type {Array<CompanyDocument>}
	 * @memberof Faculty
	 */
	companyFacultyDocuments?: Array<CompanyDocument> | null;
	/**
	 *
	 * @type {Array<InternshipFaculty>}
	 * @memberof Faculty
	 */
	internshipFaculties?: Array<InternshipFaculty> | null;
	/**
	 *
	 * @type {Array<StudyProgram>}
	 * @memberof Faculty
	 */
	studyPrograms?: Array<StudyProgram> | null;
	/**
	 *
	 * @type {Array<PracticeInternshipPeriod>}
	 * @memberof Faculty
	 */
	practicePeriods?: Array<PracticeInternshipPeriod> | null;
	/**
	 *
	 * @type {Array<InternshipLimits>}
	 * @memberof Faculty
	 */
	internshipLimits?: Array<InternshipLimits> | null;
	/**
	 *
	 * @type {Array<Company>}
	 * @memberof Faculty
	 */
	interestedCompanies?: Array<Company> | null;
}

export function FacultyFromJSON(json: any): Faculty {
	return FacultyFromJSONTyped(json, false);
}

export function FacultyFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): Faculty {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		nameRo: !exists(json, "nameRo") ? undefined : json["nameRo"],
		nameEn: !exists(json, "nameEn") ? undefined : json["nameEn"],
		acronym: !exists(json, "acronym") ? undefined : json["acronym"],
		externalId: !exists(json, "externalId") ? undefined : json["externalId"],
		decisionNumber: !exists(json, "decisionNumber")
			? undefined
			: json["decisionNumber"],
		active: !exists(json, "active") ? undefined : json["active"],
		departments: !exists(json, "departments")
			? undefined
			: json["departments"] === null
			? null
			: (json["departments"] as Array<any>).map(DepartmentFromJSON),
		facultyInternships: !exists(json, "facultyInternships")
			? undefined
			: json["facultyInternships"] === null
			? null
			: (json["facultyInternships"] as Array<any>).map(
					FacultyInternshipFromJSON
			  ),
		companyFacultyAccords: !exists(json, "companyFacultyAccords")
			? undefined
			: json["companyFacultyAccords"] === null
			? null
			: (json["companyFacultyAccords"] as Array<any>).map(
					CompanyFacultyAccordFromJSON
			  ),
		companyFacultyDocuments: !exists(json, "companyFacultyDocuments")
			? undefined
			: json["companyFacultyDocuments"] === null
			? null
			: (json["companyFacultyDocuments"] as Array<any>).map(
					CompanyDocumentFromJSON
			  ),
		internshipFaculties: !exists(json, "internshipFaculties")
			? undefined
			: json["internshipFaculties"] === null
			? null
			: (json["internshipFaculties"] as Array<any>).map(
					InternshipFacultyFromJSON
			  ),
		studyPrograms: !exists(json, "studyPrograms")
			? undefined
			: json["studyPrograms"] === null
			? null
			: (json["studyPrograms"] as Array<any>).map(StudyProgramFromJSON),
		practicePeriods: !exists(json, "practicePeriods")
			? undefined
			: json["practicePeriods"] === null
			? null
			: (json["practicePeriods"] as Array<any>).map(
					PracticeInternshipPeriodFromJSON
			  ),
		internshipLimits: !exists(json, "internshipLimits")
			? undefined
			: json["internshipLimits"] === null
			? null
			: (json["internshipLimits"] as Array<any>).map(InternshipLimitsFromJSON),
		interestedCompanies: !exists(json, "interestedCompanies")
			? undefined
			: json["interestedCompanies"] === null
			? null
			: (json["interestedCompanies"] as Array<any>).map(CompanyFromJSON),
	};
}

export function FacultyToJSON(value?: Faculty | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		nameRo: value.nameRo,
		nameEn: value.nameEn,
		acronym: value.acronym,
		externalId: value.externalId,
		decisionNumber: value.decisionNumber,
		active: value.active,
		departments:
			value.departments === undefined
				? undefined
				: value.departments === null
				? null
				: (value.departments as Array<any>).map(DepartmentToJSON),
		facultyInternships:
			value.facultyInternships === undefined
				? undefined
				: value.facultyInternships === null
				? null
				: (value.facultyInternships as Array<any>).map(FacultyInternshipToJSON),
		companyFacultyAccords:
			value.companyFacultyAccords === undefined
				? undefined
				: value.companyFacultyAccords === null
				? null
				: (value.companyFacultyAccords as Array<any>).map(
						CompanyFacultyAccordToJSON
				  ),
		companyFacultyDocuments:
			value.companyFacultyDocuments === undefined
				? undefined
				: value.companyFacultyDocuments === null
				? null
				: (value.companyFacultyDocuments as Array<any>).map(
						CompanyDocumentToJSON
				  ),
		internshipFaculties:
			value.internshipFaculties === undefined
				? undefined
				: value.internshipFaculties === null
				? null
				: (value.internshipFaculties as Array<any>).map(
						InternshipFacultyToJSON
				  ),
		studyPrograms:
			value.studyPrograms === undefined
				? undefined
				: value.studyPrograms === null
				? null
				: (value.studyPrograms as Array<any>).map(StudyProgramToJSON),
		practicePeriods:
			value.practicePeriods === undefined
				? undefined
				: value.practicePeriods === null
				? null
				: (value.practicePeriods as Array<any>).map(
						PracticeInternshipPeriodToJSON
				  ),
		internshipLimits:
			value.internshipLimits === undefined
				? undefined
				: value.internshipLimits === null
				? null
				: (value.internshipLimits as Array<any>).map(InternshipLimitsToJSON),
		interestedCompanies:
			value.interestedCompanies === undefined
				? undefined
				: value.interestedCompanies === null
				? null
				: (value.interestedCompanies as Array<any>).map(CompanyToJSON),
	};
}
