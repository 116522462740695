import { useState, useEffect } from "react";
import { theme } from "../../theme";
import { Button, Checkbox, Form, Input, Select } from "antd";
import nophoto from "../../Media/nophoto.jpg";
import {
	useGetCompanyId,
	getUserId,
	useIsStudent,
	useIsProfessor,
	useIsCompany,
	usePushNotifications,
} from "../../utils/utilFunctions";
import {
	groupPattern,
	phonePattern,
	urlPattern,
	userSkills,
} from "../../utils/constants";
import {
	acceptGDPRDate,
	getProfessorById,
	getStudentById,
	toggleAcademicUserNotifications,
	updateProfessorInfo,
	updateStudentInfo,
} from "../../Requests/academic-user-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	getCompanyById,
	updateCompanyInfo,
} from "../../Requests/company-requests";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { studentIdSet } from "../Student/StudentSlice";
import CompanyUserTable from "./CompanyUserTable";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "react-query";
import { getDepartmentsForFaculty } from "../../Requests/department-requests";
import { ErrorCodes, PreferredLanguageEnum } from "../../Api";
import {
	getCurrentStudentStudyProgram,
	getStudyPrograms,
	setCurrentStudentStudyProgram,
} from "../../Requests/student-study-requests";
import {
	getCompanyAccountInfo,
	getCurrentStudentStudyProgramInfo,
	getFacultyDepartments,
	getProfessorAccountInfo,
	getStudentAccountInfo,
	getStudyProgramsInfo,
} from "../../utils/reactQueriesConstants";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ImageCropModal from "./ImageCropModal";
import { getErrorFromResponse } from "../../utils/responseUtils";
import CustomEditor from "../../CustomComponents/CustomEditor";
import PersonalDataModal from "../Student/PersonalDataModal";
import { Routes } from "../../utils/routes";

const { Option } = Select;

const MyAccount = () => {
	const { t, i18n } = useTranslation();
	const [state, setState] = useState({} as any);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [profilePicture, setProfilePicture] = useState<string | null>("");
	const [lowResPicture, setLowResPicture] = useState<string | null>("");
	const [cropImageModalVisibility, setCropImageModalVisibility] =
		useState(false);
	const dispatch = useDispatch();
	const [skills, setSkills] = useState<string[]>(new Array<string>());
	const [areasOfInterest, setAreasOfInterest] = useState<string[]>(
		new Array<string>()
	);
	const [checked, setChecked] = useState(false);
	const [form] = Form.useForm<{
		phone: string;
		linkedInUrl?: string | null;
		name?: string | null;
		email: string;
		personalEmail: string;
		domainOfActivity?: string | null;
		researchField?: string | null;
		skills?: string[] | null;
		areasOfInterest?: string[] | null;
		description?: string | null;
		gitUrl?: string | null;
		siteUrl?: string | null;
		cvUrl?: string | null;
		department?: string;
		studyProgram?: string;
		group?: string | null;
		preferredLanguage?: string;
		address?: string;
		postalCode?: string;
	}>();
	const isStudent = useIsStudent();
	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();
	const companyId = useGetCompanyId();
	const [spinning, setSpinning] = useState(false);
	const queryClient = useQueryClient();

	const history = useHistory();
	const redirectToProfile = () => {
		dispatch(studentIdSet(getUserId()));
		isStudent
			? history.push(Routes.STUDENT_PROFILE)
			: history.push(Routes.PROFILE);
	};

	const {
		pushNotificationSupported,
		userSubscription,
		subscribeToPushNotifications,
		unsubscribeFromPushNotifications,
	} = usePushNotifications();

	const onCheckChange = (e: CheckboxChangeEvent) => {
		setChecked(e.target.checked);
		acceptGDPRDate(e.target.checked)
			.then((e) => successMessage())
			.catch((e) => saveChangesError());
	};

	const openDataErrorNotification = (_error: any) => {
		openNotification(
			t("account.error"),
			t("account.fetchUserDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: departmentList } = useQuery(
		[getFacultyDepartments, isProfessor, isStudent],
		async () =>
			isStudent || isProfessor ? await getDepartmentsForFaculty() : null,
		{
			onError: (err) => {
				openDataErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: companyState } = useQuery(
		[getCompanyAccountInfo, isCompany],
		() => {
			if (isCompany) return getCompanyById(companyId);
		},
		{
			onSuccess: (response) => {
				setState(response);
				if (response) {
					if (isCompany) {
						form.setFieldsValue({
							...form.getFieldsValue(),
							name: response.commercialName,
							email: response.email ? response.email : "",
							address: response.address ? response.address : "",
							postalCode: response.postalCode ? response.postalCode : "",
							phone: response.phone ? response.phone : "",
							domainOfActivity: response.domainOfActivity,
							areasOfInterest: response.areasOfInterest,
							siteUrl: response.siteUrl,
							linkedInUrl: response.linkedInUrl,
							description: response.description,
							preferredLanguage:
								response.preferredLanguage === PreferredLanguageEnum.Ro
									? t("account.romanian")
									: t("account.english"),
						} as any);
					}
				}
			},
			onError: openDataErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: studentState } = useQuery(
		[getStudentAccountInfo, departmentList, isStudent],
		() => {
			if (isStudent) return getStudentById(getUserId());
		},
		{
			onSuccess: (response) => {
				if (response) {
					setState(response);
					setChecked(response.hasAcceptedPublicProfile!);
					if (isStudent) {
						const responseSkills = response.userSkills?.map((x) => x.skill!);
						form.setFieldsValue({
							...form.getFieldsValue(),
							name: response.name,
							email: response.email || "",
							personalEmail: response.personalEmail || "",
							phone: response.phone || "",
							cvUrl: response.cvUrl,
							gitUrl: response.gitUrl,
							linkedInUrl: response.linkedInUrl,
							description: response.description,
							skills: responseSkills,
							department: departmentList?.find(
								(d) => d.departmentNameEn === response.departmentNameEn
							)?.id,
							group: response.group,
						} as any);
					}
				}
			},
			onError: openDataErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: studyPrograms } = useQuery(
		[getStudyProgramsInfo, isStudent, studentState?.facultyId],
		async () =>
			isStudent ? await getStudyPrograms(studentState?.facultyId!) : null,
		{
			onError: (err) => {
				openDataErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: currentStudyProgram } = useQuery(
		[getCurrentStudentStudyProgramInfo, isStudent, form],
		() => {
			if (isStudent) return getCurrentStudentStudyProgram();
		},
		{
			onSuccess: (response) => {
				if (response) {
					if (isStudent) {
						form.setFieldsValue({
							...form.getFieldsValue(),
							studyProgram: response.studyProgram?.id,
						} as any);
					}
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: professorState } = useQuery(
		[getProfessorAccountInfo, departmentList, isProfessor],
		() => {
			if (isProfessor) {
				return getProfessorById(getUserId());
			}
		},
		{
			onSuccess: (response) => {
				setState(response);
				if (response) {
					if (isProfessor) {
						form.setFieldsValue({
							...form.getFieldsValue(),
							name: response.name,
							email: response.email || "",
							personalEmail: response.personalEmail || "",
							phone: response.phone || "",
							linkedInUrl: response.linkedInUrl,
							siteUrl: response.siteUrl,
							researchField: response.researchField,
							areasOfInterest: response.areasOfInterest,
							description: response.description,
							department: departmentList?.find(
								(d) => d.departmentNameEn === response.departmentNameEn
							)?.id,
						} as any);
					}
				}
			},
			onError: openDataErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const saveChangesError = () => {
		openNotification(
			t("account.saveError"),
			t("account.saveChangesError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const missingFieldsError = () => {
		openNotification(
			t("account.saveError"),
			t("usersText.requiredFieldsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const successMessage = () => {
		openNotification(
			t("account.success"),
			t("account.successSaveMessage"),
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const changeLng = (event: any) => {
		setState({
			...state,
			preferredLanguage: event.toUpperCase(),
		});
	};

	const handleSave = () => {
		form
			.validateFields()
			.then(() => {
				let promise: Promise<void> | null = null;
				setSpinning(true);

				if (isStudent) {
					promise = updateStudentInfo(getUserId(), {
						description: state?.description ?? "",
						phone: state?.phone ?? "",
						personalEmail: state?.personalEmail ?? "",
						cvUrl: state?.cvUrl,
						gitUrl: state?.gitUrl,
						linkedInUrl: state?.linkedInUrl ?? "",
						fullResImage: state?.fullResImage,
						lowResImage: state?.lowResImage,
						departmentId: form.getFieldValue("department"),
						group: state?.group ?? "",
						skills,
					});

					setCurrentStudentStudyProgram(
						form.getFieldValue("studyProgram")
					).catch(saveChangesError);
				} else if (isCompany) {
					promise = updateCompanyInfo(companyId, {
						commercialName: state?.name,
						phone: state?.phone ?? "",
						description: state?.description,
						emailContact: state?.email,
						domainOfActivity: state?.domainOfActivity,
						siteUrl: state?.siteUrl,
						linkedInUrl: state?.linkedInUrl,
						fullResImage: state?.fullResLogo,
						lowResImage: state?.lowResLogo,
						preferredLanguage: state?.preferredLanguage,
						areasOfInterest,
						address: state.address,
						postalCode: state.postalCode,
					});
				} else if (isProfessor) {
					promise = updateProfessorInfo(getUserId(), {
						description: state?.description ?? "",
						phone: state?.phone ?? "",
						personalEmail: state?.personalEmail ?? "",
						linkedInUrl: state?.linkedInUrl ?? "",
						siteUrl: state?.siteUrl,
						researchField: state?.researchField,
						fullResImage: state?.fullResImage,
						lowResImage: state?.lowResImage,
						departmentId: form.getFieldValue("department"),
						areasOfInterest,
					});
				}

				promise
					?.then(() => {
						successMessage();
						redirectToProfile();
					})
					?.catch(async (error: any) => {
						const errorMessage = await getErrorFromResponse(error);

						let notificationMessage =
							errorMessage?.code === ErrorCodes.Forbidden
								? t("account.cannotUpdateProfile")
								: t("account.saveChangesError");

						openNotification(
							t("account.saveError"),
							notificationMessage,
							NOTIFICATION_TYPES.ERROR
						);
					})
					.finally(() => {
						setSpinning(false);
						setModalVisibility(false);
					});
			})
			.catch(missingFieldsError)
			.finally(() => setModalVisibility(false));
	};

	const handleChangeSkills = (value: any) => {
		setSkills(value);
	};

	const handleChangeAreasOfInterest = (value: any) => {
		setAreasOfInterest(value);
	};

	const handleChange = (event: any, field: any) => {
		event.persist();
		setState({
			...state,
			[field]: event.target.value,
		});
	};

	const onNotificationToggle = (e: CheckboxChangeEvent) => {
		toggleAcademicUserNotifications()
			.then(async () => {
				if (isStudent) {
					await queryClient.invalidateQueries(getStudentAccountInfo);
				} else {
					await queryClient.invalidateQueries(getProfessorAccountInfo);
				}

				if (e.target.checked) {
					openNotification(
						t("account.notificationsEnabled"),
						t("account.notificationsEnabledMessage"),
						NOTIFICATION_TYPES.SUCCESS
					);
				} else {
					openNotification(
						t("account.notificationsDisabled"),
						t("account.notificationsDisabledMessage"),
						NOTIFICATION_TYPES.SUCCESS
					);
				}
			})
			.catch(() => {
				openNotification(
					t("account.error"),
					t("account.unknownError"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const onPushNotificationToggle = async (e: CheckboxChangeEvent) => {
		if (e.target.checked && pushNotificationSupported) {
			await subscribeToPushNotifications();
		} else if (!e.target.checked) {
			await unsubscribeFromPushNotifications();
		}
	};

	useEffect(() => {
		if (isCompany) {
			setState({
				...state,
				fullResLogo: profilePicture,
				lowResLogo: lowResPicture,
			});
		} else {
			setState({
				...state,
				fullResImage: profilePicture,
				lowResImage: lowResPicture,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profilePicture, lowResPicture]);

	return (
		<div className="flex flex-col">
			<div className="flex gap-20 flex-col items-center lg:flex-row lg:items-start max-w-[728px] py-20 px-4 mx-auto">
				<div className="flex flex-col gap-10">
					<div className="relative">
						<ImageCropModal
							changeProfilePicture={setProfilePicture}
							changeLowResPicture={setLowResPicture}
							modalVisibility={cropImageModalVisibility}
							changeModalVisibility={setCropImageModalVisibility}
						/>

						{state && (
							<img
								src={
									!isCompany
										? state?.fullResImage ?? state?.lowResImage ?? nophoto
										: state?.fullResLogo ?? state?.lowResLogo ?? nophoto
								}
								alt="Profile"
								className="w-[172px] h-[172px] rounded-full"
							/>
						)}

						<div className="absolute bottom-0 translate-y-1/2 right-0 flex gap-2">
							<Button
								danger
								onClick={() => {
									setProfilePicture(null);
									setLowResPicture(null);
								}}
								title={t("account.deletePicture")}
							>
								<FontAwesomeIcon icon={solid("trash")} />
							</Button>

							<Button
								type="primary"
								onClick={() => setCropImageModalVisibility(true)}
								title={t("account.changePicture")}
							>
								<FontAwesomeIcon icon={solid("camera")} />
							</Button>
						</div>
					</div>

					<div className="flex flex-col gap-6">
						<Button onClick={history.goBack}>
							{t("profile.backToInternships")}
						</Button>

						<Button
							htmlType="submit"
							style={{
								background: theme.secondColor,
								color: theme.white,
								boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
							}}
							onClick={() => setModalVisibility(true)}
						>
							{t("account.save")}
						</Button>
					</div>

					{isStudent && <PersonalDataModal type="edit" />}

					<ConfirmationModal
						modalText={t("account.saveMessage")}
						handleFunction={handleSave}
						modalVisibility={modalVisibility}
						title=""
						changeModalVisibility={() => setModalVisibility(false)}
						spinning={spinning}
					/>
				</div>

				<div className="flex flex-col gap-4">
					<div className="flex flex-col gap-2">
						{isStudent && (
							<Checkbox checked={checked} onChange={onCheckChange}>
								{t("modal.publicProfileAccept")}
							</Checkbox>
						)}

						{(isStudent || isProfessor) && (
							<Checkbox
								checked={
									isStudent
										? studentState?.sendNotifications
										: professorState?.sendNotifications
								}
								onChange={(e: CheckboxChangeEvent) => onNotificationToggle(e)}
							>
								{t("modal.receiveNotifications")}
							</Checkbox>
						)}

						<Checkbox
							disabled={!pushNotificationSupported}
							checked={userSubscription !== null}
							onChange={async (e: CheckboxChangeEvent) =>
								await onPushNotificationToggle(e)
							}
						>
							{t("modal.receivePushNotifications")}
						</Checkbox>
					</div>

					<Form.Provider onFormFinish={handleSave}>
						<Form form={form} layout={theme.layout} initialValues={state}>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 w-full">
								<Form.Item
									name="name"
									label={
										!isCompany
											? t("account.name") + ":"
											: t("account.commercialName") + ":"
									}
									rules={[
										{
											required: isCompany,
											message: isCompany
												? t("account.requiredField", {
														field: t("account.commercialName"),
												  })
												: "",
										},
									]}
								>
									<Input
										disabled={!isCompany}
										onChange={(event: any) => handleChange(event, "name")}
									/>
								</Form.Item>

								{isCompany && (
									<Form.Item
										name="preferredLanguage"
										label={t("account.preferredLanguage") + ":"}
										rules={[
											{
												whitespace: true,
											},
										]}
									>
										<Select onChange={changeLng}>
											<Option value="ro">{t("account.romanian")}</Option>
											<Option value="en">{t("account.english")}</Option>
										</Select>
									</Form.Item>
								)}

								<Form.Item
									name="email"
									label={t("account.contactEmail") + ":"}
									rules={[
										{
											required: isCompany,
											message: t("account.requiredField", {
												field: t("account.contactEmail"),
											}),
											whitespace: true,
										},
										{
											type: "email",
											message: t("usersText.invalidField", {
												field: t("account.contactEmail"),
											}),
										},
									]}
								>
									<Input
										disabled={!isCompany}
										onChange={(event: any) => handleChange(event, "email")}
									/>
								</Form.Item>

								{(isStudent || isProfessor) && (
									<Form.Item
										name="personalEmail"
										label={t("account.personalEmail") + ":"}
										rules={[
											{
												type: "email",
												message: t("usersText.invalidField", {
													field: t("account.email"),
												}),
											},
										]}
									>
										<Input
											onChange={(event: any) =>
												handleChange(event, "personalEmail")
											}
										/>
									</Form.Item>
								)}

								<Form.Item
									name="phone"
									label={t("account.phone") + ":"}
									rules={[
										{
											pattern: phonePattern,
											message: t("usersText.invalidField", {
												field: t("account.phone"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) => handleChange(event, "phone")}
									/>
								</Form.Item>

								{isCompany && (
									<>
										<Form.Item
											name="address"
											label={t("account.address") + ":"}
											rules={[
												{
													required: true,
													message: t("account.requiredField", {
														field: t("account.address"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input
												onChange={(event: any) =>
													handleChange(event, "address")
												}
											/>
										</Form.Item>

										<Form.Item
											name="postalCode"
											label={t("account.postalCode") + ":"}
											rules={[
												{
													required: true,
													message: t("account.requiredField", {
														field: t("account.postalCode"),
													}),
													whitespace: true,
												},
											]}
										>
											<Input
												onChange={(event: any) =>
													handleChange(event, "postalCode")
												}
											/>
										</Form.Item>
									</>
								)}

								{isStudent && (
									<>
										<Form.Item
											name="department"
											label={t("account.department") + ":"}
											rules={[
												{
													required: true,
													message: t("account.requiredField", {
														field: t("account.department"),
													}),
												},
											]}
										>
											<Select allowClear placeholder={t("account.department")}>
												{departmentList?.map((department) => (
													<Option key={department.id} value={department.id}>
														{i18n.language === "en"
															? department.departmentNameEn
															: department.departmentNameRo}
													</Option>
												))}
											</Select>
										</Form.Item>

										<Form.Item
											name="studyProgram"
											label={t("account.studyProgram") + ":"}
											rules={[
												{
													required: true,
													message: t("account.requiredField", {
														field: t("account.studyProgram"),
													}),
												},
											]}
										>
											<Select
												allowClear
												placeholder={t("account.studyProgram")}
											>
												{studyPrograms?.map((studyProgram) => (
													<Option key={studyProgram.id} value={studyProgram.id}>
														{studyProgram.name}
													</Option>
												))}
											</Select>
										</Form.Item>

										<Form.Item
											name="group"
											label={t("account.group") + ":"}
											rules={[
												{
													required: true,
													message: t("account.requiredField", {
														field: t("account.group"),
													}),
													whitespace: true,
												},
												{
													pattern: groupPattern,
													message: t("account.invalidGroup"),
												},
											]}
										>
											<Input
												onChange={(event: any) => handleChange(event, "group")}
											/>
										</Form.Item>
									</>
								)}

								{isStudent && (
									<>
										<Form.Item
											name="cvUrl"
											label={t("account.cvUrl") + ":"}
											rules={[
												{
													whitespace: true,
												},
												{
													pattern: urlPattern,
													message: t("usersText.invalidField", {
														field: t("account.cvUrl"),
													}),
												},
											]}
										>
											<Input
												onChange={(event: any) => handleChange(event, "cvUrl")}
											/>
										</Form.Item>

										<Form.Item
											name="gitUrl"
											label={t("account.githubLink") + ":"}
											rules={[
												{
													whitespace: true,
												},
												{
													pattern: urlPattern,
													message: t("usersText.invalidField", {
														field: t("account.githubLink"),
													}),
												},
											]}
										>
											<Input
												onChange={(event: any) => handleChange(event, "gitUrl")}
											/>
										</Form.Item>
									</>
								)}
								{isProfessor && (
									<Form.Item
										name="department"
										label={t("account.department") + ":"}
										rules={[
											{
												required: true,
												message: t("account.requiredField", {
													field: t("account.department"),
												}),
											},
										]}
									>
										<Select allowClear placeholder={t("account.department")}>
											{departmentList?.map((department) => (
												<Option key={department.id} value={department.id}>
													{i18n.language === "en"
														? department.departmentNameEn
														: department.departmentNameRo}
												</Option>
											))}
										</Select>
									</Form.Item>
								)}
								{isCompany && (
									<Form.Item
										name="domainOfActivity"
										label={t("account.activityDomain") + ":"}
									>
										<Input
											onChange={(event: any) =>
												handleChange(event, "domainOfActivity")
											}
										/>
									</Form.Item>
								)}
								{isProfessor && (
									<Form.Item
										name="researchField"
										label={t("account.researchDomain") + ":"}
									>
										<Input
											onChange={(event: any) =>
												handleChange(event, "researchField")
											}
										/>
									</Form.Item>
								)}
								{(isProfessor || isCompany) && (
									<Form.Item
										name="siteUrl"
										label={t("account.siteLink") + ":"}
										rules={[
											{
												required: isCompany,
												message: isCompany
													? t("account.requiredField", {
															field: t("account.siteLink"),
													  })
													: "",
												whitespace: true,
											},
											{
												pattern: urlPattern,
												message: t("usersText.invalidField", {
													field: t("account.siteLink"),
												}),
											},
										]}
									>
										<Input
											onChange={(event: any) => handleChange(event, "siteUrl")}
										/>
									</Form.Item>
								)}
								{(isStudent || isCompany) && (
									<Form.Item
										name="linkedInUrl"
										label={
											(isStudent
												? t("account.linkedinLink")
												: t("account.companyLinkedin")) + ":"
										}
										rules={[
											{
												required: isCompany,
												message: isCompany
													? t("account.requiredField", {
															field: t("account.companyLinkedin"),
													  })
													: "",
												whitespace: true,
											},
											{
												pattern: urlPattern,
												message: t("usersText.invalidField", {
													field: t("account.link"),
												}),
											},
										]}
									>
										<Input
											onChange={(event: any) =>
												handleChange(event, "linkedInUrl")
											}
										/>
									</Form.Item>
								)}
							</div>
						</Form>
					</Form.Provider>
				</div>
			</div>

			<Form
				form={form}
				layout={theme.layout}
				initialValues={{ description: state?.description }}
				className="px-4"
			>
				{isStudent && (
					<Form.Item name="skills" label={t("account.skills") + ":"}>
						<Select
							mode="tags"
							maxTagCount="responsive"
							allowClear
							style={{ display: "inherit", borderRadius: "4px" }}
							value={skills}
							onChange={handleChangeSkills}
						>
							{userSkills}
						</Select>
					</Form.Item>
				)}
				{(isProfessor || isCompany) && (
					<Form.Item
						name="areasOfInterest"
						label={t("account.areasOfInterest") + ":"}
					>
						<Select
							mode="tags"
							maxTagCount="responsive"
							allowClear
							style={{ display: "inherit", borderRadius: "4px" }}
							value={areasOfInterest}
							onChange={handleChangeAreasOfInterest}
						/>
					</Form.Item>
				)}
				<Form.Item
					name="description"
					label={t("account.description") + ":"}
					rules={[
						{
							whitespace: true,
						},
					]}
				>
					<CustomEditor
						content={state?.description}
						onEditorChange={(newDescription: string) =>
							setState({
								...state,
								description: newDescription,
							})
						}
					/>
				</Form.Item>
			</Form>

			{isCompany && <CompanyUserTable />}
		</div>
	);
};

export default MyAccount;
