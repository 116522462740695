import { Controller, useForm } from "react-hook-form";
import { Form, Input, Button, Switch } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { ExtendedViewStudentDTO } from "../../../../Api";
import UserAvatar from "../Avatar";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { getStudentById } from "../../../../Requests/academic-user-requests";
import { getUserId } from "../../../../utils/utilFunctions";

const ProfileTabForm = () => {
	const { t } = useTranslation();

	const { data: studentState } = useQuery(
		["getStudent"],
		() => {
			return getStudentById(getUserId());
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const { control, reset, setValue, watch, handleSubmit } =
		useForm<ExtendedViewStudentDTO>({
			defaultValues: studentState,
		});

	useEffect(() => {
		reset(studentState);
	}, [reset, studentState]);

	const avatar = watch("lowResImage");
	const onAvatarChange = (source: string) => setValue("lowResImage", source);
	const onAvatarDelete = () => setValue("lowResImage", "");

	const handleSave = (data: ExtendedViewStudentDTO) => {
		console.log(data);
	};

	return (
		<Form layout="vertical" className="flex flex-col md:flex-row gap-6">
			<div className="shrink mx-auto">
				<UserAvatar
					value={avatar}
					onChange={onAvatarChange}
					onDelete={onAvatarDelete}
				/>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 w-full">
				<Controller
					control={control}
					name="name"
					render={({ field }) => (
						<Form.Item label="Nume" className="md:col-span-2">
							<Input value={field.value!} onChange={field.onChange} disabled />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="email"
					render={({ field }) => (
						<Form.Item label="Email contact">
							<Input value={field.value!} onChange={field.onChange} disabled />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="personalEmail"
					render={({ field }) => (
						<Form.Item label="Email personal">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="phone"
					render={({ field }) => (
						<Form.Item label="Telefon">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="departmentNameRo"
					render={({ field }) => (
						<Form.Item label="Departament">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="facultyNameRo"
					render={({ field }) => (
						<Form.Item label="Facultatea" className="md:col-span-2">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="group"
					render={({ field }) => (
						<Form.Item label="Grupa">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="cvUrl"
					render={({ field }) => (
						<Form.Item label="Link CV">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="linkedInUrl"
					render={({ field }) => (
						<Form.Item label="Link profil LinkedIn">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="gitUrl"
					render={({ field }) => (
						<Form.Item label="Link Gihub">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				{/* <Controller
				control={control}
				name="userSkills"
				render={({ field }) => {
					return (
						<Form.Item label="Skilluri" className="md:col-span-2">
							<Select
								defaultValue={[1, 2]}
								mode="multiple"
								onChange={field.onChange}
								options={[
									{ value: 1, label: "test" },
									{ value: 2, label: "test 2" },
								]}
							/>
						</Form.Item>
					);
				}}
			/> */}

				<div className="md:col-span-2">
					<Controller
						control={control}
						name="description"
						render={({ field }) => (
							<Editor
								value={field.value!}
								init={{
									resize: false,
									branding: false,
									elementpath: false,
									width: "100%",
									height: "20rem",
									margin: "0",
									menubar: false,
									outputFormat: "text",
									plugins: [
										"advlist autolink lists link image charmap print preview anchor",
										"searchreplace visualblocks code fullscreen",
										"insertdatetime media table paste code help wordcount",
										"link",
									],
									toolbar:
										"undo redo | formatselect | " +
										"bold italic | fontselect | fontsizeselect | forecolor | alignleft aligncenter " +
										"alignright alignjustify | bullist numlist outdent indent | " +
										"removeformat | link",
									content_style:
										"body { font-family:Montserrat; font-size:14px; color: rgba(0, 0, 0, 0.85); }",
								}}
								onEditorChange={(_newValue, editor) => {
									field.onChange(editor.getContent({ format: "html" }));
								}}
							/>
						)}
					/>
				</div>

				<div className="md:col-span-2 pt-5 flex flex-col gap-2">
					<Controller
						control={control}
						name="hasAcceptedPublicProfile"
						render={({ field }) => (
							<div className="flex items-center gap-2">
								<Switch defaultChecked {...field} />

								<span>
									Sunt de acord ca profilul meu sa fie public companiilor
								</span>
							</div>
						)}
					/>

					<Controller
						control={control}
						name="sendNotifications"
						render={({ field }) => (
							<div className="flex items-center gap-2">
								<Switch defaultChecked {...field} />

								<span>Doresc sa primesc notificari pe adresa de email</span>
							</div>
						)}
					/>
				</div>

				<div className="md:col-span-2 flex justify-end">
					<Button type="primary" onClick={handleSubmit(handleSave)}>
						{t("account.save")}
					</Button>
				</div>
			</div>
		</Form>
	);
};

export default ProfileTabForm;
