import {
	EnrollmentStatus,
	InternshipApi,
	InternshipDocumentState,
	InternshipEngagementType,
	InternType,
	PracticeCertificateState,
	PracticeNotebookState,
	StudentYearEnum,
	TraineesFilterEnum,
} from "../Api";
import { getAuthConfiguration } from "./configuration";
import { arrayParamAjust } from "../utils/dataUtils";

const AuthInternshipApiFactory = () =>
	new InternshipApi(getAuthConfiguration());

export const getInternshipEnrollmentById = (id: string) => {
	return AuthInternshipApiFactory().apiInternshipGetEnrollmentByIdIdGet({ id });
};

export interface InternsForEmployerProps {
	type: InternType;
	search?: string; //namePattern
	page?: number; //page
	pageSize?: number; //pageSize
	internships?: Array<string>;
	StudyProgram?: Array<string>; //studyProgram
	EngagementType?: Array<InternshipEngagementType>; //engagementType
	Year?: Array<string>; //years
	faculties?: Array<string>;
	Status?: Array<EnrollmentStatus>; //studentStatus
	recruiterStatus?: Array<EnrollmentStatus>;
	notebookStatuses?: Array<string>;
	certificateStatuses?: Array<string>;
	View?: Array<string>; //viewStatuses
}

export const getInternsForEmployerV2 = (props: InternsForEmployerProps) => {
	const {
		type,
		search: namePattern,
		page,
		pageSize,
		internships,
		StudyProgram: studyProgram,
		EngagementType: engagementType,
		Year: years,
		faculties,
		Status: studentStatus,
		recruiterStatus,
		notebookStatuses,
		certificateStatuses,
		View: viewStatuses,
	} = props;

	return AuthInternshipApiFactory().apiInternshipGetInternsForEmployerGet({
		type,
		namePattern,
		page,
		pageSize,
		internships: arrayParamAjust(internships),
		studyProgram: arrayParamAjust(studyProgram),
		engagementType: arrayParamAjust(engagementType),
		years: arrayParamAjust(
			years?.map((y) => StudentYearEnum[y as keyof typeof StudentYearEnum])
		),
		faculties: arrayParamAjust(faculties),
		studentStatus: arrayParamAjust(studentStatus),
		recruiterStatus: arrayParamAjust(recruiterStatus),
		practiceNotebookStatuses: arrayParamAjust(
			notebookStatuses?.map(
				(e) => PracticeNotebookState[e as keyof typeof PracticeNotebookState]
			)
		),
		practiceCertificateStatuses: arrayParamAjust(
			certificateStatuses?.map(
				(e) =>
					PracticeCertificateState[e as keyof typeof PracticeCertificateState]
			)
		),
		viewStatuses: arrayParamAjust(viewStatuses),
	});
};

export const getInternsForEmployer = (
	type: InternType,
	namePattern?: string,
	page?: number,
	pageSize?: number,
	internships?: Array<string>,
	studyProgram?: Array<string>,
	engagementType?: Array<InternshipEngagementType>,
	years?: Array<string>,
	faculties?: Array<string>,
	studentStatus?: Array<EnrollmentStatus>,
	recruiterStatus?: Array<EnrollmentStatus>,
	notebookStatuses?: Array<string>,
	certificateStatuses?: Array<string>,
	viewStatuses?: Array<string>
) => {
	return AuthInternshipApiFactory().apiInternshipGetInternsForEmployerGet({
		type,
		namePattern,
		page,
		pageSize,
		internships: arrayParamAjust(internships),
		studyProgram: arrayParamAjust(studyProgram),
		engagementType: arrayParamAjust(engagementType),
		years: arrayParamAjust(
			years?.map((y) => StudentYearEnum[y as keyof typeof StudentYearEnum])
		),
		faculties: arrayParamAjust(faculties),
		studentStatus: arrayParamAjust(studentStatus),
		recruiterStatus: arrayParamAjust(recruiterStatus),
		practiceNotebookStatuses: arrayParamAjust(
			notebookStatuses?.map(
				(e) => PracticeNotebookState[e as keyof typeof PracticeNotebookState]
			)
		),
		practiceCertificateStatuses: arrayParamAjust(
			certificateStatuses?.map(
				(e) =>
					PracticeCertificateState[e as keyof typeof PracticeCertificateState]
			)
		),
		viewStatuses: arrayParamAjust(viewStatuses),
	});
};

export const getPracticeStudents = (
	internshipNamePattern?: string,
	studentNamePattern?: string,
	employerNamePattern?: string,
	supervisorNamePattern?: string,
	studyPrograms?: Array<string>,
	years?: Array<string>,
	notebookStatuses?: Array<string>,
	certificateStatuses?: Array<string>,
	universityYears?: Array<string>,
	page?: number,
	pageSize?: number
) => {
	return AuthInternshipApiFactory().apiInternshipGetPracticeStudentsGet({
		internshipNamePattern,
		studentNamePattern,
		employerNamePattern,
		supervisorNamePattern,
		page,
		pageSize,
		studyProgram: arrayParamAjust(studyPrograms),
		years: arrayParamAjust(
			years?.map((y) => StudentYearEnum[y as keyof typeof StudentYearEnum])
		),
		practiceNotebookStatuses: arrayParamAjust(
			notebookStatuses?.map(
				(e) => PracticeNotebookState[e as keyof typeof PracticeNotebookState]
			)
		),
		practiceCertificateStatuses: arrayParamAjust(
			certificateStatuses?.map(
				(e) =>
					PracticeCertificateState[e as keyof typeof PracticeCertificateState]
			)
		),
		universityYears: arrayParamAjust(
			universityYears?.map((y) => Number(y.split("-")[0]))
		),
	});
};

export interface PracticeStudentsProps {
	search?: string; //studentNamePattern
	internshipSearch?: string; //internshipNamePattern
	employerSearch?: string; //employerNamePattern
	supervisorSearch?: string; //supervisorNamePattern
	StudyProgram?: Array<string>; //studyPrograms
	Year?: Array<string>; //years
	NotebookStatus?: Array<string>; //notebookStatuses
	CertificateStatus?: Array<string>; //certificateStatuses
	UniversityYear?: Array<string>; //universityYears
	page?: number; //page
	pageSize?: number; //pageSize
}

export const getPracticeStudentsV2 = (props: PracticeStudentsProps) => {
	const {
		search: studentNamePattern,
		internshipSearch: internshipNamePattern,
		employerSearch: employerNamePattern,
		supervisorSearch: supervisorNamePattern,
		StudyProgram: studyPrograms,
		Year: years,
		NotebookStatus: notebookStatuses,
		CertificateStatus: certificateStatuses,
		UniversityYear: universityYears,
		page,
		pageSize,
	} = props;

	return AuthInternshipApiFactory().apiInternshipGetPracticeStudentsGet({
		internshipNamePattern,
		studentNamePattern,
		employerNamePattern,
		supervisorNamePattern,
		page,
		pageSize,
		studyProgram: arrayParamAjust(studyPrograms),
		years: arrayParamAjust(
			years?.map((y) => StudentYearEnum[y as keyof typeof StudentYearEnum])
		),
		practiceNotebookStatuses: arrayParamAjust(
			notebookStatuses?.map(
				(e) => PracticeNotebookState[e as keyof typeof PracticeNotebookState]
			)
		),
		practiceCertificateStatuses: arrayParamAjust(
			certificateStatuses?.map(
				(e) =>
					PracticeCertificateState[e as keyof typeof PracticeCertificateState]
			)
		),
		universityYears: arrayParamAjust(
			universityYears?.map((y) => Number(y.split("-")[0]))
		),
	});
};

export const getPracticeStudentsFilters = (
	internshipNamePattern?: string,
	studentNamePattern?: string,
	employerNamePattern?: string,
	supervisorNamePattern?: string,
	studyProgram?: Array<string>
) => {
	return AuthInternshipApiFactory().apiInternshipGetPracticeStudentsFiltersGet({
		internshipNamePattern,
		studentNamePattern,
		employerNamePattern,
		supervisorNamePattern,
		studyProgram: arrayParamAjust(studyProgram),
	});
};

export const getAllApplications = (
	skills?: Array<string>,
	categories?: Array<string>,
	types?: Array<InternshipEngagementType>,
	locations?: Array<string>,
	searchTerm?: string,
	studentStatus?: Array<EnrollmentStatus>,
	recruiterStatus?: Array<EnrollmentStatus>,
	page?: number,
	pageSize?: number
) => {
	return AuthInternshipApiFactory().apiInternshipGetAllApplicationsGet({
		skills: arrayParamAjust(skills),
		categories: arrayParamAjust(categories),
		types: arrayParamAjust(types),
		locations: arrayParamAjust(locations),
		searchTerm,
		studentStatus: arrayParamAjust(studentStatus),
		recruiterStatus: arrayParamAjust(recruiterStatus),
		page,
		pageSize,
	});
};

export const getPreviousEnrollments = (
	skills?: Array<string>,
	categories?: Array<string>,
	types?: Array<InternshipEngagementType>,
	locations?: Array<string>,
	searchTerm?: string,
	studentStatus?: Array<EnrollmentStatus>,
	recruiterStatus?: Array<EnrollmentStatus>,
	page?: number,
	pageSize?: number
) => {
	return AuthInternshipApiFactory().apiInternshipGetPreviousEnrollmentsGet({
		skills: arrayParamAjust(skills),
		categories: arrayParamAjust(categories),
		types: arrayParamAjust(types),
		locations: arrayParamAjust(locations),
		searchTerm,
		studentStatus: arrayParamAjust(studentStatus),
		recruiterStatus: arrayParamAjust(recruiterStatus),
		page,
		pageSize,
	});
};

export const getInternsFilters = (
	filtersList: Array<TraineesFilterEnum>,
	language: string,
	type: InternType,
	namePattern?: string,
	page?: number,
	pageSize?: number,
	internships?: Array<string>,
	studyProgram?: Array<string>,
	engagementType?: Array<InternshipEngagementType>,
	years?: Array<string>,
	faculties?: Array<string>,
	studentStatus?: Array<EnrollmentStatus>,
	recruiterStatus?: Array<EnrollmentStatus>,
	notebookStatuses?: Array<string>,
	certificateStatuses?: Array<string>,
	viewStatuses?: Array<string>
) => {
	return AuthInternshipApiFactory().apiInternshipGetInternsFiltersGet({
		filtersList,
		language,
		type,
		namePattern,
		page,
		pageSize,
		internships: arrayParamAjust(internships),
		studyProgram: arrayParamAjust(studyProgram),
		engagementType: arrayParamAjust(engagementType),
		years: arrayParamAjust(
			years?.map((y) => StudentYearEnum[y as keyof typeof StudentYearEnum])
		),
		faculties: arrayParamAjust(faculties),
		studentStatus: arrayParamAjust(studentStatus),
		recruiterStatus: arrayParamAjust(recruiterStatus),
		practiceNotebookStatuses: arrayParamAjust(
			notebookStatuses?.map(
				(e) => PracticeNotebookState[e as keyof typeof PracticeNotebookState]
			)
		),
		practiceCertificateStatuses: arrayParamAjust(
			certificateStatuses?.map(
				(e) =>
					PracticeCertificateState[e as keyof typeof PracticeCertificateState]
			)
		),
		viewStatuses: arrayParamAjust(viewStatuses),
	});
};

export interface InternsFiltersProps {
	filtersList: Array<TraineesFilterEnum>;
	language: string;
	type: InternType;
	search?: string;
	page?: number;
	pageSize?: number;
	internships?: Array<string>;
	studyProgram?: Array<string>;
	engagementType?: Array<InternshipEngagementType>;
	years?: Array<string>;
	faculties?: Array<string>;
	studentStatus?: Array<EnrollmentStatus>;
	recruiterStatus?: Array<EnrollmentStatus>;
	notebookStatuses?: Array<string>;
	certificateStatuses?: Array<string>;
	viewStatuses?: Array<string>;
}

export const getInternsFiltersV2 = (props: InternsFiltersProps) => {
	const {
		search: namePattern,
		filtersList,
		language,
		type,
		page,
		pageSize,
		internships,
		studyProgram,
		engagementType,
		years,
		faculties,
		studentStatus,
		recruiterStatus,
		notebookStatuses,
		certificateStatuses,
		viewStatuses,
	} = props;

	return AuthInternshipApiFactory().apiInternshipGetInternsFiltersGet({
		filtersList,
		language,
		type,
		namePattern,
		page,
		pageSize,
		internships: arrayParamAjust(internships),
		studyProgram: arrayParamAjust(studyProgram),
		engagementType: arrayParamAjust(engagementType),
		years: arrayParamAjust(
			years?.map((y) => StudentYearEnum[y as keyof typeof StudentYearEnum])
		),
		faculties: arrayParamAjust(faculties),
		studentStatus: arrayParamAjust(studentStatus),
		recruiterStatus: arrayParamAjust(recruiterStatus),
		practiceNotebookStatuses: arrayParamAjust(
			notebookStatuses?.map(
				(e) => PracticeNotebookState[e as keyof typeof PracticeNotebookState]
			)
		),
		practiceCertificateStatuses: arrayParamAjust(
			certificateStatuses?.map(
				(e) =>
					PracticeCertificateState[e as keyof typeof PracticeCertificateState]
			)
		),
		viewStatuses: arrayParamAjust(viewStatuses),
	});
};

export const updateInternshipEnrollmentTutor = (
	id: string,
	tutorId: string
) => {
	return AuthInternshipApiFactory().apiInternshipUpdateTutorIdPut({
		id,
		body: tutorId,
	});
};

export const canApply = async (internshipId: string) => {
	await AuthInternshipApiFactory().apiInternshipCanApplyInternshipIdGet({
		internshipId,
	});
};

export const apply = async (internshipId: string) => {
	await AuthInternshipApiFactory().apiInternshipApplyInternshipIdPost({
		internshipId,
	});
};

export const cancel = async (internshipId: string) => {
	await AuthInternshipApiFactory().apiInternshipCancelInternshipIdPost({
		internshipId,
	});
};

export const accept = async (internshipId: string) => {
	await AuthInternshipApiFactory().apiInternshipAcceptPositionInternshipIdPost({
		internshipId,
	});
};

export const acceptApplicant = async (
	internshipId: string,
	studentId: string,
	tutorId?: string
) => {
	await AuthInternshipApiFactory().apiInternshipAcceptApplicationPost({
		companyInternshipAcceptDTO: { internshipId, studentId, tutorId },
	});
};

export const finalAcceptApplicant = async (
	internshipId: string,
	studentId: string
) => {
	await AuthInternshipApiFactory().apiInternshipFinalAcceptPost({
		internshipAcceptDTO: { internshipId, studentId },
	});
};

export const rejectApplicant = async (
	internshipId: string,
	studentId: string
) => {
	await AuthInternshipApiFactory().apiInternshipRejectApplicationPost({
		internshipAcceptDTO: { internshipId, studentId },
	});
};

export const deleteEnrollment = async (id: string) => {
	await AuthInternshipApiFactory().apiInternshipDeleteEnrollmentIdDelete({
		id,
	});
};

export const withdrawOffer = async (id: string) => {
	await AuthInternshipApiFactory().apiInternshipWithdrawOfferIdDelete({ id });
};

export const getCountAllApplicationTypes = () => {
	return AuthInternshipApiFactory().apiInternshipGetAllApplicationTypesCountGet();
};

export const getCountStudentsTypes = () => {
	return AuthInternshipApiFactory().apiInternshipGetStudentsTypesCountGet();
};

export const getCountPracticeStudents = () => {
	return AuthInternshipApiFactory().apiInternshipGetPracticeStudentsCountGet();
};

export const getCountDiplomaStudents = () => {
	return AuthInternshipApiFactory().apiInternshipGetDiplomaStudentsCountGet();
};

export const enrollStudentToInternship = (
	studentId: string,
	internshipId: string
) => {
	return AuthInternshipApiFactory().apiInternshipEnrollStudentPost({
		enrollStudentDTO: { studentId, internshipId },
	});
};

export const getDiplomaStudents = (
	namePattern?: string,
	page?: number,
	pageSize?: number,
	studyProgram?: Array<string>,
	engagementType?: Array<InternshipEngagementType>,
	years?: Array<string>,
	faculties?: Array<string>,
	documentStatuses?: Array<string>
) => {
	return AuthInternshipApiFactory().apiInternshipGetDiplomaStudentsGet({
		namePattern,
		page,
		pageSize,
		studyProgram: arrayParamAjust(studyProgram),
		engagementType: arrayParamAjust(engagementType),
		years: arrayParamAjust(
			years?.map((y) => StudentYearEnum[y as keyof typeof StudentYearEnum])
		),
		faculties: arrayParamAjust(faculties),
		internshipDocumentStates: arrayParamAjust(
			documentStatuses?.map(
				(e) =>
					InternshipDocumentState[e as keyof typeof InternshipDocumentState]
			)
		),
	});
};

export interface DiplomaStudentsProps {
	search?: string; //namePattern
	page?: number;
	pageSize?: number;
	studyProgram?: Array<string>;
	engagementType?: Array<InternshipEngagementType>;
	years?: Array<string>;
	faculties?: Array<string>;
	documentStatuses?: Array<string>;
}

export const getDiplomaStudentsV2 = (props: DiplomaStudentsProps) => {
	const {
		search: namePattern,
		page,
		pageSize,
		studyProgram,
		engagementType,
		years,
		faculties,
		documentStatuses,
	} = props;

	return AuthInternshipApiFactory().apiInternshipGetDiplomaStudentsGet({
		namePattern,
		page,
		pageSize,
		studyProgram: arrayParamAjust(studyProgram),
		engagementType: arrayParamAjust(engagementType),
		years: arrayParamAjust(
			years?.map((y) => StudentYearEnum[y as keyof typeof StudentYearEnum])
		),
		faculties: arrayParamAjust(faculties),
		internshipDocumentStates: arrayParamAjust(
			documentStatuses?.map(
				(e) =>
					InternshipDocumentState[e as keyof typeof InternshipDocumentState]
			)
		),
	});
};

export const updateInternshipPracticeInLaboratory = (
	id: string,
	laboratoryPracticeRoom: string
) => {
	return AuthInternshipApiFactory().apiInternshipUpdatePracticeInLaboratoryIdPut(
		{ id, laboratoryPracticeRoom }
	);
};

export const updateIsEnrollmentViewed = (
	id: string,
	isEnrollmentView: boolean
) => {
	return AuthInternshipApiFactory().apiInternshipUpdateIsEnrollmentViewedIdPut({
		id,
		isEnrollmentView,
	});
};
