import { AddInternshipOfferFormProps } from "./AddInternshipOfferForm.types";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import {
	CompanyInternshipState,
	InternshipEngagementType,
} from "../../../../../../Api";
import styles from "../../../AddInternshipForm.module.scss";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../../Notifications/NotificationsUtils";
import { theme } from "../../../../../../theme";
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Radio,
	Row,
	Select,
	Tooltip,
} from "antd";
import {
	companyInternshipTypes,
	onlyInternshipTypes,
	professorInternshipTypes,
	professorInternshipTypesACS,
	userSkills,
} from "../../../../../../utils/constants";
import { StudyProgramsSelect } from "../../formItems/StudyProgramsSelect/StudyProgramsSelect";
import { StudentYearsSelect } from "../../formItems/StudentYearsSelect/StudentYearsSelect";
import CustomEditor from "../../../../../../CustomComponents/CustomEditor";
import { CoordinatingProfessorSelect } from "../../formItems/CoordinatingProfessorSelect/CoordinatingProfessorSelect";
import { CompanyUsersSelect } from "../../formItems/CompanyUsersSelect/CompanyUsersSelect";
import { CoordinatorSelect } from "../../formItems/CoordinatorSelect/CoordinatorSelect";
import { CoTutorSelect } from "../../formItems/CoTutorSelect/CoTutorSelect";
import InternshipCompetencesForm from "../../formItems/InternshipCompetencesForm/InternshipCompetencesForm";
import CustomAutocomplete from "../../../../../CustomAutocomplete/CustomAutocomplete";
import ConfirmationModal from "../../../../../../Containers/ConfirmationModal";
import { range } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const { RangePicker } = DatePicker;
const { Group } = Radio;
const { Option } = Select;
const dateFormatList = "YYYY-MM-DD";

export const AddInternshipOfferForm = (props: AddInternshipOfferFormProps) => {
	const { t } = useTranslation();

	const getRow1Span = useCallback(
		(size: string) => {
			switch (size) {
				case "xs":
				case "sm":
				case "md":
					return 24;
				default:
					return props.isCompany ? 8 : 12;
			}
		},
		[props.isCompany]
	);

	const getRow2Span = useCallback(
		(size: string) => {
			switch (size) {
				case "xs":
				case "sm":
				case "md":
					return 24;
				default:
					return props.engagementTypeValue ===
						InternshipEngagementType.Internship
						? 8
						: 12;
			}
		},
		[props.engagementTypeValue]
	);

	const thesisAvailabilityYears = useMemo(() => {
		const years: number[] = [];

		if (
			props.internship?.availableFrom.getFullYear() &&
			props.internship?.availableFrom.getFullYear() < props.currentAcademicYear
		) {
			years.push(props.internship?.availableFrom.getFullYear());
		}

		return years.concat(
			range(props.currentAcademicYear, props.currentAcademicYear + 4)
		);
	}, [props.internship?.availableFrom, props.currentAcademicYear]);

	const formatAvailablePositionsLabel = () => {
		if (props.internship?.availablePositions) {
			return t("internships.addInternshipForm.unoccupiedPosts", {
				positions: props.internship?.availablePositions,
			});
		} else {
			return "";
		}
	};

	return (
		<div className="container mx-auto px-4 py-10 flex flex-col gap-8">
			{props.isCompany && props.facultiesWithCompanyAccordList?.length === 0 ? (
				<h3>{t("internships.addInternshipForm.attentionMessage")}</h3>
			) : null}

			<Form
				form={props.form}
				onFinish={() => {
					props.setSaveModalVisibility(true);
				}}
				onFinishFailed={() =>
					openNotification(
						t("internships.addInternshipForm.addInternship"),
						t("usersText.requiredFieldsError"),
						NOTIFICATION_TYPES.ERROR
					)
				}
				layout={theme.layout}
				action="/propuneri"
			>
				<div className={styles.firstLine}>
					<Row gutter={8}>
						<Col
							xs={getRow1Span("xs")}
							sm={getRow1Span("sm")}
							md={getRow1Span("md")}
							lg={getRow1Span("lg")}
							xl={getRow1Span("xl")}
						>
							<Form.Item
								required
								name="engagementType"
								label={t("internships.addInternshipForm.type") + ":"}
								rules={[props.yupSync]}
								initialValue={
									process.env.REACT_APP_DISABLE_FEATURES === "true"
										? onlyInternshipTypes[0]
										: props.isCompany
										? companyInternshipTypes[0]
										: props.defaultFacultyName ===
										  "Facultatea de Automatică și Calculatoare"
										? professorInternshipTypesACS[0]
										: professorInternshipTypes[0]
								}
							>
								<Select
									className={styles.antSelect}
									disabled={props.isEditing}
									allowClear
									style={{ width: "100%", textAlign: "left" }}
									placeholder={t("internships.addInternshipForm.type")}
									onChange={props.handleEngagementTypeChange}
									notFoundContent={t(
										"internships.addInternshipForm.notFoundContent"
									)}
								>
									{process.env.REACT_APP_DISABLE_FEATURES === "true"
										? onlyInternshipTypes.map((type) => (
												<Option key={type} value={type}>
													{t("internships.filters." + type)}
												</Option>
										  ))
										: props.isCompany
										? companyInternshipTypes.map((type) => (
												<Option
													key={type}
													value={type}
													disabled={
														props.facultiesWithCompanyAccordList?.length ===
															0 &&
														(type === InternshipEngagementType.Internship ||
															type === InternshipEngagementType.Scholarship)
													}
												>
													{t("internships.filters." + type)}
												</Option>
										  ))
										: props.defaultFacultyName ===
										  "Facultatea de Automatică și Calculatoare"
										? professorInternshipTypesACS.map((type) => (
												<Option key={type} value={type}>
													{t("internships.filters." + type)}
												</Option>
										  ))
										: professorInternshipTypes.map((type) => (
												<Option key={type} value={type}>
													{t("internships.filters." + type)}
												</Option>
										  ))}
								</Select>
							</Form.Item>
						</Col>
						{props.isCompany && (
							<Col
								xs={getRow1Span("xs")}
								sm={getRow1Span("sm")}
								md={getRow1Span("md")}
								lg={getRow1Span("lg")}
								xl={getRow1Span("xl")}
							>
								<Form.Item
									required
									name="faculties"
									label={t("internships.addInternshipForm.faculty") + ":"}
									tooltip={
										props.engagementTypeValue ===
										InternshipEngagementType.Internship
											? t(
													"internships.addInternshipForm.selectFacultyForInternship"
											  )
											: null
									}
									rules={[
										{
											required: true,
											message: t(
												"internships.addInternshipForm.requiredFaculty"
											),
										},
									]}
								>
									<Select
										className={styles.antSelect}
										showSearch
										disabled={
											props.isEditing &&
											props.internship?.state !== CompanyInternshipState.Draft
										}
										allowClear
										filterOption={false}
										onSearch={props.setFacultySearch}
										style={{ width: "100%", textAlign: "left" }}
										placeholder={t("internships.addInternshipForm.faculty")}
										onChange={props.handleSelectedFaculties}
										notFoundContent={t(
											"internships.addInternshipForm.notFoundContent"
										)}
									>
										{props.facultyOptions}
									</Select>
								</Form.Item>
							</Col>
						)}
						<Col
							xs={getRow1Span("xs")}
							sm={getRow1Span("sm")}
							md={getRow1Span("md")}
							lg={getRow1Span("lg")}
							xl={getRow1Span("xl")}
						>
							<StudyProgramsSelect
								isProfessor={props.isProfessor}
								defaultFaculty={props.defaultFaculty}
								selectedFaculties={props.selectedFaculties}
								engagementTypeValue={props.engagementTypeValue}
							/>
						</Col>
					</Row>

					<Row gutter={8}>
						<Col
							xs={getRow2Span("xs")}
							sm={getRow2Span("sm")}
							md={getRow2Span("md")}
							lg={getRow2Span("lg")}
							xl={getRow2Span("xl")}
						>
							<Form.Item
								required
								name="initialAvailablePositions"
								label={
									t("internships.addInternshipForm.freePosts") +
									formatAvailablePositionsLabel() +
									":"
								}
								initialValue={props.defaultValues?.initialAvailablePositions}
								rules={[props.yupSync]}
							>
								<InputNumber
									className="w-full"
									min={
										props.isEditing
											? props.internship?.initialAvailablePositions
											: 1
									}
									placeholder={t("internships.addInternshipForm.freePosts")}
								/>
							</Form.Item>
						</Col>
						<Col
							xs={getRow2Span("xs")}
							sm={getRow2Span("sm")}
							md={getRow2Span("md")}
							lg={getRow2Span("lg")}
							xl={getRow2Span("xl")}
						>
							<StudentYearsSelect
								disabled={
									props.isCompany &&
									props.isEditing &&
									props.internship?.state !== CompanyInternshipState.Draft
								}
								isProfessor={props.isProfessor}
								professorUser={props.professorUser}
								facultiesWithCompanyAccordList={
									props.facultiesWithCompanyAccordList
								}
								engagementTypeValue={props.engagementTypeValue}
								selectedFaculties={props.selectedFaculties}
								form={props.form}
								setSelectedYears={props.setSelectedYears}
								setDisabledDateStart={props.setDisabledDateStart}
								setDisabledDateEnd={props.setDisabledDateEnd}
							/>
						</Col>
						{props.engagementTypeValue ===
							InternshipEngagementType.Internship && (
							<Col
								xs={getRow2Span("xs")}
								sm={getRow2Span("sm")}
								md={getRow2Span("md")}
								lg={getRow2Span("lg")}
								xl={getRow2Span("xl")}
							>
								<Form.Item
									required={
										props.engagementTypeValue ===
										InternshipEngagementType.Internship
									}
									name="totalPracticeHours"
									label={
										t("internships.addInternshipForm.totalPracticeHours") + ":"
									}
									rules={[props.yupSync]}
								>
									<InputNumber
										className="w-full"
										min={1}
										placeholder={t(
											"internships.addInternshipForm.totalPracticeHours"
										)}
										disabled={
											(props.selectedFaculties &&
												props.selectedFaculties.length === 1 &&
												props.facultiesWithCompanyAccordList?.filter(
													(faculty) => {
														return (
															faculty.id === props.selectedFaculties[0] &&
															faculty.facultyNameRo ===
																"Facultatea de Inginerie Industrială și Robotică"
														);
													}
												).length === 1) ||
											(props.isCompany &&
												props.isEditing &&
												props.internship?.state !==
													CompanyInternshipState.Draft)
										}
									/>
								</Form.Item>
							</Col>
						)}
					</Row>
					<Form.Item
						required
						name="internshipName"
						label={t("internships.addInternshipForm.internshipTitle") + ":"}
						rules={[props.yupSync]}
					>
						<Input
							className={styles.antItem}
							placeholder={t("internships.addInternshipForm.internshipTitle")}
							disabled={
								props.isCompany &&
								props.isEditing &&
								props.internship?.state !== CompanyInternshipState.Draft
							}
						/>
					</Form.Item>
					<Form.Item
						name="category"
						label={t("internships.addInternshipForm.category") + ":"}
						rules={[props.yupSync]}
					>
						<Input
							className={styles.antItem}
							placeholder={t("internships.addInternshipForm.category")}
							disabled={
								props.isCompany &&
								props.isEditing &&
								props.internship?.state !== CompanyInternshipState.Draft
							}
						/>
					</Form.Item>
					{props.isCompany && (
						<Form.Item
							required
							name="companyDepartment"
							label={t("internships.addInternshipForm.companyDepartment") + ":"}
							rules={[props.yupSync]}
						>
							<Input
								className={styles.antItem}
								placeholder={t(
									"internships.addInternshipForm.companyDepartment"
								)}
								disabled={
									props.isEditing &&
									props.internship?.state !== CompanyInternshipState.Draft
								}
							/>
						</Form.Item>
					)}
					<Form.Item
						required
						name="description"
						label={t("internships.addInternshipForm.description") + ":"}
						rules={[props.yupSync]}
					>
						<CustomEditor
							onEditorChange={(description: string) => {
								props.form.setFieldsValue({
									...props.form.getFieldsValue(),
									description,
								} as any);
							}}
							disabled={
								props.isCompany &&
								props.isEditing &&
								props.internship?.state !== CompanyInternshipState.Draft
							}
						/>
					</Form.Item>
					<Form.Item
						name="shortDescription"
						label={t("internships.addInternshipForm.shortDescription") + ":"}
						rules={[props.yupSync]}
					>
						<Input.TextArea
							rows={4}
							cols={100}
							style={{ minHeight: 100, width: "100%" }}
							placeholder={t("internships.addInternshipForm.shortDescription")}
							disabled={
								props.isCompany &&
								props.isEditing &&
								props.internship?.state !== CompanyInternshipState.Draft
							}
						/>
					</Form.Item>
					<Form.Item
						name="internshipLink"
						label={t("internships.addInternshipForm.internshipLink") + ":"}
						rules={[props.yupSync]}
					>
						<Input
							className={styles.antItem}
							placeholder={t(
								"internships.addInternshipForm.internshipLinkDetails"
							)}
							disabled={
								props.isCompany &&
								props.isEditing &&
								props.internship?.state !== CompanyInternshipState.Draft
							}
						/>
					</Form.Item>
					{props.isDepartmentSecretary && (
						<CoordinatingProfessorSelect
							isEditing={props.isEditing}
							isProfessor={props.isProfessor}
							debouncedUserSearch={props.debouncedUserSearch}
							currentUserId={props.currentUserId}
							internship={props.internship}
							yupSync={props.yupSync}
						/>
					)}
					{props.isCompany && (
						<CompanyUsersSelect
							disabled={
								props.isEditing &&
								props.internship?.state !== CompanyInternshipState.Draft
							}
							isCompany={props.isCompany}
							internship={props.internship}
							yupSync={props.yupSync}
						/>
					)}
					{props.isCompany &&
						(props.engagementTypeValue ===
							InternshipEngagementType.BachelorsInternship ||
							props.engagementTypeValue ===
								InternshipEngagementType.MastersInternship ||
							props.engagementTypeValue ===
								InternshipEngagementType.SummerSchool) && (
							<CoordinatorSelect
								isEditing={props.isEditing}
								isCompany={props.isCompany}
								engagementTypeValue={props.engagementTypeValue}
								internship={props.internship}
								handleFacultyCoordinatorChange={
									props.handleFacultyCoordinatorChange
								}
							/>
						)}
					{props.isProfessor &&
						(props.engagementTypeValue ===
							InternshipEngagementType.BachelorsInternship ||
							props.engagementTypeValue ===
								InternshipEngagementType.MastersInternship ||
							props.engagementTypeValue ===
								InternshipEngagementType.SummerSchool ||
							props.engagementTypeValue ===
								InternshipEngagementType.ResearchInternship) && (
							<CoTutorSelect
								isProfessor={props.isProfessor}
								debouncedUserSearch={props.debouncedUserSearch}
								internship={props.internship}
								currentUserId={props.currentUserId}
								setUserSearch={props.setUserSearch}
							/>
						)}
					<Form.Item
						name="skills"
						label={t("internships.addInternshipForm.requiredSkills") + ":"}
					>
						<Select
							className={styles.antSelect}
							mode="tags"
							maxTagCount="responsive"
							disabled={
								props.isCompany &&
								props.isEditing &&
								props.internship?.state !== CompanyInternshipState.Draft
							}
							allowClear
							notFoundContent={t(
								"internships.addInternshipForm.requiredSkillsNoData"
							)}
							style={{ width: "100%", textAlign: "left" }}
							placeholder={t("internships.addInternshipForm.requiredSkills")}
						>
							{userSkills}
						</Select>
					</Form.Item>
					{props.isCompany &&
						props.engagementTypeValue ===
							InternshipEngagementType.Internship && (
							<InternshipCompetencesForm
								name="internshipCompetences"
								initialValue={[
									{
										competence: "",
										type: "",
										place: "",
										activity: "",
										observations: "",
									},
								]}
								rules={[
									{
										required:
											props.isCompany &&
											props.engagementTypeValue ===
												InternshipEngagementType.Internship,
										message:
											props.isCompany &&
											props.engagementTypeValue ===
												InternshipEngagementType.Internship
												? t("internships.addInternshipForm.requiredCompetences")
												: "",
									},
								]}
								disabled={
									props.isCompany &&
									props.isEditing &&
									props.internship?.state !== CompanyInternshipState.Draft
								}
							/>
						)}

					{!(
						props.isProfessor &&
						(props.engagementTypeValue ===
							InternshipEngagementType.MastersInternship ||
							props.engagementTypeValue ===
								InternshipEngagementType.BachelorsInternship)
					) && (
						<Row gutter={8}>
							<Col span={window.innerWidth > 1100 ? 8 : 24}>
								<Form.Item
									required
									name="location"
									initialValue={props.location}
									label={t("internships.addInternshipForm.location") + ":"}
								>
									{(!props.internshipId || props.internship) && (
										<CustomAutocomplete
											onPlaceChange={props.handleChangeLocation}
											locationInput={props.location}
											disabled={
												props.isCompany &&
												props.isEditing &&
												props.internship?.state !== CompanyInternshipState.Draft
											}
										/>
									)}
								</Form.Item>
							</Col>
							<Col span={window.innerWidth > 1100 ? 8 : 24}>
								<Form.Item
									required
									name="startingDate"
									label={
										t("internships.addInternshipForm.internshipStartDate") + ":"
									}
									rules={[props.yupSync]}
								>
									<DatePicker
										format={dateFormatList}
										style={{ width: "100%", height: "35px" }}
										disabled={
											props.isCompany &&
											props.isEditing &&
											props.internship?.state !== CompanyInternshipState.Draft
										}
										placeholder={t(
											"internships.addInternshipForm.internshipStartDate"
										)}
									/>
								</Form.Item>
							</Col>
							<Col span={window.innerWidth > 1100 ? 8 : 24}>
								<Form.Item
									required
									name="period"
									initialValue={props.defaultValues?.period}
									label={
										<>
											{t("internships.addInternshipForm.internshipPeriod") +
												":"}
											<Tooltip
												title={t("internships.addInternshipForm.periodTooltip")}
											>
												<FontAwesomeIcon
													icon={solid("circle-info")}
													style={{ marginLeft: "5px" }}
												/>
											</Tooltip>
										</>
									}
									rules={[props.yupSync]}
								>
									<InputNumber
										className="w-full"
										min={1}
										max={
											props.form.getFieldsValue().engagementType ===
											InternshipEngagementType.Internship
												? 3
												: 12
										}
										placeholder={t(
											"internships.addInternshipForm.internshipPeriodPlaceholder"
										)}
										disabled={
											props.isCompany &&
											props.isEditing &&
											props.internship?.state !== CompanyInternshipState.Draft
										}
									/>
								</Form.Item>
							</Col>
						</Row>
					)}
					{props.isCompany && (
						<Form.Item
							name="isPaid"
							initialValue={props.defaultValues?.isPaid}
							label={t("internships.addInternshipForm.contractType") + ":"}
						>
							<Group
								className={styles.checkbox}
								disabled={
									props.isCompany &&
									props.isEditing &&
									props.internship?.state !== CompanyInternshipState.Draft
								}
							>
								<Radio value={true} className={styles.input_container}>
									{t("internships.addInternshipForm.paid")}
								</Radio>
								<Radio value={false} className={styles.input_container}>
									{t("internships.addInternshipForm.unpaid")}
								</Radio>
							</Group>
						</Form.Item>
					)}
					{props.isProfessor &&
					(props.engagementTypeValue ===
						InternshipEngagementType.MastersInternship ||
						props.engagementTypeValue ===
							InternshipEngagementType.BachelorsInternship ||
						props.engagementTypeValue ===
							InternshipEngagementType.SummerSchool) ? (
						<Form.Item
							name="isViewOnly"
							initialValue={props.defaultValues?.isViewOnly}
							label={t("internships.addInternshipForm.isViewOnly")}
						>
							<Group className={styles.checkbox}>
								<Radio value={false} className={styles.input_container}>
									{t("internships.addInternshipForm.no")}
								</Radio>
								<Radio value={true} className={styles.input_container}>
									{t("internships.addInternshipForm.yes")}
								</Radio>
							</Group>
						</Form.Item>
					) : null}
					{props.isProfessor &&
					(props.engagementTypeValue ===
						InternshipEngagementType.MastersInternship ||
						props.engagementTypeValue ===
							InternshipEngagementType.BachelorsInternship) ? (
						<Form.Item
							required
							name="availabilityYear"
							label={t("internships.availabilityAd") + ":"}
							rules={[props.yupSync]}
						>
							<Select
								className={styles.antSelect}
								style={{ width: "100%", textAlign: "left" }}
								disabled={(props.internship?.applicantsCount ?? 0) > 0}
							>
								{thesisAvailabilityYears.map((year) => (
									<Option
										key={year}
										value={year}
										disabled={year < props.currentAcademicYear}
									>
										{year}-{year + 1}
									</Option>
								))}
							</Select>
						</Form.Item>
					) : (
						<Form.Item
							required
							name="availabilityAd"
							label={t("internships.availabilityAd") + ":"}
							rules={[props.yupSync]}
						>
							<RangePicker
								picker="date"
								format={dateFormatList}
								style={{ width: "100%" }}
								placeholder={[
									t("internships.addInternshipForm.from"),
									t("internships.addInternshipForm.to"),
								]}
							/>
						</Form.Item>
					)}
				</div>

				<div className="flex justify-end items-center gap-6">
					<Button
						style={{
							background: theme.green,
							color: theme.white,
							boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
						}}
						onClick={() => props.setCancelModalVisibility(true)}
					>
						{t("internships.addInternshipForm.cancel")}
					</Button>

					<ConfirmationModal
						modalText={t("internships.cancelMessage")}
						handleFunction={props.handleCancel}
						modalVisibility={props.cancelModalVisibility}
						changeModalVisibility={() => props.setCancelModalVisibility(false)}
						title=""
						spinning={props.spinning}
					/>

					<Button
						htmlType="submit"
						type={"primary"}
						style={{
							background: theme.secondColor,
							color: theme.white,
							boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
						}}
					>
						{props.isEditing
							? t("internships.addInternshipForm.updateButton")
							: t("internships.addInternshipForm.saveButton")}
					</Button>

					<ConfirmationModal
						modalText={t("internships.saveMessage")}
						handleFunction={props.handleSave}
						modalVisibility={props.saveModalVisibility}
						title=""
						changeModalVisibility={() => props.setSaveModalVisibility(false)}
						spinning={props.spinning}
					/>
				</div>
			</Form>
		</div>
	);
};
