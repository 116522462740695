import { Button, Pagination, Tabs, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { getAllProfessorInternshipFilters } from "../../Requests/professor-internship-requests";
import { getAllCompanyInternshipFilters } from "../../Requests/company-internship-requests";
import { Link, useLocation } from "react-router-dom";
import { useIsCompany, useIsProfessor } from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	InternType,
	InternshipSortFieldEnum,
	SortDirectionEnum,
} from "../../Api";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import OffersList from "./OffersList";
import {
	getActiveResearchOffers,
	getActiveResearchOffersFilters,
	getMyResearchOffers,
	getMyResearchOffersFilters,
	getResearchApplicants,
	getResearchApplicantsFilters,
	getResearchApplications,
} from "../../utils/reactQueriesConstants";
import ApplicantsList from "./ApplicantsList";
import { getInternsFilters } from "../../Requests/internship-requests";
import { traineesFilters } from "../../utils/constants";
import { getAllCompanyResearchPropsalsV2 } from "../../Requests/company-research-proposals-requests";
import { getAllProfessorResearchPropsalsV2 } from "../../Requests/professor-research-proposals-requests";
import {
	getMyApplicationsV2,
	getResearchPartnersForCompanyOrProfessorV2,
} from "../../Requests/research-proposal-requests";
import ApplicationsList from "./ApplicationsList";
import { PlusCircleOutlined } from "@ant-design/icons";
import Filters from "../Filters";
import useQueryFilters from "../../Hooks/useQueryFilters";

const { TabPane } = Tabs;

const getAllKeys = (filterItems: any[]) => {
	const keys: any[] = [];

	filterItems?.forEach((filter) => {
		keys.push(filter.key);

		filter.children.forEach((filterItem: any) => {
			keys.push(filterItem.key.split("/")[1]);
		});
	});

	return keys;
};

const ResearchOffers = (props: any) => {
	const { t, i18n } = useTranslation();
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortField, setSortField] = useState(
		InternshipSortFieldEnum.PublishDate
	);
	const [sortDirection, setSortDirection] = useState(
		SortDirectionEnum.Descending
	);
	const [searchTerm, setSearchTerm] = useState("");
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState<any>([]);
	const location = useLocation();
	const [activeTab, setActiveTab] = useState("1");
	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();
	const queryClient = useQueryClient();
	const query = useQueryFilters({
		initialValues: {
			tab: activeTab,
		},
	});

	const handleListChange = async (current: number, pageSize: number) => {
		setCurrentPage(() => current);
		setPageSize(() => pageSize);
		await queryClient.invalidateQueries(getMyResearchOffersFilters);
		await queryClient.invalidateQueries(getActiveResearchOffersFilters);
		await queryClient.invalidateQueries(getResearchApplicantsFilters);
		await queryClient.invalidateQueries(getMyResearchOffers);
		await queryClient.invalidateQueries(getActiveResearchOffers);
		await queryClient.invalidateQueries(getResearchApplicants);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDatasError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: myOffersFilters } = useQuery(
		[getMyResearchOffersFilters, isProfessor, isCompany],
		() =>
			isProfessor
				? getAllProfessorInternshipFilters()
				: getAllCompanyInternshipFilters(),
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: activeOffersFilters } = useQuery(
		[getActiveResearchOffersFilters, isCompany],
		() =>
			isProfessor
				? getAllProfessorInternshipFilters()
				: getAllCompanyInternshipFilters(),
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isProfessor) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: applicantsFilters } = useQuery(
		[getResearchApplicantsFilters, traineesFilters, i18n.language],
		() => {
			return getInternsFilters(
				traineesFilters,
				i18n.language,
				InternType.Applicant,
				searchTerm,
				currentPage,
				pageSize,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined
			);
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
		}
	);

	const { data: myOffers, isLoading: loadingMyOffers } = useQuery(
		[getMyResearchOffers, isProfessor, query.filters],
		() =>
			isProfessor
				? getAllProfessorResearchPropsalsV2(query.filters)
				: getAllCompanyResearchPropsalsV2(query.filters),
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: activeOffers, isLoading: loadingActiveOffers } = useQuery(
		[getActiveResearchOffers, isProfessor, query.filters],
		() =>
			isProfessor
				? getAllCompanyResearchPropsalsV2(query.filters)
				: getAllProfessorResearchPropsalsV2(query.filters),
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: applicants, isLoading: loadingApplicants } = useQuery(
		[getResearchApplicants, query.filters],
		() => getResearchPartnersForCompanyOrProfessorV2(query.filters),
		{
			onError: openGetErrorNotification,
		}
	);

	const { data: myApplications, isLoading: loadingMyApplications } = useQuery(
		[getResearchApplications, query.filters],
		() => getMyApplicationsV2(query.filters),
		{
			onError: openGetErrorNotification,
		}
	);

	const changeTab = (activeKey: any) => {
		setActiveTab(activeKey);

		query.update(
			{
				tab: [activeKey],
			},
			true
		);
	};

	useEffect(() => {
		const url = new URLSearchParams(location.search);
		if (!url) return;

		for (const [key, value] of url) {
			if (key === "tab") {
				setActiveTab(value);
				break;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update({ tab: [activeTab], ...values }, true);
		},
		[activeTab, query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 pb-10 flex flex-col relative">
			{activeTab === "1" && (
				<div className="fixed bottom-10 right-10 z-50">
					<Button
						type="primary"
						className="p-6 flex flex-col justify-center items-center rounded-full relative"
					>
						<PlusCircleOutlined />

						<Link
							to={{
								pathname: "/editare-propunere-cercetare/",
								state: {
									activeTab: activeTab,
								},
							}}
						>
							{t("internships.addInternship")}
						</Link>
					</Button>
				</div>
			)}

			<Filters
				filters={
					activeTab === "1"
						? myOffersFilters!
						: activeTab === "2"
						? activeOffersFilters!
						: applicantsFilters
				}
				searchFields={
					activeTab === "2"
						? [{ name: "searchTerm", label: t("internships.search") }]
						: []
				}
				onUpdate={onUpdate}
				hasSort={activeTab === "2"}
			/>

			<div className="relative flex flex-col md:flex-row justify-between items-center">
				<Typography.Title level={3} className="my-0">
					{
						(activeTab === "1"
							? myOffers!
							: activeTab === "2"
							? activeOffers
							: activeTab === "3"
							? applicants
							: myApplications
						)?.totalCount
					}{" "}
					rezultate
				</Typography.Title>

				<Pagination
					defaultCurrent={
						(activeTab === "1"
							? myOffers!
							: activeTab === "2"
							? activeOffers
							: activeTab === "3"
							? applicants
							: myApplications
						)?.page
					}
					total={
						(activeTab === "1"
							? myOffers!
							: activeTab === "2"
							? activeOffers
							: activeTab === "3"
							? applicants
							: myApplications
						)?.totalCount
					}
					onChange={onPageUpdate}
					pageSizeOptions={[12, 24, 48, 96]}
					defaultPageSize={12}
				/>
			</div>

			<div className="relative">
				<Tabs
					activeKey={activeTab}
					animated={{ inkBar: false }}
					onChange={changeTab}
				>
					<TabPane tab={t("internships.myResearchOffers")} key="1">
						<OffersList
							data={myOffers}
							checkedKeys={checkedKeys}
							loading={loadingMyOffers}
							location={props.location}
							activeTab={"1"}
							currentPage={currentPage}
							pageSize={pageSize}
							searchTerm={""}
							sortDirection={sortDirection}
							sortField={sortField}
							handleListChange={handleListChange}
						/>
					</TabPane>
					<TabPane tab={t("internships.activeResearchOffers")} key="2">
						<OffersList
							data={activeOffers}
							checkedKeys={checkedKeys}
							loading={loadingActiveOffers}
							location={props.location}
							activeTab={"2"}
							currentPage={currentPage}
							pageSize={pageSize}
							searchTerm={""}
							sortDirection={sortDirection}
							sortField={sortField}
							handleListChange={handleListChange}
						/>
					</TabPane>
					<TabPane tab={t("internships.applicants")} key="3">
						<ApplicantsList
							data={applicants}
							checkedKeys={checkedKeys}
							loading={loadingApplicants}
							location={props.location}
							activeTab={"3"}
							currentPage={currentPage}
							pageSize={pageSize}
							searchTerm={""}
							sortDirection={sortDirection}
							sortField={sortField}
							handleListChange={handleListChange}
						/>
					</TabPane>
					<TabPane tab={t("research.myApplications")} key="4">
						<ApplicationsList
							data={myApplications}
							checkedKeys={checkedKeys}
							loading={loadingMyApplications}
							location={props.location}
							activeTab={"4"}
							currentPage={currentPage}
							pageSize={pageSize}
							searchTerm={""}
							sortDirection={sortDirection}
							sortField={sortField}
							handleListChange={handleListChange}
						/>
					</TabPane>
				</Tabs>
			</div>
		</div>
	);
};

export default ResearchOffers;
