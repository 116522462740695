/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FeatureFlagsCategoryEnum {
	Optionals = "Optionals",
	Feedback = "Feedback",
}

export function FeatureFlagsCategoryEnumFromJSON(
	json: any
): FeatureFlagsCategoryEnum {
	return FeatureFlagsCategoryEnumFromJSONTyped(json, false);
}

export function FeatureFlagsCategoryEnumFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): FeatureFlagsCategoryEnum {
	return json as FeatureFlagsCategoryEnum;
}

export function FeatureFlagsCategoryEnumToJSON(
	value?: FeatureFlagsCategoryEnum | null
): any {
	return value as any;
}
