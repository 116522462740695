/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	AcademicUser,
	AcademicUserFromJSON,
	AcademicUserFromJSONTyped,
	AcademicUserToJSON,
} from "./AcademicUser";
import {
	Company,
	CompanyFromJSON,
	CompanyFromJSONTyped,
	CompanyToJSON,
} from "./Company";
import {
	CompanyInternshipState,
	CompanyInternshipStateFromJSON,
	CompanyInternshipStateFromJSONTyped,
	CompanyInternshipStateToJSON,
} from "./CompanyInternshipState";
import {
	CompanyUser,
	CompanyUserFromJSON,
	CompanyUserFromJSONTyped,
	CompanyUserToJSON,
} from "./CompanyUser";
import {
	Internship,
	InternshipFromJSON,
	InternshipFromJSONTyped,
	InternshipToJSON,
} from "./Internship";
import {
	InternshipCompetence,
	InternshipCompetenceFromJSON,
	InternshipCompetenceFromJSONTyped,
	InternshipCompetenceToJSON,
} from "./InternshipCompetence";

/**
 *
 * @export
 * @interface CompanyInternship
 */
export interface CompanyInternship {
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternship
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyInternship
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof CompanyInternship
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternship
	 */
	companyId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternship
	 */
	internshipId?: string;
	/**
	 *
	 * @type {boolean}
	 * @memberof CompanyInternship
	 */
	isPaid?: boolean;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternship
	 */
	companyDepartment?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternship
	 */
	tutorId?: string;
	/**
	 *
	 * @type {string}
	 * @memberof CompanyInternship
	 */
	facultyCoordinatorId?: string | null;
	/**
	 *
	 * @type {CompanyInternshipState}
	 * @memberof CompanyInternship
	 */
	state?: CompanyInternshipState;
	/**
	 *
	 * @type {Internship}
	 * @memberof CompanyInternship
	 */
	internship?: Internship;
	/**
	 *
	 * @type {Company}
	 * @memberof CompanyInternship
	 */
	company?: Company;
	/**
	 *
	 * @type {CompanyUser}
	 * @memberof CompanyInternship
	 */
	tutor?: CompanyUser;
	/**
	 *
	 * @type {AcademicUser}
	 * @memberof CompanyInternship
	 */
	facultyCoordinator?: AcademicUser;
	/**
	 *
	 * @type {Array<InternshipCompetence>}
	 * @memberof CompanyInternship
	 */
	internshipCompetences?: Array<InternshipCompetence> | null;
}

export function CompanyInternshipFromJSON(json: any): CompanyInternship {
	return CompanyInternshipFromJSONTyped(json, false);
}

export function CompanyInternshipFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): CompanyInternship {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		internshipId: !exists(json, "internshipId")
			? undefined
			: json["internshipId"],
		isPaid: !exists(json, "isPaid") ? undefined : json["isPaid"],
		companyDepartment: !exists(json, "companyDepartment")
			? undefined
			: json["companyDepartment"],
		tutorId: !exists(json, "tutorId") ? undefined : json["tutorId"],
		facultyCoordinatorId: !exists(json, "facultyCoordinatorId")
			? undefined
			: json["facultyCoordinatorId"],
		state: !exists(json, "state")
			? undefined
			: CompanyInternshipStateFromJSON(json["state"]),
		internship: !exists(json, "internship")
			? undefined
			: InternshipFromJSON(json["internship"]),
		company: !exists(json, "company")
			? undefined
			: CompanyFromJSON(json["company"]),
		tutor: !exists(json, "tutor")
			? undefined
			: CompanyUserFromJSON(json["tutor"]),
		facultyCoordinator: !exists(json, "facultyCoordinator")
			? undefined
			: AcademicUserFromJSON(json["facultyCoordinator"]),
		internshipCompetences: !exists(json, "internshipCompetences")
			? undefined
			: json["internshipCompetences"] === null
			? null
			: (json["internshipCompetences"] as Array<any>).map(
					InternshipCompetenceFromJSON
			  ),
	};
}

export function CompanyInternshipToJSON(value?: CompanyInternship | null): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		companyId: value.companyId,
		internshipId: value.internshipId,
		isPaid: value.isPaid,
		companyDepartment: value.companyDepartment,
		tutorId: value.tutorId,
		facultyCoordinatorId: value.facultyCoordinatorId,
		state: CompanyInternshipStateToJSON(value.state),
		internship: InternshipToJSON(value.internship),
		company: CompanyToJSON(value.company),
		tutor: CompanyUserToJSON(value.tutor),
		facultyCoordinator: AcademicUserToJSON(value.facultyCoordinator),
		internshipCompetences:
			value.internshipCompetences === undefined
				? undefined
				: value.internshipCompetences === null
				? null
				: (value.internshipCompetences as Array<any>).map(
						InternshipCompetenceToJSON
				  ),
	};
}
