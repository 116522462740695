import { Controller, useFormContext } from "react-hook-form";
import { ProfessorDTO } from "../../../../Api";
import { Form, Input, Switch } from "antd";
import { Editor } from "@tinymce/tinymce-react";

export default function UserForm() {
	const { control } = useFormContext<ProfessorDTO>();

	return (
		<Form
			layout="vertical"
			className="grid grid-cols-1 md:grid-cols-2 gap-x-6 w-full"
		>
			<Controller
				control={control}
				name="name"
				render={({ field }) => (
					<Form.Item label="Nume" className="md:col-span-2">
						<Input value={field.value!} onChange={field.onChange} disabled />
					</Form.Item>
				)}
			/>

			<Controller
				control={control}
				name="email"
				render={({ field }) => (
					<Form.Item label="Email contact">
						<Input value={field.value!} onChange={field.onChange} disabled />
					</Form.Item>
				)}
			/>

			<Controller
				control={control}
				name="personalEmail"
				render={({ field }) => (
					<Form.Item label="Email personal">
						<Input value={field.value!} onChange={field.onChange} />
					</Form.Item>
				)}
			/>

			<Controller
				control={control}
				name="phone"
				render={({ field }) => (
					<Form.Item label="Telefon">
						<Input value={field.value!} onChange={field.onChange} />
					</Form.Item>
				)}
			/>

			<Controller
				control={control}
				name="departmentNameRo"
				render={({ field }) => (
					<Form.Item label="Departament">
						<Input value={field.value!} onChange={field.onChange} />
					</Form.Item>
				)}
			/>

			<Controller
				control={control}
				name="areasOfInterest"
				render={({ field }) => (
					<Form.Item label="Domeniu de cercetare" className="md:col-span-2">
						<Input value={field.value!} onChange={field.onChange} />
					</Form.Item>
				)}
			/>

			<Controller
				control={control}
				name="siteUrl"
				render={({ field }) => (
					<Form.Item label="Link site" className="md:col-span-2">
						<Input value={field.value!} onChange={field.onChange} />
					</Form.Item>
				)}
			/>

			<Controller
				control={control}
				name="areasOfInterest"
				render={({ field }) => (
					<Form.Item label="Cuvinte cheie" className="md:col-span-2">
						<Input value={field.value!} onChange={field.onChange} />
					</Form.Item>
				)}
			/>

			<div className="md:col-span-2">
				<Controller
					control={control}
					name="description"
					render={({ field }) => (
						<Editor
							value={field.value!}
							init={{
								resize: false,
								branding: false,
								elementpath: false,
								width: "100%",
								height: "20rem",
								margin: "0",
								menubar: false,
								outputFormat: "text",
								plugins: [
									"advlist autolink lists link image charmap print preview anchor",
									"searchreplace visualblocks code fullscreen",
									"insertdatetime media table paste code help wordcount",
									"link",
								],
								toolbar:
									"undo redo | formatselect | " +
									"bold italic | fontselect | fontsizeselect | forecolor | alignleft aligncenter " +
									"alignright alignjustify | bullist numlist outdent indent | " +
									"removeformat | link",
								content_style:
									"body { font-family:Montserrat; font-size:14px; color: rgba(0, 0, 0, 0.85); }",
							}}
							onEditorChange={(_newValue, editor) => {
								field.onChange(editor.getContent({ format: "html" }));
							}}
						/>
					)}
				/>
			</div>

			<div className="md:col-span-2 pt-5">
				<Controller
					control={control}
					name="sendNotifications"
					render={({ field }) => (
						<div className="flex items-center gap-2">
							<Switch defaultChecked {...field} />

							<span>Doresc sa primesc notificari pe adresa de email</span>
						</div>
					)}
				/>
			</div>
		</Form>
	);
}
