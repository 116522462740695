import { Pagination, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getInterns } from "../../../../../utils/reactQueriesConstants";
import { getInternsForEmployerV2 } from "../../../../../Requests/internship-requests";
import { InternsForEmployerDTO, InternType } from "../../../../../Api";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../../../Notifications/NotificationsUtils";
import { usePagination } from "../../../../../Hooks";
import { InternshipMyStudentsTableProps } from "./InternshipMyStudentsTable.types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { studentIdSet } from "../../../../Student/StudentSlice";
import { useCallback } from "react";
import useQueryFilters from "../../../../../Hooks/useQueryFilters";

export const InternshipMyStudentsTable = (
	props: InternshipMyStudentsTableProps
) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQueryFilters({});

	const goToStudentProfile = (id: any) => {
		dispatch(studentIdSet(id));
		history.push("/student");
	};

	let locale = {
		emptyText: t("tableText.noApplicants"),
	};

	const columns = [
		{
			title: t("students.name"),
			key: "name",
			dataIndex: "name",
			fixed: "left" as "left",
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (text: string | undefined, record: InternsForEmployerDTO) => {
				return (
					<a onClick={() => goToStudentProfile(record.id)}>
						<b>{text}</b>
					</a>
				);
			},
		},
		{
			title: t("students.group"),
			key: "group",
			dataIndex: "group",
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.group || !b.group) return -1;
			// 	return a.group.localeCompare(b.group);
			// },
		},
		{
			title: t("students.email"),
			dataIndex: "email",
			key: "email",
			// sorter: (a: InternsForEmployerDTO, b: InternsForEmployerDTO) => {
			// 	if (!a.email || !b.email) return -1;
			// 	return a.email.localeCompare(b.email);
			// },
			render: (_: string | undefined, record: InternsForEmployerDTO) => (
				<span>
					{record.personalEmail !== "" && record.personalEmail !== undefined
						? record.personalEmail
						: record.email}
				</span>
			),
		},
	];

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.failedUserGet"),
			t("usersText.errorTexts.serverFailedDescription"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data, isLoading: loading } = useQuery(
		[getInterns, props.internship.internshipName, query.filters],
		() => {
			return getInternsForEmployerV2({
				type: InternType.Trainee,
				internships: [props.internship.internshipName],
				...query.filters,
			});
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="w-full">
			<h2>{t("header.myStudents")}</h2>

			<div className="flex flex-col md:flex-row justify-between items-center">
				<Typography.Title level={4} className="my-0">
					{data?.totalCount} rezultate
				</Typography.Title>
				{!loading && (
					<Pagination
						defaultCurrent={data?.page}
						defaultPageSize={data?.pageSize}
						total={data?.totalCount}
						onChange={onPageUpdate}
						locale={{ items_per_page: t("pagination") }}
					/>
				)}
			</div>

			<Table
				locale={locale}
				columns={columns}
				dataSource={data?.data ?? []}
				pagination={false}
				scroll={{ x: "calc(300px + 50%)", y: 420 }}
				rowKey={(record) => record.id!}
			/>
		</div>
	);
};
