import { useCallback, useState } from "react";
import styles from "./Profile.module.scss";
import { Button, Pagination, Table, Typography } from "antd";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { ErrorCodes } from "../../Api";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	getAvailablePrograms,
	getSupervisedPrograms,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";
import AddSupervisedStudyProgramModal from "./AddSupervisedStudyProgramModal";
import { getSupervisedStudyProgramsV2 } from "../../Requests/student-study-requests";
import { deleteSupervisedStudyProgram } from "../../Requests/academic-user-requests";
import useQueryFilters from "../../Hooks/useQueryFilters";

const SupervisedStudyPrograms = (props: { supervisorId: string }) => {
	const { t } = useTranslation();
	const [modalVisibility, setModalVisibility] = useState(false);
	const [selectedStudyProgramId, setSelectedStudyProgramId] =
		useState<string>("");
	const [spinning, setSpinning] = useState(false);
	const query = useQueryFilters({});

	let locale = {
		emptyText: t("tableText.noSupervisedStudyPrograms"),
	};

	const queryClient = useQueryClient();
	const invalidateUsersQuery = async () => {
		await queryClient.invalidateQueries(getSupervisedPrograms);
		await queryClient.invalidateQueries(getAvailablePrograms);
	};

	const handleDelete = (id: string) => {
		setSpinning(true);

		deleteSupervisedStudyProgram(props.supervisorId, id)
			.then(async () => {
				await invalidateUsersQuery();

				openNotification(
					t("account.studyProgramDelete"),
					t("account.studyProgramDeleteSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (ex: any) => {
				const error = await getErrorFromResponse(ex);

				openNotification(
					t("account.studyProgramDelete"),
					error?.code === ErrorCodes.Forbidden
						? t("account.noPermissions")
						: error?.code === ErrorCodes.SupervisionsOngoing
						? t("account.supervisionsOngoing")
						: error?.code === ErrorCodes.StudyProgramNotFound
						? t("account.studyProgramNotFound")
						: error?.code === ErrorCodes.SupervisorNotFound
						? t("account.supervisorNotFound")
						: t("account.studyProgramDeleteError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setModalVisibility(false);
			});
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: supervisedPrograms, isLoading } = useQuery(
		[getSupervisedPrograms, props.supervisorId, query.filters],
		() =>
			getSupervisedStudyProgramsV2({
				supervisorId: props.supervisorId,
				...query.filters,
			}),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const columns = [
		{
			title: t("account.name"),
			dataIndex: "name",
			key: "name",
			fixed: "left" as "left",
			// sorter: (a: StudyProgramDTO, b: StudyProgramDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
		},
		{
			title: t("account.domain"),
			dataIndex: "domain",
			key: "domain",
			// sorter: (a: StudyProgramDTO, b: StudyProgramDTO) => {
			// 	if (!a.domain || !b.domain) return -1;
			// 	return a.domain.localeCompare(b.domain);
			// },
		},
		{
			title: t("account.studyCycle"),
			dataIndex: "studyCycle",
			key: "studyCycle",
			// sorter: (a: StudyProgramDTO, b: StudyProgramDTO) => {
			// 	if (!a.studyCycle || !b.studyCycle) return -1;
			// 	return a.studyCycle.localeCompare(b.studyCycle);
			// },
			render: (_text: string | undefined, record: any) =>
				t("account.studyCycles." + record.studyCycle),
		},
		{
			title: t("account.actions"),
			fixed: "right" as "right",
			width: 85,
			render: (_text: string | undefined, record: any) => (
				<div style={{ whiteSpace: "nowrap" }}>
					<div className={styles.modalBtnContainer}>
						<>
							<div className={styles.modalContainer}>
								<Button
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedStudyProgramId(record.id);
										setModalVisibility(true);
									}}
									icon={<FontAwesomeIcon icon={solid("trash")} />}
									title={t("account.deleteStudyProgram")}
								/>
								<ConfirmationModal
									modalText={
										t("account.deleteStudyProgramMessage") +
										record.name +
										t("account.deleteStudyProgramMessage2")
									}
									handleFunction={() => handleDelete(selectedStudyProgramId)}
									modalVisibility={modalVisibility}
									title=""
									changeModalVisibility={() => setModalVisibility(false)}
									spinning={spinning}
								/>
							</div>
						</>
					</div>
				</div>
			),
		},
	];

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 pb-10 flex flex-col gap-4">
			<AddSupervisedStudyProgramModal supervisorId={props.supervisorId} />

			<div className="flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{supervisedPrograms?.totalCount} rezultate
					</Typography.Title>
					{!isLoading && (
						<Pagination
							defaultCurrent={supervisedPrograms?.page}
							defaultPageSize={supervisedPrograms?.pageSize}
							total={supervisedPrograms?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
						/>
					)}
				</div>

				<Table
					locale={locale}
					columns={columns}
					dataSource={supervisedPrograms?.data ?? []}
					pagination={false}
					scroll={{ x: "calc(700px + 50%)", y: 420 }}
					rowKey={(record) => record.id!}
				/>
			</div>
		</div>
	);
};

export default SupervisedStudyPrograms;
