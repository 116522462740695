import {
	CompanyInternshipApi,
	CompanyInternshipAddUpdateDTO,
	InternshipEngagementType,
	FacultyCoordinatorDTO,
	CompanyInternshipRejectionDTO,
	InternshipSortFieldEnum,
	SortDirectionEnum,
	InternshipStatus,
	CompanyInternshipState,
} from "../Api";
import { arrayParamAjust } from "../utils/dataUtils";
import { getAuthConfiguration } from "./configuration";

const AuthCompanyInternshipApiFactory = () =>
	new CompanyInternshipApi(getAuthConfiguration());

export const addCompanyInternship = async (
	companyInternshipAddUpdateDTO: CompanyInternshipAddUpdateDTO
) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipAddPost({
		companyInternshipAddUpdateDTO,
	});
};

export const deleteCompanyInternship = async (id: string) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipDeleteIdDelete({
		id,
	});
};

export const duplicateCompanyInternship = async (id: string) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipDuplicateIdPost({
		id,
	});
};

export const markInternshipAsComplete = async (id: string) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipSendForApprovalIdPut(
		{ id }
	);
};

export const approveInternship = async (id: string) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipApproveIdPut({
		id,
	});
};

export const rejectInternship = async (
	id: string,
	companyInternshipRejectionDTO: CompanyInternshipRejectionDTO
) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipRejectIdPut({
		id,
		companyInternshipRejectionDTO,
	});
};

export const invalidateInternship = async (id: string) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipInvalidateIdPut({
		id,
	});
};

export const acceptCoordination = async (id: string) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipAcceptCoordinationIdPut(
		{ id }
	);
};

export const rejectCoordination = async (id: string) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipRejectCoordinationIdPut(
		{ id }
	);
};

export const getAllCompanyInternship = (
	skills?: Array<string>,
	categories?: Array<string>,
	locations?: Array<string>,
	types?: Array<string>,
	statuses?: Array<string>,
	states?: Array<string>,
	universityYears?: Array<string>,
	searchTerm?: string,
	page?: number,
	pageSize?: number,
	sortField?: InternshipSortFieldEnum,
	sortDirection?: SortDirectionEnum
) => {
	return AuthCompanyInternshipApiFactory().apiCompanyInternshipGetAllGet({
		skills: arrayParamAjust(skills),
		categories: arrayParamAjust(categories),
		locations: arrayParamAjust(locations),
		types: arrayParamAjust(
			types?.map(
				(e) =>
					InternshipEngagementType[e as keyof typeof InternshipEngagementType]
			)
		),
		statuses: arrayParamAjust(
			statuses?.map((e) => InternshipStatus[e as keyof typeof InternshipStatus])
		),
		states: arrayParamAjust(
			states?.map(
				(e) => CompanyInternshipState[e as keyof typeof CompanyInternshipState]
			)
		),
		universityYears: arrayParamAjust(
			universityYears?.map((y) => Number(y.split("-")[0]))
		),
		searchTerm,
		page,
		pageSize,
		sortField,
		sortDirection,
	});
};

export interface AllCompanyProps {
	searchTerm?: string;
	page?: number;
	pageSize?: number;
	sortField?: InternshipSortFieldEnum;
	sortDirection?: SortDirectionEnum;
	skills?: Array<string>;
	categories?: Array<string>;
	Location?: Array<string>;
	Type?: Array<string>;
	Status?: Array<string>;
	State?: Array<string>;
	UniversityYear?: Array<string>;
}

export const getAllCompanyInternshipV2 = (props: AllCompanyProps) => {
	const {
		searchTerm,
		page,
		pageSize,
		sortField,
		sortDirection,
		skills,
		categories,
		Location: locations,
		Type: types,
		Status: statuses,
		State: states,
		UniversityYear: universityYears,
	} = props;

	return AuthCompanyInternshipApiFactory().apiCompanyInternshipGetAllGet({
		skills: arrayParamAjust(skills),
		categories: arrayParamAjust(categories),
		locations: arrayParamAjust(locations),
		types: arrayParamAjust(
			types?.map(
				(e) =>
					InternshipEngagementType[e as keyof typeof InternshipEngagementType]
			)
		),
		statuses: arrayParamAjust(
			statuses?.map((e) => InternshipStatus[e as keyof typeof InternshipStatus])
		),
		states: arrayParamAjust(
			states?.map(
				(e) => CompanyInternshipState[e as keyof typeof CompanyInternshipState]
			)
		),
		universityYears: arrayParamAjust(
			universityYears?.map((y) => Number(y.split("-")[0]))
		),
		searchTerm,
		page,
		pageSize,
		sortField,
		sortDirection,
	});
};

export const getAllCompanyInternshipFilters = () => {
	return AuthCompanyInternshipApiFactory().apiCompanyInternshipGetInternshipFiltersGet();
};

export const getCoordinatedProfessorInternships = (
	skills?: Array<string>,
	categories?: Array<string>,
	locations?: Array<string>,
	types?: Array<string>,
	statuses?: Array<string>,
	universityYears?: Array<string>,
	searchTerm?: string,
	page?: number,
	pageSize?: number,
	sortField?: InternshipSortFieldEnum,
	sortDirection?: SortDirectionEnum
) => {
	return AuthCompanyInternshipApiFactory().apiCompanyInternshipGetCoordinatedProfessorInternshipsGet(
		{
			skills: arrayParamAjust(skills),
			categories: arrayParamAjust(categories),
			locations: arrayParamAjust(locations),
			types: arrayParamAjust(
				types?.map(
					(e) =>
						InternshipEngagementType[e as keyof typeof InternshipEngagementType]
				)
			),
			statuses: arrayParamAjust(
				statuses?.map(
					(e) => InternshipStatus[e as keyof typeof InternshipStatus]
				)
			),
			universityYears: arrayParamAjust(
				universityYears?.map((y) => Number(y.split("-")[0]))
			),
			searchTerm,
			page,
			pageSize,
			sortField,
			sortDirection,
		}
	);
};

export const getCoordinatedProfessorInternshipsFilters = () => {
	return AuthCompanyInternshipApiFactory().apiCompanyInternshipGetCoordinatedProfessorInternshipsFiltersGet();
};

export const getCompanyInternshipById = (id: string) => {
	return AuthCompanyInternshipApiFactory().apiCompanyInternshipGetByIdIdGet({
		id,
	});
};

export const updateCompanyInternshipViewCountByInternshipId = async (
	id: string
) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipUpdateViewCountByInternshipIdIdPut(
		{ id }
	);
};

export const updateCompanyInternshipInfo = async (
	id: string,
	companyInternshipAddUpdateDTO?: CompanyInternshipAddUpdateDTO
) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipUpdateIdPut({
		id,
		companyInternshipAddUpdateDTO,
	});
};

export const updateFacultyCoordinator = async (
	id: string,
	facultyCoordinatorDTO?: FacultyCoordinatorDTO
) => {
	await AuthCompanyInternshipApiFactory().apiCompanyInternshipUpdateFacultyCoordinatorIdPut(
		{ id, facultyCoordinatorDTO }
	);
};

export const changeStudyProgramsCompany = (
	id: string,
	requestBody: Array<string>
) => {
	return AuthCompanyInternshipApiFactory().apiCompanyInternshipChangeStudyProgramIdPut(
		{ id, requestBody }
	);
};

export const approveInternshipStudyProgram = (id: string) => {
	return AuthCompanyInternshipApiFactory().apiCompanyInternshipChangeApproveInternshipStudyProgramIdPut(
		{ id }
	);
};
