import { Typography, Pagination } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { InternshipSortFieldEnum, SortDirectionEnum } from "../../Api";
import { useQuery } from "react-query";
import {
	getFacultyInternships,
	getInternshipsFilters,
} from "../../utils/reactQueriesConstants";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	getAllInternshipTypeEventFilters,
	getAllInternshipsTypeEventV2,
} from "../../Requests/internship-type-event-requests";
import EventList from "./EventList";
import useQueryFilters from "../../Hooks/useQueryFilters";
import Filters from "../Filters";

const getAllKeys = (filterItems: any[]) => {
	const keys: any[] = [];

	filterItems?.forEach((filter) => {
		keys.push(filter.key);

		filter.children.forEach((filterItem: any) => {
			keys.push(filterItem.key.split("/")[1]);
		});
	});

	return keys;
};

const AllEvents = (props: any) => {
	const { t } = useTranslation();
	const [expandedKeys, setExpandedKeys] = useState<any>([]);
	const [checkedKeys, setCheckedKeys] = useState([]);
	const query = useQueryFilters({});

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDatasError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: filters } = useQuery(
		[getInternshipsFilters, props.location.pathname],
		() => {
			return getAllInternshipTypeEventFilters();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
		}
	);

	const { data: events, isLoading: loadingEvents } = useQuery(
		[getFacultyInternships, query.filters],
		() => {
			return getAllInternshipsTypeEventV2(query.filters);
		},
		{
			onError: openGetErrorNotification,
		}
	);

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			console.log(values);
			query.update(values, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 py-10 flex flex-col">
			<Filters
				filters={filters}
				searchFields={[{ name: "searchTerm", label: t("internships.search") }]}
				onUpdate={onUpdate}
				hasSort={true}
			/>

			<div className="flex flex-col gap-10 relative">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{events?.totalCount} rezultate
					</Typography.Title>
					{!loadingEvents && (
						<Pagination
							defaultCurrent={events?.page}
							total={events?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
							pageSizeOptions={[12, 24, 48, 96]}
							defaultPageSize={12}
						/>
					)}
				</div>

				<EventList
					data={events}
					checkedKeys={checkedKeys}
					loading={loadingEvents}
					location={props.location}
					currentPage={1}
					pageSize={12}
					searchTerm={""}
					sortDirection={SortDirectionEnum.Descending}
					sortField={InternshipSortFieldEnum.PublishDate}
				/>
			</div>
		</div>
	);
};

export default AllEvents;
