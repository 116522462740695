import { useCallback, useState } from "react";
import styles from "./Profile.module.scss";
import { Button, Pagination, Table, Typography } from "antd";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getFacultiesOfInterestList } from "../../utils/reactQueriesConstants";
import {
	deleteFacultyOfInterest,
	getFacultiesOfInterestV2,
} from "../../Requests/company-requests";
import AddFacultyOfInterestModal from "./AddFacultyOfInterestModal";
import { useGetCompanyId } from "../../utils/utilFunctions";
import { ErrorCodes } from "../../Api";
import { getErrorFromResponse } from "../../utils/responseUtils";
import useQueryFilters from "../../Hooks/useQueryFilters";

const FacultiesOfInterestTable = (props: { companyId: string }) => {
	const { t, i18n } = useTranslation();
	const [removeModalVisibility, setRemoveModalVisibility] = useState(false);
	const [selectedId, setSelectedId] = useState<string>("");
	const [spinning, setSpinning] = useState(false);
	const companyId = useGetCompanyId();
	const query = useQueryFilters({});

	let locale = {
		emptyText: t("tableText.noFacultyOfInterest"),
	};

	const queryClient = useQueryClient();
	const invalidateQuery = async () => {
		await queryClient.invalidateQueries(getFacultiesOfInterestList);
	};

	const handleDelete = (id: string) => {
		setSpinning(true);

		deleteFacultyOfInterest(id)
			.then(async () => {
				await invalidateQuery();

				openNotification(
					t("account.facultyOfInterestDelete"),
					t("account.facultyOfInterestDeleteSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (ex: any) => {
				const error = await getErrorFromResponse(ex);

				openNotification(
					t("account.supervisorDelete"),
					error?.code === ErrorCodes.Forbidden
						? t("account.noPermissions")
						: error?.code === ErrorCodes.AlreadyDeleted
						? t("account.facultyOfInterestAlreadyDeleted")
						: error?.code === ErrorCodes.CompanyNotFound
						? t("account.companyNotFound")
						: error?.code === ErrorCodes.FacultyNotFound
						? t("account.facultyNotFound")
						: t("account.facultyOfInterestDelete"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setRemoveModalVisibility(false);
			});
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: facultiesOfInterest, isLoading } = useQuery(
		[getFacultiesOfInterestList, companyId, query.filters],
		() => getFacultiesOfInterestV2({ companyId, ...query.filters }),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
		}
	);

	const columns = [
		{
			title: t("account.facultyOfInterest"),
			dataIndex: i18n.language === "ro" ? "facultyNameRo" : "facultyNameEn",
			key: i18n.language === "ro" ? "facultyNameRo" : "facultyNameEn",
			fixed: "left" as "left",
			// sorter: (a: FacultyDTO, b: FacultyDTO) => {
			// 	if (i18n.language === "ro") {
			// 		if (!a.facultyNameRo || !b.facultyNameRo) return -1;
			// 		return a.facultyNameRo.localeCompare(b.facultyNameRo);
			// 	} else {
			// 		if (!a.facultyNameEn || !b.facultyNameEn) return -1;
			// 		return a.facultyNameEn.localeCompare(b.facultyNameEn);
			// 	}
			// },
		},
		{
			title: t("account.actions"),
			width: "100px",
			fixed: "right" as "right",
			render: (_text: string | undefined, record: any) => (
				<div style={{ whiteSpace: "nowrap" }}>
					<div className={styles.modalBtnContainer}>
						<>
							<div className={styles.modalContainer}>
								<Button
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedId(record.id);
										setRemoveModalVisibility(true);
									}}
									icon={<FontAwesomeIcon icon={solid("trash")} />}
									title={t("account.deleteFacultyOfInterest")}
								/>
							</div>
						</>
					</div>
				</div>
			),
		},
	];

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="flex flex-col gap-4">
			<AddFacultyOfInterestModal
				facultiesOfInterestIds={
					facultiesOfInterest?.data?.map((e) => e.id!) ?? []
				}
			/>

			<ConfirmationModal
				modalText={t("account.deleteFacultyOfInterestMessage")}
				handleFunction={() => handleDelete(selectedId)}
				modalVisibility={removeModalVisibility}
				title=""
				changeModalVisibility={() => setRemoveModalVisibility(false)}
				spinning={spinning}
			/>

			<div className="px-4 pb-10 flex flex-col">
				<div className="flex flex-col gap-10">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<Typography.Title level={3} className="my-0">
							{facultiesOfInterest?.totalCount} rezultate
						</Typography.Title>
						{!isLoading && (
							<Pagination
								defaultCurrent={facultiesOfInterest?.page}
								defaultPageSize={facultiesOfInterest?.pageSize}
								total={facultiesOfInterest?.totalCount}
								onChange={onPageUpdate}
								locale={{ items_per_page: t("pagination") }}
							/>
						)}
					</div>

					<Table
						locale={locale}
						columns={columns}
						dataSource={facultiesOfInterest?.data ?? []}
						pagination={false}
						scroll={{ x: "calc(300px + 50%)", y: 420 }}
						rowKey={(record) => record.id!}
					/>
				</div>
			</div>
		</div>
	);
};

export default FacultiesOfInterestTable;
