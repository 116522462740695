import {
	Avatar,
	Button,
	Checkbox,
	Modal,
	Pagination,
	Select,
	Space,
	Spin,
	Table,
	Typography,
	Upload,
} from "antd";
import { getProfessorById } from "../../Requests/academic-user-requests";
import { StatusCodes } from "http-status-codes";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	useGetCompanyId,
	getUserId,
	useIsProfessor,
	useIsCompany,
	isUserLogged,
	useIsFacultyAdmin,
	useIsDean,
	useIsInternshipAdmin,
} from "../../utils/utilFunctions";
import { getCompanyById } from "../../Requests/company-requests";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { getCompanyUserById } from "../../Requests/company-users-requests";
import { useTranslation } from "react-i18next";
import {
	CompanyDocumentDTO,
	CompanyDocumentStatusEnum,
	ErrorCodes,
	InternshipType,
	ProfessionalTitleEnum,
	Role,
} from "../../Api";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CustomButton from "../../CustomComponents/CustomButton";
import { useQuery, useQueryClient } from "react-query";
import {
	getCompany,
	getCompanyUser,
	getDocuments,
	getFaculties,
	getProfessor,
} from "../../utils/reactQueriesConstants";
import PracticeSupervisorTable from "./PracticeSupervisorTable";
import SupervisedStudyProgramsTable from "./SupervisedStudyProgramsTable";
import FacultiesOfInterestTable from "./FacultiesOfInterestTable";
import {
	addNewDocument,
	deleteDocument,
	downloadDocument,
	getCompanyDocuments,
	rejectDocument,
	updateSkipOfferValidationStatus,
} from "../../Requests/company-document-requests";
import { useCallback, useState } from "react";
import { getAllFaculties } from "../../Requests/faculty-requests";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { getErrorFromResponse } from "../../utils/responseUtils";
import CustomEditor from "../../CustomComponents/CustomEditor";
import moment from "moment";
import styles from "./Profile.module.scss";
import { downloadFile } from "../../utils/downloadUtils";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import useQueryFilters from "../../Hooks/useQueryFilters";
import DashboardLayout from "../../layouts/Dashboard";
import { ArrowDownOutlined, UserOutlined } from "@ant-design/icons";

const Profile = () => {
	const { t, i18n } = useTranslation();
	const location = useLocation<
		| {
				id: string;
				type: InternshipType;
				userType: Role;
				filters: any;
				searchTerm: string;
				sortField: any;
				sortDirection: any;
				currentPage: any;
				pageSize: any;
				activeTab: string;
				origin: string;
		  }
		| null
		| undefined
	>();
	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();
	const isFacultyAdmin = useIsFacultyAdmin();
	const isDean = useIsDean();
	const isInternshipAdmin = useIsInternshipAdmin();
	const companyId = useGetCompanyId();
	const [adminFaculties, setAdminFaculties] = useState<any>([]);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrent] = useState(1);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [file, setFile] = useState<Blob>();
	const [documentType, setDocumentType] = useState<any>(null);
	const [selectedFaculty, setSelectedFaculty] = useState(undefined);
	const [selectedDocumentId, setSelectedDocumentId] = useState<any>(undefined);
	const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
	const [rejectionMessage, setRejectionMessage] = useState("");
	const queryClient = useQueryClient();
	const [facultySearch, setFacultySearch] = useState("");
	const [facultyOptions, setFacultyOptions] = useState<any>([]);
	const query = useQueryFilters({});
	const history = useHistory();

	const returnAction = t("universityAccord.uploadedFile");

	const onCheckboxChange = (event: any, id: string) => {
		updateSkipOfferValidationStatus(id)
			.then(async () => {
				await queryClient.invalidateQueries(getDocuments);
				openNotification(
					t("documents.changeStatus"),
					t("documents.statusChangedSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("documents.statusChangedFailed"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const openProfileErrorNotification = (ex: any) => {
		if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
			openNotification(
				t("account.error"),
				t("account.notFoundProfile"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("account.error"),
				t("account.unknownError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const downloadFail = (_err: any) => {
		openNotification(
			t("universityAccord.error"),
			t("universityAccord.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const deleteCompanyDocument = (documentId: string) => {
		deleteDocument(documentId)
			.then(async () => {
				await queryClient.invalidateQueries(getDocuments);
				openNotification(
					t("documents.deleteDocument"),
					t("documents.deleteSuccessfully"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("documents.deleteError"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const columns = [
		{
			title: t("documents.documentType"),
			dataIndex: "documentType",
			key: "documentType",
			fixed: "left" as "left",
			// sorter: (a: CompanyDocumentDTO, b: CompanyDocumentDTO) => {
			// 	if (!a.documentType || !b.documentType) return -1;
			// 	return a.documentType.localeCompare(b.documentType);
			// },
			render: (text: string | undefined, record: any) => {
				return <span>{t("documents." + record.documentType)}</span>;
			},
		},
		{
			title: t("documents.faculty"),
			dataIndex: "facultyNameRo",
			key: "facultyNameRo",
			// sorter: (a: CompanyDocumentDTO, b: CompanyDocumentDTO) => {
			// 	if (i18n.language === "ro") {
			// 		if (!a.facultyNameRo || !b.facultyNameRo) return -1;
			// 		return a.facultyNameRo.localeCompare(b.facultyNameRo);
			// 	} else {
			// 		if (!a.facultyNameEn || !b.facultyNameEn) return -1;
			// 		return a.facultyNameEn.localeCompare(b.facultyNameEn);
			// 	}
			// },
		},
		{
			title: t("documents.uploadDate"),
			dataIndex: "generationDate",
			key: "generationDate",
			// sorter: (a: CompanyDocumentDTO, b: CompanyDocumentDTO) => {
			// 	if (!a.generationDate || !b.generationDate) return -1;
			// 	return a.generationDate < b.generationDate ? -1 : 1;
			// },
			render: (text: string | undefined, record: any) => {
				return (
					<span>{moment(record.generationDate).format("DD.MM.YYYY")}</span>
				);
			},
		},
		{
			title: t("documents.expirationDate"),
			dataIndex: "expirationDate",
			key: "expirationDate",
			// sorter: (a: CompanyDocumentDTO, b: CompanyDocumentDTO) => {
			// 	if (!a.expirationDate || !b.expirationDate) return -1;
			// 	return a.expirationDate < b.expirationDate ? -1 : 1;
			// },
			render: (text: string | undefined, record: any) => {
				return (
					<span>{moment(record.expirationDate).format("DD.MM.YYYY")}</span>
				);
			},
		},
		{
			title: t("documents.status"),
			dataIndex: "documentStatus",
			key: "documentStatus",
			// sorter: (a: CompanyDocumentDTO, b: CompanyDocumentDTO) => {
			// 	if (!a.documentStatus || !b.documentStatus) return -1;
			// 	return a.documentStatus.localeCompare(b.documentStatus);
			// },
			render: (text: string | undefined, record: any) => {
				return <span>{t("documents." + record.documentStatus)}</span>;
			},
		},
		{
			title: t("documents.canPublish"),
			dataIndex: "skipOfferValidation",
			key: "skipOfferValidation",
			render: (text: string | undefined, record: any) => {
				return getUserId().toString() === record.documentOwner ||
					record.facultyId == adminData?.faculty?.id ? (
					<Checkbox
						checked={record.skipOfferValidation}
						disabled={
							getUserId().toString() !== record.documentOwner &&
							record.facultyId != adminData?.faculty?.id
						}
						onChange={(event: any) => onCheckboxChange(event, record.id)}
					/>
				) : (
					<span>
						{record.skipOfferValidation
							? t("documents.yes")
							: t("documents.no")}
					</span>
				);
			},
		},
		{
			title: t("documents.actions"),
			dataIndex: "actions",
			key: "actions",
			fixed: "right" as "right",
			width: 85,
			render: (text: string | undefined, record: any) => (
				<div className={styles.modalContainer} key={"actions-" + record.id}>
					<Space>
						<div className={styles.modalBtnContainer}>
							<CustomButton
								style={{
									background: "transparent",
									border: "none",
									outline: "none",
									color: theme.black,
									boxShadow: "none",
									padding: 0,
								}}
								title={t("documents.download")}
								onClick={() =>
									downloadFile(
										downloadDocument(record.id!),
										t("documents." + record.documentType) +
											"_" +
											record.facultyNameRo +
											"_" +
											companyProfile?.name
									).catch(downloadFail)
								}
							>
								<FontAwesomeIcon
									icon={solid("download")}
									style={{ fontSize: "15px" }}
								/>
							</CustomButton>
						</div>
						{getUserId().toString() === record.documentOwner ? (
							<div className={styles.modalBtnContainer}>
								<CustomButton
									fontSize={"0.9rem"}
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedDocumentId(record.id);
										setModalVisibility(true);
									}}
									icon={
										<FontAwesomeIcon
											icon={solid("trash")}
											style={{ paddingRight: "5%" }}
										/>
									}
									title={t("documents.delete")}
								/>
								<ConfirmationModal
									modalText={t("documents.deleteWarning")}
									handleFunction={() => {
										deleteCompanyDocument(selectedDocumentId);
									}}
									modalVisibility={modalVisibility}
									title=""
									changeModalVisibility={() => setModalVisibility(false)}
									spinning={spinning}
								/>
							</div>
						) : null}
						{(getUserId().toString() === record.documentOwner &&
							isCompany &&
							record.documentStatus !== CompanyDocumentStatusEnum.Finalized) ||
						(!isCompany &&
							record.documentStatus === CompanyDocumentStatusEnum.InProgress &&
							record.facultyId == adminData?.faculty?.id) ? (
							<div className={styles.modalBtnContainer}>
								<CustomButton
									fontSize={"0.9rem"}
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedDocumentId(record.id);
										setSelectedFaculty(record.facultyId);
										setDocumentType("practiceProtocol");
										setIsModalVisible(true);
									}}
									icon={
										<FontAwesomeIcon
											icon={solid("file-pen")}
											style={{ paddingRight: "5%" }}
										/>
									}
									title={t("documents.uploadNewVersion")}
								/>
							</div>
						) : null}
						{record.documentStatus === CompanyDocumentStatusEnum.InProgress &&
						record.facultyId === adminData?.faculty?.id ? (
							<div className={styles.modalBtnContainer}>
								<CustomButton
									fontSize={"0.9rem"}
									style={{
										background: "transparent",
										border: "none",
										outline: "none",
										color: theme.black,
										boxShadow: "none",
										padding: 0,
										marginTop: 0,
									}}
									onClick={() => {
										setSelectedDocumentId(record.id);
										setIsRejectModalVisible(true);
									}}
									icon={
										<FontAwesomeIcon
											icon={solid("ban")}
											style={{ paddingRight: "5%" }}
										/>
									}
									title={t("documents.rejectDocument")}
								/>
							</div>
						) : null}
					</Space>
				</div>
			),
		},
	];

	const { data: professorProfile } = useQuery(
		[getProfessor, location, isProfessor],
		async () =>
			location.state && location.state.userType === Role.Professor
				? getProfessorById(location.state.id)
				: !location.state && isProfessor
				? getProfessorById(getUserId())
				: null,
		{
			onError: openProfileErrorNotification,
		}
	);

	const { data: companyUser } = useQuery(
		[getCompanyUser, location, isCompany, companyId],
		async () =>
			isCompany && (!location.state || location.state?.id === companyId)
				? getCompanyUserById(getUserId())
				: null,
		{
			onError: openProfileErrorNotification,
		}
	);

	const { data: companyProfile } = useQuery(
		[getCompany, location, isCompany, companyId],
		async () =>
			location.state && location.state.userType === Role.Company
				? getCompanyById(location.state.id)
				: !location.state && isCompany
				? getCompanyById(companyId)
				: null,
		{
			onError: openProfileErrorNotification,
		}
	);

	const data = professorProfile
		? {
				id: professorProfile.id,
				image: professorProfile.fullResImage ?? professorProfile.lowResImage,
				departmentNameRo: professorProfile.departmentNameRo,
				departmentNameEn: professorProfile.departmentNameEn,
				phone: professorProfile.phone,
				email: professorProfile.email,
				personalEmail: professorProfile.personalEmail,
				description: professorProfile.description,
				name: professorProfile.name,
				professionalTitle: professorProfile.professionalTitle,
				siteUrl: professorProfile.siteUrl,
		  }
		: companyProfile
		? {
				id: companyProfile.id,
				logo: companyProfile.fullResLogo ?? companyProfile.lowResLogo,
				domainOfActivity: companyProfile.domainOfActivity,
				phone: companyProfile.phone,
				email: companyProfile.email,
				description: companyProfile.description,
				name: companyProfile.commercialName,
				linkedInUrl: companyProfile.linkedInUrl,
				siteUrl: companyProfile.siteUrl,
		  }
		: null;

	const openNotificationErrorFetchDocuments = (_ex: any) => {
		openNotification(
			t("usersText.errorTexts.error"),
			t("documents.documentsFetchFailed"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: allFacultiesList } = useQuery(
		[getFaculties, isFacultyAdmin],
		() => (isFacultyAdmin ? getAllFaculties("", 1, 20) : []),
		{
			onError: (err) => {
				openGetErrorNotification(err);
			},
			refetchOnWindowFocus: false,
		}
	);

	const fetchDataErrorNotification = (ex: any) => {
		if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
			openNotification(
				t("account.error"),
				t("account.fetchUserDataError"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("account.error"),
				t("account.unknownError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data: adminData } = useQuery(
		[getProfessor, isFacultyAdmin],
		async () => (isFacultyAdmin ? getProfessorById(getUserId()) : null),
		{
			onError: fetchDataErrorNotification,
		}
	);

	const { data: documents, isLoading: loading } = useQuery(
		[
			getDocuments,
			location.state?.userType,
			location.state?.id,
			documentTypes,
			currentPage,
			pageSize,
			isFacultyAdmin,
		],
		() => {
			if (
				isFacultyAdmin &&
				location.state &&
				location.state.userType === Role.Company
			) {
				return getCompanyDocuments(
					location.state?.id,
					documentTypes,
					currentPage,
					pageSize
				);
			} else {
				return null;
			}
		},
		{
			onError: openNotificationErrorFetchDocuments,
			onSuccess: (data) => {
				if (data) {
					if (data?.data !== null && data?.data !== undefined) {
						let auxFaculties = [adminData?.faculty?.id];
						for (let i = 0; i < data?.data.length; i++) {
							let facultyId = data.data[i].facultyId;
							auxFaculties = auxFaculties?.filter((e) => e !== facultyId);
						}
						setAdminFaculties(auxFaculties);
					}
				}
			},
		}
	);

	const handleShow = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setDocumentType(null);
		setSelectedFaculty(undefined);
		setFile(undefined);
		setSelectedDocumentId(undefined);
		setIsModalVisible(false);
		setIsRejectModalVisible(false);
	};

	const uploadDocument = (
		companyId: string,
		file: Blob,
		facultyId?: string,
		documentId?: string
	) => {
		addNewDocument(companyId, file, facultyId, documentId)
			.then(async () => {
				await queryClient.invalidateQueries(getDocuments);
				openNotification(
					t("documents.uploadedDocument"),
					t("documents.uploadSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("usersText.errorTexts.error"),
					t("documents.uploadFail"),
					NOTIFICATION_TYPES.ERROR
				);
			});
	};

	const handleOk = () => {
		setSpinning(true);
		if (
			location.state?.userType == Role.Company &&
			location.state?.id &&
			file !== undefined
		) {
			uploadDocument(
				location.state?.id,
				file,
				selectedFaculty,
				selectedDocumentId
			);
		}
		setDocumentType(null);
		setSelectedFaculty(undefined);
		setSpinning(false);
		setFile(undefined);
		setSelectedDocumentId(undefined);
		setIsModalVisible(false);
	};

	const onChange = (event: string) => {
		setDocumentType(event);
	};

	const handleSelectedFaculties = (selected: any) => {
		setSelectedFaculty(selected);
	};

	const rejectCompanyDocument = (documentId: string, message: string) => {
		setSpinning(true);

		rejectDocument(documentId, message)
			.then(() => {
				openNotification(
					t("documents.documentRejection"),
					t("documents.documentRejectionSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);

				setRejectionMessage("");
				setIsRejectModalVisible(false);
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.DocumentNotFound:
						notificationMessage = t("documents.documentNotFound");
						break;
					case ErrorCodes.Forbidden:
						notificationMessage = t("documents.notAllowed");
						break;
					default:
						notificationMessage = t("documents.documentRejectionError");
						break;
				}

				openNotification(
					t("documents.documentRejection"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
			});
	};

	let locale = {
		emptyText: t("tableText.noDocuments"),
	};

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	const goToEdit = () => history.push("/contul-meu");

	if (!isUserLogged() && !location.state) {
		return <Redirect to="/404" />;
	} else {
		return (
			<DashboardLayout>
				<Spin
					size="large"
					spinning={!professorProfile && !companyProfile}
					tip={t("account.loading")}
				>
					<div className="flex items-center gap-2 ml-2 mt-2">
						<ArrowDownOutlined onClick={() => history.goBack()} className="rotate-90 text-4xl text-primary" />
					</div>
					<div className="flex flex-col pt-20 pb-10 items-center">
						<div className="relative w-full pb-10">
							<div className="absolute w-full top-32 md:top-14 bottom-0 bg-primary"></div>

							<div className="relative grid md:grid-rows-[auto_1fr] grid-cols-1 md:grid-cols-[218px_auto] gap-12 max-w-[727px] w-full mx-auto">
								<div className="row-span-2 flex flex-col gap-6 items-center">
									<Avatar
										size={218}
										src={data?.image || data?.logo}
										icon={<UserOutlined />}
									/>

									{(companyUser?.id === getUserId() ||
										professorProfile?.id === getUserId()) &&
									((isCompany &&
										companyUser &&
										(companyUser.isRepresentative || companyUser.isAdmin)) ||
										isProfessor) ? (
										<Button className="bg-primary-faded" onClick={goToEdit}>
											Editeaza profil
										</Button>
									) : null}
								</div>

								<div className="font-bold text-primary text-2xl order-first md:order-none justify-center md:justify-start flex items-center gap-2">
									{data?.professionalTitle &&
									data?.professionalTitle !==
										ProfessionalTitleEnum.NotApplicable
										? t("professionalTitle." + data?.professionalTitle)
										: ""}
									<span>{data?.name}</span>

									{data?.email &&
										location.state?.userType !== Role.Company &&
										!isCompany &&
										data.id !== getUserId() && (
											<Button
												type="text"
												href={
													"https://teams.microsoft.com/l/chat/0/0?users=" +
													data?.email
												}
												target="_blank"
												rel="noopener noreferrer"
												style={{
													backgroundColor: "transparent",
													float: "left",
													padding: "0",
												}}
												title={t("students.teams")}
											>
												<FontAwesomeIcon
													icon={solid("comment")}
													className="text-2xl"
												/>
											</Button>
										)}

									{data?.siteUrl && (
										<Button
											type="text"
											href={data?.siteUrl}
											target="_blank"
											rel="noreferrer"
											style={{
												backgroundColor: "transparent",
												float: "left",
												padding: "0",
											}}
											title={t("profile.siteUrl")}
										>
											<FontAwesomeIcon
												icon={solid("link")}
												className="text-2xl"
											/>
										</Button>
									)}

									{data?.linkedInUrl && (
										<Button
											type="text"
											href={data?.linkedInUrl!}
											target="_blank"
											rel="noreferrer"
											style={{
												backgroundColor: "transparent",
												padding: "0",
											}}
											title={t("students.linkedinUrl")}
										>
											<FontAwesomeIcon
												icon={brands("linkedin")}
												className="text-2xl"
											/>
										</Button>
									)}
								</div>

								<div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-fit text-white order-last md:order-none mx-auto md:mx-0 text-center md:text-left">
									{i18n.language === "ro" ? (
										data?.departmentNameRo ? (
											<>
												<div className="font-bold">Departament</div>
												<div>{data?.departmentNameRo}</div>
											</>
										) : null
									) : data?.departmentNameEn ? (
										<>
											<div className="font-bold">Department</div>
											<div>{data?.departmentNameEn}</div>
										</>
									) : null}

									{data?.domainOfActivity && (
										<>
											<div className="font-bold">
												{t("signupText.activityDomain")}
											</div>
											<div>{data?.domainOfActivity}</div>
										</>
									)}

									{data?.phone && (
										<>
											<div className="font-bold">{t("account.phone")}</div>
											<div>{data?.phone}</div>
										</>
									)}

									{data?.email && (
										<>
											{" "}
											<div className="font-bold self-center">
												{t("account.email")}
											</div>
											<div className="flex items-center">
												{data?.email}
												<Button
													type="text"
													style={{
														backgroundColor: "transparent",
													}}
													onClick={() => {
														navigator.clipboard
															.writeText(data?.email || "")
															.then(() => {
																openNotification(
																	t("profile.emailCopied"),
																	t("profile.emailCopiedMessage"),
																	NOTIFICATION_TYPES.SUCCESS
																);
															});
													}}
												>
													<FontAwesomeIcon
														icon={solid("copy")}
														className="text-2xl text-white"
														title={t("account.copyToClipboard")}
													/>
												</Button>
											</div>
										</>
									)}

									{data?.personalEmail && (
										<>
											{" "}
											<div className="font-bold self-center">
												{t("account.email")}
											</div>
											<div className="flex items-center">
												{data?.personalEmail}
												<Button
													type="text"
													style={{
														backgroundColor: "transparent",
													}}
													onClick={() => {
														navigator.clipboard
															.writeText(data?.personalEmail || "")
															.then(() => {
																openNotification(
																	t("profile.emailCopied"),
																	t("profile.emailCopiedMessage"),
																	NOTIFICATION_TYPES.SUCCESS
																);
															});
													}}
												>
													<FontAwesomeIcon
														icon={solid("copy")}
														className="text-2xl text-white"
														title={t("account.copyToClipboard")}
													/>
												</Button>
											</div>
										</>
									)}
								</div>
							</div>
						</div>

						<div
							className="max-w-[727px] w-full mx-auto px-4"
							dangerouslySetInnerHTML={{ __html: data?.description || "" }}
						></div>

						{isFacultyAdmin &&
							location.state &&
							location.state.userType === Role.Company && (
								<div className="px-4 w-full">
									<h2>{t("documents.documents")}</h2>

									<Button
										onClick={handleShow}
										disabled={adminFaculties?.length === 0}
										className="mb-6"
									>
										{t("documents.uploadDocument")}
									</Button>

									<Modal
										open={isModalVisible}
										maskClosable={!spinning}
										onCancel={handleCancel}
										onOk={handleOk}
										title={""}
										width={window.innerWidth > 1215 ? "50%" : "80%"}
										footer={
											<CustomModalFooter
												handleClose={handleCancel}
												spinning={spinning}
												handleSave={handleOk}
												confirmButtonName={t("account.confirm")}
											/>
										}
									>
										<Spin spinning={spinning}>
											<p>{t("documents.selectDocumentText")}</p>
											<div className="flex flex-col gap-6">
												{selectedDocumentId === undefined && (
													<Select
														className="w-full"
														value={documentType}
														onChange={onChange}
														placeholder={t("documents.selectDocumentType")}
														options={[
															{
																label: t("documents.practiceProtocol"),
																value: "practiceProtocol",
															},
														]}
														allowClear
													/>
												)}
												{isCompany && selectedDocumentId === undefined && (
													<Select
														value={selectedFaculty}
														allowClear
														filterOption={false}
														onSearch={setFacultySearch}
														showSearch
														placeholder={t("documents.selectFaculty")}
														className="w-full"
														onChange={handleSelectedFaculties}
													>
														{facultyOptions}
													</Select>
												)}

												<Upload
													accept=".pdf"
													action={(uploaded) => {
														setFile(uploaded);
														return returnAction;
													}}
													showUploadList={true}
													multiple={false}
													customRequest={({ onSuccess }) => {
														onSuccess && onSuccess("");
													}}
												>
													<Button
														disabled={documentType === null}
														icon={<FontAwesomeIcon icon={solid("upload")} />}
														title={t("documents.uploadPdf")}
														style={{ marginTop: "2rem" }}
													>
														&nbsp;{t("documents.upload")}
													</Button>
												</Upload>
											</div>
										</Spin>
									</Modal>
									<Modal
										open={isRejectModalVisible}
										onCancel={handleCancel}
										onOk={() =>
											rejectCompanyDocument(
												selectedDocumentId,
												rejectionMessage
											)
										}
										title={t("documents.documentRejection")}
										width={window.innerWidth > 1215 ? "50%" : "80%"}
										footer={
											<CustomModalFooter
												handleClose={handleCancel}
												spinning={spinning}
												handleSave={() =>
													rejectCompanyDocument(
														selectedDocumentId,
														rejectionMessage
													)
												}
												confirmButtonName={t("account.confirm")}
											/>
										}
									>
										<Spin spinning={spinning}>
											<p>{t("documents.rejectDocumentMessage")}</p>

											<CustomEditor
												content={rejectionMessage}
												onEditorChange={(newValue: string) =>
													setRejectionMessage(newValue)
												}
											/>
										</Spin>
									</Modal>

									<div className="px-4 pb-10 flex flex-col">
										<div className="flex flex-col gap-10">
											<div className="flex flex-col md:flex-row justify-between items-center">
												<Typography.Title level={3} className="my-0">
													{documents?.totalCount} rezultate
												</Typography.Title>
												{!loading && (
													<Pagination
														defaultCurrent={documents?.page}
														defaultPageSize={documents?.pageSize}
														total={documents?.totalCount}
														onChange={onPageUpdate}
														locale={{ items_per_page: t("pagination") }}
													/>
												)}
											</div>

											<Table
												locale={locale}
												columns={columns}
												dataSource={documents?.data ?? []}
												pagination={false}
												scroll={{ x: "calc(700px + 50%)", y: 420 }}
												rowKey={(record) => record.id!}
											/>
										</div>
									</div>
								</div>
							)}

						<div className="px-4 w-full">
							{isFacultyAdmin &&
								location.state &&
								location.state.userType === Role.Company && (
									<PracticeSupervisorTable companyId={location.state.id} />
								)}
							{(isFacultyAdmin || isDean) &&
								// own profile -> an admin/dean who also is an internship admin
								((!location.state && isInternshipAdmin) ||
									// someone else's profile who is an internship admin
									(location.state &&
										location.state.userType === Role.Professor &&
										professorProfile?.roles?.includes(
											Role.InternshipAdmin
										))) && (
									<SupervisedStudyProgramsTable
										supervisorId={
											location.state ? location.state.id : getUserId()
										}
									/>
								)}
							{isCompany && (
								<FacultiesOfInterestTable
									companyId={location.state ? location.state.id : getUserId()}
								/>
							)}
						</div>
					</div>
				</Spin>
			</DashboardLayout>
		);
	}
};

export default Profile;
