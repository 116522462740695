import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, GetProp, Upload, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/lib/upload";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (img: FileType, callback: (url: string) => void) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result as string));
	reader.readAsDataURL(img);
};

export default function UserAvatar({
	onChange,
	onDelete,
	value,
}: {
	onChange: (value: string) => void;
	onDelete: (value: string) => void;
	value: string | null | undefined;
}) {
	const handleChange = (file: RcFile, callback: (event: any) => void) => {
		const newFile = file;

		if (!newFile) return;

		getBase64(newFile as FileType, (url) => {
			callback(url);
		});
	};

	const handleDelete = () => onDelete("");

	return (
		<div className="relative">
			{value ? (
				<img
					src={value}
					alt="avatar"
					className="w-[172px] h-[172px] rounded-full"
				/>
			) : (
				<div className="w-[172px] h-[172px] rounded-full flex items-center justify-center bg-gray-300">
					No image
				</div>
			)}

			<div className="absolute bottom-0 translate-y-1/2 right-0 flex gap-2">
				<ImgCrop rotationSlider>
					<Upload
						showUploadList={false}
						beforeUpload={(value) => handleChange(value, onChange)}
					>
						<Button
							type="primary"
							shape="circle"
							icon={<DownloadOutlined />}
							size={"large"}
						/>
					</Upload>
				</ImgCrop>

				<Button
					onClick={handleDelete}
					type="primary"
					danger
					shape="circle"
					icon={<DeleteOutlined />}
					size={"large"}
				/>
			</div>
		</div>
	);
}
