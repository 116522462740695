import moment from "moment";
import { useQuery } from "react-query";
import {
	ExtendedViewStudentDTO,
	Role,
	SimpleViewAcademicUserDTO,
	PushNotificationSubscriptionDTO,
	StudentYearEnum,
	Filter,
} from "../Api";
import {
	getRolesForUser,
	getStudentById,
} from "../Requests/academic-user-requests";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { t } from "i18next";
import { getRoles } from "./reactQueriesConstants";
import { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import {
	askUserPermission,
	subscribe,
	unsubscribe,
	getUserSubscription,
	isPushNotificationSupported,
	registerServiceWorker,
} from "./pushNotifications";
import {
	registerPushNotificationsSubscription,
	unregisterPushNotificationsSubscription,
} from "../Requests/push-notification-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Components/Notifications/NotificationsUtils";
import {
	getCompaniesFeedbackEnabledFlagValue,
	getPhdFeedbackEnabledFlagValue,
	getProfessorsFeedbackEnabledFlagValue,
	getStudentsFeedbackEnabledFlagValue,
} from "../Requests/feedback-feature-flags-requests";

interface JWTPayload extends JwtPayload {
	companyId: string;
	facultyId: string;
}

export function getUserId(): any {
	return localStorage.getItem("token") != null
		? jwt_decode<JWTPayload>(localStorage.getItem("token")!)?.sub
		: "";
}

export function useGetCompanyId(): any {
	return useRoles().data?.includes(Role.Company)
		? localStorage.getItem("token") != null
			? jwt_decode<JWTPayload>(localStorage.getItem("token")!)?.companyId
			: ""
		: null;
}

export function getTokenExpire(): any {
	return localStorage.getItem("token") != null
		? jwt_decode<JWTPayload>(localStorage.getItem("token")!)?.exp
		: "";
}

export function isUserLogged(): boolean {
	return localStorage.getItem("token") != null;
}

export const getBaseUrl = () => {
	return `${window.location.protocol}//${window.location.hostname}${
		["80", "443", ""].includes(window.location.port)
			? ""
			: ":" + window.location.port
	}/`;
};

export function logout(isAcademic: boolean, i18n: any): void {
	localStorage.clear();
	i18n.changeLanguage(i18n.language);
}

export const capitalizeFirst = (str: any) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeFirstLetterFromEachWordInASentence = (str: any) => {
	const arr = str.split(" ");

	for (let i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
	}

	let newStr = arr.join(" ");
	const arr2 = newStr.split("-");

	for (let i = 0; i < arr2.length; i++) {
		arr2[i] = arr2[i].charAt(0).toUpperCase() + arr2[i].slice(1);
	}

	return arr2.join("-");
};

export function useBeforeRender(callback: any, deps: any) {
	const [isRun, setIsRun] = useState(false);

	if (!isRun) {
		callback();
		setIsRun(true);
	}

	useEffect(() => () => setIsRun(false), deps);
}

export default function useRoles() {
	return useQuery([getRoles], () => (isUserLogged() ? getRolesForUser() : []), {
		refetchOnWindowFocus: false,
	});
}

export const useIsProfessor = () => {
	return useRoles().data?.includes(Role.Professor);
};

export const useIsStudent = () => {
	return useRoles().data?.includes(Role.Student);
};

export const useIsCompany = () => {
	return useRoles().data?.includes(Role.Company);
};

export const useIsDepartmentSecretary = () => {
	return useRoles().data?.includes(Role.DepartmentSecretary);
};

export const useIsAdmin = () => {
	return useRoles().data?.includes(Role.Admin);
};

export const useIsNewsletterAdmin = () => {
	return useRoles().data?.includes(Role.NewsletterAdmin);
};

export const useIsFacultyAdmin = () => {
	return useRoles().data?.includes(Role.FacultyAdmin);
};

export const useIsInternshipAdmin = () => {
	return useRoles().data?.includes(Role.InternshipAdmin);
};

export const useIsDean = () => {
	var data = useRoles().data;
	return data?.includes(Role.Dean) || data?.includes(Role.ViceDean);
};

export const useIsDepartmentDirector = () => {
	return useRoles().data?.includes(Role.DepartmentDirector);
};

export const useIsStudyProgramCoordinator = () => {
	return useRoles().data?.includes(Role.StudyProgramCoordinator);
};

export const useIsRector = () => {
	return useRoles().data?.includes(Role.Rector);
};

export const useIsAdministrativeAccordsResponsible = () => {
	return useRoles().data?.includes(Role.AdministrativeAccordsResponsible);
};

export const useIsFeedbackAdmin = () => {
	return useRoles().data?.includes(Role.FeedbackAdmin);
};

export const useIsFacultyContactsResponsible = () => {
	return useRoles().data?.includes(Role.FacultyContactsResponsible);
};

export function useUser(): any {
	const isStudent = useRoles().data?.includes(Role.Student);
	return useQuery(
		["getStudent", isStudent],
		() =>
			isUserLogged() && isStudent
				? getStudentById(getUserId())
				: ({} as ExtendedViewStudentDTO),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		}
	);
}

export const useIsDoctorateStudent = () => {
	const { data } = useUser();
	return (
		!!data &&
		(data.year === StudentYearEnum.D1 ||
			data.year === StudentYearEnum.D2 ||
			data.year === StudentYearEnum.D3 ||
			data.year === StudentYearEnum.D4 ||
			data.year === StudentYearEnum.D5)
	);
};

export const useIsUPBAcademicUser = () => {
	const { data } = useRoles();

	return (
		data?.includes(Role.Rector) ||
		data?.includes(Role.Dean) ||
		data?.includes(Role.DepartmentDirector) ||
		data?.includes(Role.StudyProgramCoordinator) ||
		data?.includes(Role.InternshipAdmin) ||
		data?.includes(Role.Admin) ||
		data?.includes(Role.FacultyAdmin) ||
		data?.includes(Role.Professor) ||
		data?.includes(Role.GeneralDirector) ||
		data?.includes(Role.EconomicDirector) ||
		data?.includes(Role.LegalCounselor) ||
		data?.includes(Role.PreventiveFinancialControl)
	);
};

export const useIsUPBAdministrativeUser = () => {
	const { data } = useRoles();

	return (
		data?.includes(Role.AdministrativeAccordsResponsible) ||
		data?.includes(Role.LegalCounselor) ||
		data?.includes(Role.PreventiveFinancialControl) ||
		data?.includes(Role.UniversityAccordResponsible) ||
		data?.includes(Role.EconomicDirector) ||
		data?.includes(Role.GeneralDirector) ||
		data?.includes(Role.Rector)
	);
};

export const useIsGeneralDirector = () => {
	return useRoles().data?.includes(Role.GeneralDirector);
};

export const useIsEconomicDirector = () => {
	return useRoles().data?.includes(Role.EconomicDirector);
};

export const useIsLegalCounselor = () => {
	return useRoles().data?.includes(Role.LegalCounselor);
};

export const useIsPreventiveFinancialControl = () => {
	return useRoles().data?.includes(Role.PreventiveFinancialControl);
};

export const useIsUniversityAccordResponsible = () => {
	return useRoles().data?.includes(Role.UniversityAccordResponsible);
};

export function useIsStudentsFeedbackEnabled() {
	return useQuery(
		["studentsFeedback"],
		async () => await getStudentsFeedbackEnabledFlagValue()
	);
}

export function useIsPhdStudentsFeedbackEnabled() {
	return useQuery(
		["phdFeedback"],
		async () => await getPhdFeedbackEnabledFlagValue()
	);
}

export function useIsProfessorsFeedbackEnabled() {
	return useQuery(
		["professorsFeedback"],
		async () => await getProfessorsFeedbackEnabledFlagValue()
	);
}

export function useIsCompaniesFeedbackEnabled() {
	return useQuery(
		["companiesFeedback"],
		async () => await getCompaniesFeedbackEnabledFlagValue()
	);
}

export const getKey = (record: SimpleViewAcademicUserDTO) => {
	return record.id!;
};

export const getAllKeys = (filterItems: any[], keySeparator?: string) => {
	const keys: any[] = [];

	filterItems.forEach((filter) => {
		keys.push(filter.key);

		filter.children.forEach((filterItem: any) => {
			keys.push(filterItem.key.split(keySeparator ?? "@")[1]);
		});
	});

	return keys;
};

export function geti18nLanguage(): any {
	return localStorage.getItem("i18nextLng");
}

export const addMonths = (date: Date, months: number): moment.Moment => {
	const newDate = moment(date);
	newDate.add(months, "months");

	return newDate;
};

export const safeOr = (array: (boolean | undefined)[]) => {
	return array.reduce(
		(a, b) => (a === undefined || b === undefined ? undefined : a || b),
		false
	);
};

export const safeAnd = (array: (boolean | undefined)[]) => {
	return array.reduce(
		(a, b) => (a === undefined && b === undefined ? undefined : a && b),
		true
	);
};

export const getCurrentUniversityYear = () => {
	const now = new Date();
	return now < new Date(now.getFullYear(), 10, 1)
		? now.getFullYear() - 1
		: now.getFullYear();
};

export const internshipStatus = (from: any, to: any): boolean => {
	const today = new Date().setHours(0, 0, 0, 0);
	return (
		!!from &&
		!!to &&
		new Date(from).setHours(0, 0, 0, 0) <= today &&
		today <= new Date(to).setHours(0, 0, 0, 0)
	);
};

export const computeDateMessage = (date: Date): string => {
	const currentDate = new Date();
	const timeDiff = currentDate.getTime() - date.getTime();
	const oneMinute = 60 * 1000;
	const oneHour = 60 * oneMinute;
	const oneDay = 24 * oneHour;
	const oneWeek = 7 * oneDay;
	const twoWeeks = 2 * oneWeek;

	if (timeDiff < oneMinute) {
		return t("notifications.time.justNow");
	} else if (timeDiff < oneHour) {
		const minutes = Math.floor(timeDiff / oneMinute);
		return `${t("notifications.time.ago1")} ${minutes} ${t(
			"notifications.time." +
				(minutes === 1
					? "minute"
					: minutes % 100 >= 20
					? "minutes1"
					: "minutes2")
		)} ${t("notifications.time.ago2")}`;
	} else if (timeDiff < oneDay) {
		const hours = Math.floor(timeDiff / oneHour);
		return `${t("notifications.time.ago1")} ${hours} ${t(
			"notifications.time." +
				(hours === 1 ? "hour" : hours % 100 >= 20 ? "hours1" : "hours2")
		)} ${t("notifications.time.ago2")}`;
	} else if (
		timeDiff < oneWeek &&
		currentDate.getDate() - date.getDate() === 1
	) {
		return `${t(
			"notifications.time.yesterdayAt"
		)} ${date.getHours()}:${date.getMinutes()}`;
	} else if (timeDiff < oneWeek) {
		return `${t(
			"notifications.time.week." +
				date.toLocaleString("default", { weekday: "long" })
		)}`;
	} else if (timeDiff < twoWeeks) {
		return t("notifications.time.lastWeek");
	} else {
		return date.toLocaleDateString(geti18nLanguage());
	}
};

export const reduceImageSize = (file: Blob) => {
	return new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			64,
			64,
			"PNG",
			80,
			0,
			(uri) => {
				resolve(uri);
			},
			"base64"
		);
	});
};

export const usePushNotifications = () => {
	// We initialize the userConsent with that value
	// to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
	const [userConsent, setUserConsent] = useState(Notification.permission);

	// to manage the push server subscription
	const [userSubscription, setUserSubscription] =
		useState<PushSubscription | null>(null);

	// to manage errors
	const [error, setError] = useState<any>(null);

	// to manage async actions
	const [loading, setLoading] = useState(true);

	// check if the push notifications are supported by the browser
	const pushNotificationSupported = isPushNotificationSupported();

	// if the push notifications are supported, registers the service worker
	// this effect runs only the first render
	useEffect(() => {
		if (pushNotificationSupported) {
			setLoading(true);
			setError(false);
			registerServiceWorker().then(() => {
				setLoading(false);
			});
		}
	}, [pushNotificationSupported]);

	// Retrieve if there is any push notification subscription for the registered service worker
	// this useEffect runs only in the first render
	useEffect(() => {
		setLoading(true);
		setError(false);
		const getExistingSubscription = async () => {
			const existingSubscription = await getUserSubscription();
			setUserSubscription(existingSubscription);
			setLoading(false);
		};
		getExistingSubscription();
	}, []);

	/**
	 * define a click handler that asks the user permission,
	 * it uses the setUserConsent state, to set the consent of the user
	 * If the user denies the consent, an error is created with the setError hook
	 */
	const askPushNotificationsUserPermission = async () => {
		setLoading(true);
		setError(false);

		const consent = await askUserPermission();
		setUserConsent(consent);

		if (consent !== "granted") {
			setError({
				name: "Consent denied",
				message: "You denied the consent to receive notifications",
				code: 0,
			});
		}

		setLoading(false);

		return consent;
	};

	/**
	 * define a click handler that creates a push notification subscription.
	 * Once the subscription is created, it uses the setUserSubscription hook
	 */
	const subscribeToPushNotifications = async () => {
		let consent = userConsent;

		if (userConsent !== "granted") {
			consent = await askPushNotificationsUserPermission();
		}

		if (consent === "granted") {
			setLoading(true);
			setError(false);

			subscribe()
				.then((subscription: PushSubscription) => {
					setUserSubscription(subscription);

					let json = JSON.parse(JSON.stringify(subscription));
					let body: PushNotificationSubscriptionDTO = {
						endpoint: json.endpoint,
						p256dh: json.keys.p256dh,
						auth: json.keys.auth,
					};

					registerPushNotificationsSubscription(body)
						.then(() => {
							openNotification(
								t("usersText.notifications.subscribe"),
								t("usersText.notifications.subscribeSuccessMessage"),
								NOTIFICATION_TYPES.SUCCESS
							);
							setLoading(false);
						})
						.catch((error: any) => {
							openNotification(
								t("usersText.notifications.subscribe"),
								t("usersText.notifications.subscribeErrorMessage"),
								NOTIFICATION_TYPES.ERROR
							);
							setLoading(false);
						});
				})
				.catch((err: any) => {
					openNotification(
						t("usersText.notifications.subscribe"),
						t("usersText.notifications.subscribeErrorMessage"),
						NOTIFICATION_TYPES.ERROR
					);
					setError(err);
					setLoading(false);
				});
		}
	};

	/**
	 * define a click handler that creates a push notification subscription.
	 * Once the subscription is created, it uses the setUserSubscription hook
	 */
	const unsubscribeFromPushNotifications = () => {
		setLoading(true);
		setError(false);

		unsubscribe()
			.then(() => {
				setUserSubscription(null);

				unregisterPushNotificationsSubscription()
					.then(() => {
						openNotification(
							t("usersText.notifications.unsubscribe"),
							t("usersText.notifications.unsubscribeSuccessMessage"),
							NOTIFICATION_TYPES.SUCCESS
						);
						setLoading(false);
					})
					.catch((error: any) => {
						openNotification(
							t("usersText.notifications.unsubscribe"),
							t("usersText.notifications.unsubscribeErrorMessage"),
							NOTIFICATION_TYPES.ERROR
						);
						setLoading(false);
					});
			})
			.catch((err: any) => {
				openNotification(
					t("usersText.notifications.unsubscribe"),
					t("usersText.notifications.unsubscribeErrorMessage"),
					NOTIFICATION_TYPES.ERROR
				);
				setError(err);
				setLoading(false);
			});
	};

	/**
	 * returns all the stuff needed by a Component
	 */
	return {
		subscribeToPushNotifications,
		unsubscribeFromPushNotifications,
		userConsent,
		pushNotificationSupported,
		userSubscription,
		error,
		loading,
	};
};
