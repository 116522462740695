/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	StudentStudyProgramDTO,
	StudentStudyProgramDTOFromJSON,
	StudentStudyProgramDTOToJSON,
	StudyProgramDTO,
	StudyProgramDTOFromJSON,
	StudyProgramDTOToJSON,
	StudyProgramDTOPagedResponse,
	StudyProgramDTOPagedResponseFromJSON,
	StudyProgramDTOPagedResponseToJSON,
} from "../models";

export interface ApiStudentStudyGetAvailableStudyProgramsSupervisorIdGetRequest {
	supervisorId: string;
}

export interface ApiStudentStudyGetStudentStudyProgramsGetRequest {
	studentId?: string;
}

export interface ApiStudentStudyGetStudyProgramsGetRequest {
	facultyIds?: Array<string>;
}

export interface ApiStudentStudyGetSupervisedStudyProgramsSupervisorIdGetRequest {
	supervisorId: string;
	namePattern?: string;
	page?: number;
	pageSize?: number;
}

export interface ApiStudentStudySetCurrentStudentStudyProgramPutRequest {
	studyProgramId?: string;
}

/**
 *
 */
export class StudentStudyApi extends runtime.BaseAPI {
	/**
	 */
	async apiStudentStudyGetAvailableStudyProgramsSupervisorIdGetRaw(
		requestParameters: ApiStudentStudyGetAvailableStudyProgramsSupervisorIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<StudyProgramDTO>>> {
		if (
			requestParameters.supervisorId === null ||
			requestParameters.supervisorId === undefined
		) {
			throw new runtime.RequiredError(
				"supervisorId",
				"Required parameter requestParameters.supervisorId was null or undefined when calling apiStudentStudyGetAvailableStudyProgramsSupervisorIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentStudy/GetAvailableStudyPrograms/{supervisorId}`.replace(
					`{${"supervisorId"}}`,
					encodeURIComponent(String(requestParameters.supervisorId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(StudyProgramDTOFromJSON)
		);
	}

	/**
	 */
	async apiStudentStudyGetAvailableStudyProgramsSupervisorIdGet(
		requestParameters: ApiStudentStudyGetAvailableStudyProgramsSupervisorIdGetRequest,
		initOverrides?: RequestInit
	): Promise<Array<StudyProgramDTO>> {
		const response =
			await this.apiStudentStudyGetAvailableStudyProgramsSupervisorIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiStudentStudyGetCurrentStudentStudyProgramGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<StudentStudyProgramDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentStudy/GetCurrentStudentStudyProgram`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			StudentStudyProgramDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiStudentStudyGetCurrentStudentStudyProgramGet(
		initOverrides?: RequestInit
	): Promise<StudentStudyProgramDTO> {
		const response =
			await this.apiStudentStudyGetCurrentStudentStudyProgramGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiStudentStudyGetPossibleStudyProgramsForCurrnetStudentGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<StudyProgramDTO>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentStudy/GetPossibleStudyProgramsForCurrnetStudent`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(StudyProgramDTOFromJSON)
		);
	}

	/**
	 */
	async apiStudentStudyGetPossibleStudyProgramsForCurrnetStudentGet(
		initOverrides?: RequestInit
	): Promise<Array<StudyProgramDTO>> {
		const response =
			await this.apiStudentStudyGetPossibleStudyProgramsForCurrnetStudentGetRaw(
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiStudentStudyGetReportsStudyProgramsGetRaw(
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<StudyProgramDTO>>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentStudy/GetReportsStudyPrograms`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(StudyProgramDTOFromJSON)
		);
	}

	/**
	 */
	async apiStudentStudyGetReportsStudyProgramsGet(
		initOverrides?: RequestInit
	): Promise<Array<StudyProgramDTO>> {
		const response = await this.apiStudentStudyGetReportsStudyProgramsGetRaw(
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiStudentStudyGetStudentStudyProgramsGetRaw(
		requestParameters: ApiStudentStudyGetStudentStudyProgramsGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<StudentStudyProgramDTO>>> {
		const queryParameters: any = {};

		if (requestParameters.studentId !== undefined) {
			queryParameters["studentId"] = requestParameters.studentId;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentStudy/GetStudentStudyPrograms`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(StudentStudyProgramDTOFromJSON)
		);
	}

	/**
	 */
	async apiStudentStudyGetStudentStudyProgramsGet(
		requestParameters: ApiStudentStudyGetStudentStudyProgramsGetRequest = {},
		initOverrides?: RequestInit
	): Promise<Array<StudentStudyProgramDTO>> {
		const response = await this.apiStudentStudyGetStudentStudyProgramsGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiStudentStudyGetStudyProgramsGetRaw(
		requestParameters: ApiStudentStudyGetStudyProgramsGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<Array<StudyProgramDTO>>> {
		const queryParameters: any = {};

		if (requestParameters.facultyIds) {
			queryParameters["facultyIds"] = requestParameters.facultyIds;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentStudy/GetStudyPrograms`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			jsonValue.map(StudyProgramDTOFromJSON)
		);
	}

	/**
	 */
	async apiStudentStudyGetStudyProgramsGet(
		requestParameters: ApiStudentStudyGetStudyProgramsGetRequest = {},
		initOverrides?: RequestInit
	): Promise<Array<StudyProgramDTO>> {
		const response = await this.apiStudentStudyGetStudyProgramsGetRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async apiStudentStudyGetSupervisedStudyProgramsSupervisorIdGetRaw(
		requestParameters: ApiStudentStudyGetSupervisedStudyProgramsSupervisorIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<StudyProgramDTOPagedResponse>> {
		if (
			requestParameters.supervisorId === null ||
			requestParameters.supervisorId === undefined
		) {
			throw new runtime.RequiredError(
				"supervisorId",
				"Required parameter requestParameters.supervisorId was null or undefined when calling apiStudentStudyGetSupervisedStudyProgramsSupervisorIdGet."
			);
		}

		const queryParameters: any = {};

		if (requestParameters.namePattern !== undefined) {
			queryParameters["NamePattern"] = requestParameters.namePattern;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentStudy/GetSupervisedStudyPrograms/{supervisorId}`.replace(
					`{${"supervisorId"}}`,
					encodeURIComponent(String(requestParameters.supervisorId))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			StudyProgramDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiStudentStudyGetSupervisedStudyProgramsSupervisorIdGet(
		requestParameters: ApiStudentStudyGetSupervisedStudyProgramsSupervisorIdGetRequest,
		initOverrides?: RequestInit
	): Promise<StudyProgramDTOPagedResponse> {
		const response =
			await this.apiStudentStudyGetSupervisedStudyProgramsSupervisorIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiStudentStudySetCurrentStudentStudyProgramPutRaw(
		requestParameters: ApiStudentStudySetCurrentStudentStudyProgramPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		if (requestParameters.studyProgramId !== undefined) {
			queryParameters["studyProgramId"] = requestParameters.studyProgramId;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/StudentStudy/SetCurrentStudentStudyProgram`,
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiStudentStudySetCurrentStudentStudyProgramPut(
		requestParameters: ApiStudentStudySetCurrentStudentStudyProgramPutRequest = {},
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiStudentStudySetCurrentStudentStudyProgramPutRaw(
			requestParameters,
			initOverrides
		);
	}
}
