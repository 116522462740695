import { useEffect } from "react";
import { getProfessorById } from "../../../../Requests/academic-user-requests";
import { getUserId } from "../../../../utils/utilFunctions";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getProfessorAccountInfo } from "../../../../utils/reactQueriesConstants";
import { FormProvider, useForm } from "react-hook-form";
import { AcademicUserUpdateDTO } from "../../../../Api";
import UserAvatar from "../Avatar";
import { Button } from "antd";
import UserForm from "./Form";

export default function EditProfessor() {
	const { t } = useTranslation();

	const { data: professorState } = useQuery(
		[getProfessorAccountInfo],
		() => {
			return getProfessorById(getUserId());
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const form = useForm<AcademicUserUpdateDTO>({
		defaultValues: professorState,
	});

	useEffect(() => {
		form.reset(professorState);
	}, [form, professorState]);

	const handleSave = (data: AcademicUserUpdateDTO) => {
		console.log(data);
	};

	const avatar = form.watch("lowResImage");
	const onAvatarChange = (source: string) =>
		form.setValue("lowResImage", source);
	const onAvatarDelete = () => form.setValue("lowResImage", "");

	return (
		<FormProvider {...form}>
			<div className="max-w-[900px] mx-auto px-4 py-20 flex flex-col md:flex-row gap-6">
				<div className="shrink mx-auto">
					<UserAvatar
						value={avatar}
						onChange={onAvatarChange}
						onDelete={onAvatarDelete}
					/>
				</div>

				<div className="flex flex-col gap-6 items-end w-full basis-full">
					<UserForm />

					<Button type="primary" onClick={form.handleSubmit(handleSave)}>
						{t("account.save")}
					</Button>
				</div>
			</div>
		</FormProvider>
	);
}
