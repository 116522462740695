import { Button, Tabs, Progress, Typography, Pagination } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
	getAllProfessorInternshipV2,
	getAllProfessorInternshipFilters,
	getDepartmentProfessorInternshipFilters,
	getFacultyProfessorInternshipFilters,
	getStudyProgramProfessorInternshipFilters,
	getAllProfessorInternshipsForFacultyV2,
	getAllProfessorInternshipsForDepartmentV2,
	getAllProfessorInternshipsForStudyProgramV2,
} from "../../Requests/professor-internship-requests";
import {
	getAllCompanyInternshipFilters,
	getAllCompanyInternshipV2,
	getCoordinatedProfessorInternships,
	getCoordinatedProfessorInternshipsFilters,
} from "../../Requests/company-internship-requests";
import { Link, useLocation } from "react-router-dom";
import {
	getCurrentUniversityYear,
	getUserId,
	useIsDean,
	useIsDepartmentDirector,
	useIsDepartmentSecretary,
	useIsFacultyAdmin,
	useIsProfessor,
	useIsStudent,
	useIsStudyProgramCoordinator,
} from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	InternshipSortFieldEnum,
	ProfessorProposalReportDTO,
	Role,
	SortDirectionEnum,
} from "../../Api";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import InternshipsList from "./InternshipsList";
import {
	getAllProfessors,
	getProfessorById,
} from "../../Requests/academic-user-requests";
import {
	getAllApplicationTypesCount,
	getCompanyInternships,
	getCoordinatedInternships,
	getCoordinatedInternshipsFilters,
	getDepartmentInternships,
	getDepartmentInternshipsFiltersForProfessor,
	getFacultiesInternshipsFiltersForProfessor,
	getFacultyInternships,
	getInternshipsFilters,
	getProfessorInternships,
	getStudyProgramInternships,
	getStudyProgramInternshipsFiltersForProfessor,
} from "../../utils/reactQueriesConstants";
import { getStudyProgramsForFaculties } from "../../Requests/student-study-request";
import { downloadAll, downloadExcel } from "../../utils/downloadUtils";
import { removeDiacritics } from "../../utils/dataUtils";
import { ColumnType } from "../../utils/downloadReportUtils.types";
import useQueryFilters from "../../Hooks/useQueryFilters";
import Filters from "../Filters";
import { PlusCircleOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

// const FEATURE_ENABLED = !process.env.REACT_APP_DISABLE_FEATURES;

const getAllKeys = (filterItems: any[]) => {
	const keys: any[] = [];

	filterItems?.forEach((filter) => {
		keys.push(filter.key);

		filter.children.forEach((filterItem: any) => {
			keys.push(filterItem.key.split("/")[1]);
		});
	});

	return keys;
};

const FacultyInternships = (props: any) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(12);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortField, setSortField] = useState(
		InternshipSortFieldEnum.PublishDate
	);
	const [sortDirection, setSortDirection] = useState(
		SortDirectionEnum.Descending
	);
	const [skills, setSkills] = useState([]);
	const [categories, setCategories] = useState([]);
	const [locations, setLocations] = useState([]);
	const [types, setTypes] = useState([] as any);
	const [universityYears, setUniversityYears] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [checkedKeys, setCheckedKeys] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState<any>([]);
	const { search, pathname } = useLocation();
	const [activeTab, setActiveTab] = useState("1");
	const isProfessor = useIsProfessor();
	const isDepartmentSecretary = useIsDepartmentSecretary();
	const isDepartmentDirector = useIsDepartmentDirector();
	const isDean = useIsDean();
	const isStudyProgramCoordinator = useIsStudyProgramCoordinator();
	const isStudent = useIsStudent();
	const isFacultyAdmin = useIsFacultyAdmin();
	const [professorOptions, setProfessorOptions] = useState([] as any);
	const queryClient = useQueryClient();
	const [faculties, setFaculties] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [downloading, setDownloading] = useState(false);
	const [downloadError, setDownloadError] = useState(false);
	const [progress, setProgress] = useState(0);
	const query = useQueryFilters({
		initialValues: {
			UniversityYear: [
				getCurrentUniversityYear() + "-" + (getCurrentUniversityYear() + 1),
			],
			tab: activeTab,
		},
	});

	useEffect(() => {
		const url = new URLSearchParams(search);
		if (!url) return;

		for (const [key, value] of url) {
			if (key === "tab") {
				setActiveTab(value);
				break;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleListChange = async (current: number, pageSize: number) => {
		setCurrentPage(() => current);
		setPageSize(() => pageSize);
		await queryClient.invalidateQueries(getInternshipsFilters);
		await queryClient.invalidateQueries(getCompanyInternships);
		await queryClient.invalidateQueries(getProfessorInternships);
		await queryClient.invalidateQueries(getCoordinatedInternships);
		await queryClient.invalidateQueries(getAllApplicationTypesCount);
	};

	const openErrorNotification = (error: string, message: string) => {
		openNotification(error, message, NOTIFICATION_TYPES.ERROR);
	};

	const downloadReportFaculty = useCallback(async () => {
		setDownloading(true);

		return downloadExcel(
			t("reports.proposalsReport"),
			proposalColumns(t).map((e) => {
				return { header: removeDiacritics(e.title), key: e.key.toString() };
			}),
			await downloadAll(
				() => getAllProfessorInternshipsForFacultyV2(query.filters),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openErrorNotification(
						t("reports.errorTexts.downloadFailed"),
						t("reports.errorTexts.downloadFailedMessage")
					);
				}
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setDownloading]);

	const downloadReportDepartment = useCallback(async () => {
		setDownloading(true);

		return downloadExcel(
			t("reports.proposalsReport"),
			proposalColumns(t).map((e) => {
				return { header: removeDiacritics(e.title), key: e.key.toString() };
			}),
			await downloadAll(
				() => getAllProfessorInternshipsForDepartmentV2(query.filters),
				(value) => {
					setDownloadError(false);
					setProgress(value);
				},
				() => {
					setDownloadError(true);
					openErrorNotification(
						t("reports.errorTexts.downloadFailed"),
						t("reports.errorTexts.downloadFailedMessage")
					);
				}
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setDownloading]);

	const proposalColumns = (
		t: (text: string) => string
	): ColumnType<ProfessorProposalReportDTO>[] => [
		{
			title: t("reports.professorName"),
			key: "recruiterName",
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.id,
							userType: Role.Professor,
							origin: pathname,
						},
					}}
				>
					<b>{record.recruiterName}</b>
				</Link>
			),
		},
		{
			title: t("reports.proposalName"),
			key: "internshipName",
		},
		{
			title: t("reports.initialAvailablePositions"),
			key: "initialAvailablePositions",
		},
		{
			title: t("reports.availablePositions"),
			key: "availablePositions",
		},
		{
			title: t("reports.applicantsCount"),
			key: "applicantsCount",
		},
		{
			title: t("reports.viewCount"),
			key: "viewCount",
		},
	];

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchDatasError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: filters } = useQuery(
		[getInternshipsFilters],
		() => {
			return getAllProfessorInternshipFilters();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: coordinatedInternshipsFilters } = useQuery(
		[getCoordinatedInternshipsFilters, isProfessor],
		() => {
			if (isProfessor) {
				return getCoordinatedProfessorInternshipsFilters();
			}
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isProfessor) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: facultyInternshipsFilters } = useQuery(
		[getFacultiesInternshipsFiltersForProfessor, isDean, isFacultyAdmin],
		() => {
			if (isDean || isFacultyAdmin) {
				return getFacultyProfessorInternshipFilters();
			}
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isDean) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: departmentInternshipsFilters } = useQuery(
		[
			getDepartmentInternshipsFiltersForProfessor,
			isProfessor,
			isDepartmentDirector,
		],
		() => {
			if (isProfessor || isDepartmentDirector) {
				return getDepartmentProfessorInternshipFilters();
			}
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isProfessor || isDepartmentDirector) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: studyProgramInternshipsFilters } = useQuery(
		[getStudyProgramInternshipsFiltersForProfessor, isStudyProgramCoordinator],
		() => {
			if (isStudyProgramCoordinator) {
				return getStudyProgramProfessorInternshipFilters();
			}
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response: any) => {
				if (isStudyProgramCoordinator) {
					if (!expandedKeys || expandedKeys.length === 0)
						setExpandedKeys(getAllKeys(response));
				}
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: companyInternshipsFilters } = useQuery(
		[getInternshipsFilters, isProfessor],
		() => {
			return getAllCompanyInternshipFilters();
		},
		{
			onError: openFilterErrorNotification,
			onSuccess: (response) => {
				if (!expandedKeys || expandedKeys.length === 0)
					setExpandedKeys(getAllKeys(response));
			},
			refetchOnWindowFocus: false,
		}
	);

	const { data: professorInternships, isLoading: loadingProfessorInternships } =
		useQuery(
			[
				getProfessorInternships,
				isProfessor,
				isStudent,
				props.location.pathname,
				query.filters,
			],
			() => {
				if (
					isProfessor ||
					(isStudent && props.location.pathname === "/propuneri-facultati")
				) {
					return getAllProfessorInternshipV2(query.filters);
				}
			},
			{
				onError: openGetErrorNotification,
				refetchOnWindowFocus: false,
			}
		);

	const {
		data: coordinatedProfessorInternships,
		isLoading: loadingCoordinatedProfessorInternships,
	} = useQuery(
		[
			getCoordinatedInternships,
			isProfessor,
			isFacultyAdmin,
			skills,
			types,
			categories,
			locations,
			statuses,
			universityYears,
			searchTerm,
			currentPage,
			pageSize,
			sortField,
			sortDirection,
		],
		() => {
			if (isFacultyAdmin) {
				getAllProfessors(searchTerm, currentPage, pageSize)
					.then((response: any) => {
						setProfessorOptions(() => {
							return response.data;
						});
					})
					.catch((ex) => {
						openGetErrorNotification(ex);
					});
			}
			if (isProfessor) {
				return getCoordinatedProfessorInternships(
					skills,
					categories,
					locations,
					types,
					statuses,
					universityYears,
					searchTerm,
					currentPage,
					pageSize,
					sortField,
					sortDirection
				);
			}
		},
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: facultyInternships, isLoading: loadingFacultyInternships } =
		useQuery(
			[getFacultyInternships, isDean, isFacultyAdmin, query.filters],
			() => {
				if (isDean || isFacultyAdmin) {
					return getAllProfessorInternshipsForFacultyV2(query.filters);
				}
			},
			{
				onError: openGetErrorNotification,
				refetchOnWindowFocus: false,
			}
		);

	const {
		data: departmentInternships,
		isLoading: loadingDepartmentInternships,
	} = useQuery(
		[
			getDepartmentInternships,
			isProfessor,
			isDepartmentDirector,
			query.filters,
		],
		() => {
			if (isProfessor || isDepartmentDirector) {
				return getAllProfessorInternshipsForDepartmentV2(query.filters);
			}
		},
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const {
		data: studyProgramInternships,
		isLoading: loadingStudyProgramInternships,
	} = useQuery(
		[getStudyProgramInternships, isStudyProgramCoordinator, query.filters],
		() => {
			if (isStudyProgramCoordinator) {
				return getAllProfessorInternshipsForStudyProgramV2(query.filters);
			}
		},
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: companyInternships, isLoading: loadingCompanyInternships } =
		useQuery(
			[
				getCompanyInternships,
				currentPage,
				pageSize,
				searchTerm,
				isFacultyAdmin,
				isProfessor,
				faculties,
				departments,
				query.filters,
			],
			() => {
				if (isFacultyAdmin) {
					getAllProfessors(
						searchTerm,
						currentPage,
						pageSize,
						faculties,
						departments
					)
						.then((response: any) => {
							setProfessorOptions(() => {
								return response.data;
							});
						})
						.catch((ex) => {
							openGetErrorNotification(ex);
						});
					return getAllCompanyInternshipV2(query.filters);
				} else if (isProfessor) {
					return getAllCompanyInternshipV2(query.filters);
				}
			},
			{
				onError: openGetErrorNotification,
				refetchOnWindowFocus: false,
			}
		);

	const { data: dean } = useQuery(
		[isDean],
		() => {
			if (isDean) {
				return getProfessorById(getUserId());
			}
		},
		{
			onError: openGetErrorNotification,
			refetchOnWindowFocus: false,
		}
	);

	const { data: studyProgramsList } = useQuery(
		[getStudyProgramsForFaculties, isDean, dean],
		() => {
			if (isDean) {
				return getStudyProgramsForFaculties([dean?.faculty?.id!]);
			}
		},
		{
			onError: (err) => {
				openNotification(
					t("internships.addInternshipForm.studyProgramsError"),
					t("internships.addInternshipForm.notFoundContent"),
					NOTIFICATION_TYPES.ERROR
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const changeTab = (activeKey: any) => {
		setActiveTab(activeKey);

		query.update(
			{
				tab: [activeKey],
				search: "",
				Type: [],
				Location: [],
				ApplicabilityType: [],
				ValidationState: [],
				Status: [],
				State: [],
				UniversityYear: [
					getCurrentUniversityYear() + "-" + (getCurrentUniversityYear() + 1),
				],
			},
			true
		);
	};

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update({ tab: [activeTab], ...values }, true);
		},
		[activeTab, query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 pb-10 flex flex-col relative">
			{!isStudent && (
				<div className="fixed bottom-10 right-10 z-50">
					<Button
						type="primary"
						className="p-6 flex flex-col justify-center items-center rounded-full relative"
					>
						<PlusCircleOutlined />

						<Link
							to={{
								pathname: "/editare-propunere/",
								state: {
									filters: checkedKeys,
									searchTerm: searchTerm,
									sortField: sortField,
									sortDirection: sortDirection,
									currentPage: currentPage,
									pageSize: pageSize,
									activeTab: activeTab,
								},
							}}
						>
							{t("internships.addInternship")}
						</Link>
					</Button>
				</div>
			)}

			<Filters
				filters={
					activeTab === "1"
						? filters!
						: activeTab === "2"
						? coordinatedInternshipsFilters!
						: activeTab === "3"
						? facultyInternshipsFilters
						: activeTab === "4"
						? departmentInternshipsFilters
						: activeTab === "5"
						? studyProgramInternshipsFilters
						: companyInternshipsFilters
				}
				searchFields={[{ name: "searchTerm", label: t("internships.search") }]}
				onUpdate={onUpdate}
				hasSort={true}
			/>

			<div className="relative flex flex-col md:flex-row justify-between items-center">
				<Typography.Title level={3} className="my-0">
					{
						(activeTab === "1"
							? professorInternships!
							: activeTab === "2"
							? coordinatedProfessorInternships
							: activeTab === "3"
							? facultyInternships
							: activeTab === "4"
							? departmentInternships
							: activeTab === "5"
							? studyProgramInternships
							: companyInternships
						)?.totalCount
					}{" "}
					rezultate
				</Typography.Title>

				<Pagination
					defaultCurrent={
						(activeTab === "1"
							? professorInternships!
							: activeTab === "2"
							? coordinatedProfessorInternships
							: activeTab === "3"
							? facultyInternships
							: activeTab === "4"
							? departmentInternships
							: activeTab === "5"
							? studyProgramInternships
							: companyInternships
						)?.page
					}
					total={
						(activeTab === "1"
							? professorInternships!
							: activeTab === "2"
							? coordinatedProfessorInternships
							: activeTab === "3"
							? facultyInternships
							: activeTab === "4"
							? departmentInternships
							: activeTab === "5"
							? studyProgramInternships
							: companyInternships
						)?.totalCount
					}
					onChange={onPageUpdate}
					pageSizeOptions={[12, 24, 48, 96]}
					defaultPageSize={12}
				/>
			</div>

			{isProfessor && props.location.pathname === "/propuneri" ? (
				<div className="relative">
					<Tabs
						activeKey={activeTab}
						animated={{ inkBar: false }}
						onChange={changeTab}
					>
						<TabPane tab={t("header.professorInternships")} key="1">
							<InternshipsList
								data={professorInternships}
								checkedKeys={checkedKeys}
								loading={loadingProfessorInternships}
								location={props.location}
								activeTab={"1"}
								currentPage={currentPage}
								pageSize={pageSize}
								searchTerm={searchTerm}
								sortDirection={sortDirection}
								sortField={sortField}
								handleListChange={handleListChange}
							/>
						</TabPane>
						{/* {FEATURE_ENABLED && ( */}
						<TabPane tab={t("internships.coordinatedByMe")} key="2">
							<InternshipsList
								data={coordinatedProfessorInternships}
								professorOptions={professorOptions}
								checkedKeys={checkedKeys}
								loading={loadingCoordinatedProfessorInternships}
								location={props.location}
								activeTab={"2"}
								currentPage={currentPage}
								pageSize={pageSize}
								searchTerm={searchTerm}
								sortDirection={sortDirection}
								sortField={sortField}
								handleListChange={handleListChange}
								studyProgramsList={studyProgramsList}
							/>
						</TabPane>
						{/* )} */}

						{(isDean || isFacultyAdmin) && (
							<TabPane tab={t("internships.facultyProposals")} key="3">
								<div className="flex flex-col items-end py-4 gap-2">
									<Button type="primary" onClick={downloadReportFaculty}>
										{t("reports.download")}
									</Button>
									{downloading && (
										<Progress
											percent={progress}
											status={downloadError ? "exception" : undefined}
										/>
									)}
								</div>
								<InternshipsList
									data={facultyInternships}
									checkedKeys={checkedKeys}
									loading={loadingFacultyInternships}
									location={props.location}
									activeTab={"3"}
									currentPage={currentPage}
									pageSize={pageSize}
									searchTerm={searchTerm}
									sortDirection={sortDirection}
									sortField={sortField}
									handleListChange={handleListChange}
									studyProgramsList={studyProgramsList}
								/>
							</TabPane>
						)}
						{(isProfessor || isDepartmentDirector || isDepartmentSecretary) &&
							!isDean &&
							!isFacultyAdmin && (
								<TabPane tab={t("internships.departmentProposals")} key="4">
									<div className="flex flex-col items-end py-4 gap-2">
										<Button type="primary" onClick={downloadReportDepartment}>
											{t("reports.download")}
										</Button>
										{downloading && (
											<Progress
												percent={progress}
												status={downloadError ? "exception" : undefined}
											/>
										)}
									</div>
									<InternshipsList
										data={departmentInternships}
										checkedKeys={checkedKeys}
										loading={loadingDepartmentInternships}
										location={props.location}
										activeTab={"4"}
										currentPage={currentPage}
										pageSize={pageSize}
										searchTerm={searchTerm}
										sortDirection={sortDirection}
										sortField={sortField}
										handleListChange={handleListChange}
										studyProgramsList={studyProgramsList}
									/>
								</TabPane>
							)}
						{isStudyProgramCoordinator && (
							<TabPane tab={t("internships.studyProgramProposals")} key="5">
								<InternshipsList
									data={studyProgramInternships}
									checkedKeys={checkedKeys}
									loading={loadingStudyProgramInternships}
									location={props.location}
									activeTab={"5"}
									currentPage={currentPage}
									pageSize={pageSize}
									searchTerm={searchTerm}
									sortDirection={sortDirection}
									sortField={sortField}
									handleListChange={handleListChange}
									studyProgramsList={studyProgramsList}
								/>
							</TabPane>
						)}
						{isProfessor && (
							<TabPane tab={t("internships.companyProposals")} key="6">
								<InternshipsList
									data={companyInternships}
									professorOptions={professorOptions}
									checkedKeys={checkedKeys}
									loading={loadingCompanyInternships}
									location={props.location}
									activeTab={"6"}
									currentPage={currentPage}
									pageSize={pageSize}
									searchTerm={searchTerm}
									sortDirection={sortDirection}
									sortField={sortField}
									handleListChange={handleListChange}
									studyProgramsList={studyProgramsList}
								/>
							</TabPane>
						)}
					</Tabs>
				</div>
			) : (
				<div className="relative">
					<InternshipsList
						data={professorInternships}
						checkedKeys={checkedKeys}
						loading={loadingProfessorInternships}
						location={props.location}
						activeTab={"2"}
						currentPage={currentPage}
						pageSize={pageSize}
						searchTerm={searchTerm}
						sortDirection={sortDirection}
						sortField={sortField}
						handleListChange={handleListChange}
					/>
				</div>
			)}
		</div>
	);
};

export default FacultyInternships;
