import DashboardLayout from "../../../layouts/Dashboard";
import { useIsProfessor } from "../../../utils/utilFunctions";
import EditProfessor from "./Professor/EditProfessor";
import EditStudent from "./Student/EditStudent";

export default function ProfileEditPage() {
	const isProfessor = useIsProfessor();

	return (
		<DashboardLayout>
			{isProfessor ? <EditProfessor /> : <EditStudent />}
		</DashboardLayout>
	);
}
