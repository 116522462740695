import { Avatar, Button, Col, Spin, Typography } from "antd";
import SkillEndorsements from "./SkillEndorsements";
import Appreciations from "./Appreciations";
import { useDispatch, useSelector } from "react-redux";
import {
	selectLoading,
	selectStudent,
	selectStudentId,
	studentGetSucceeded,
	studentLoad,
} from "./StudentSlice";
import { useEffect } from "react";
import { getStudentById } from "../../Requests/academic-user-requests";
import { StatusCodes } from "http-status-codes";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	getUserId,
	useIsCompany,
	useIsProfessor,
} from "../../utils/utilFunctions";
import { useQuery } from "react-query";
import { getCurrentStudentStudyProgram } from "../../Requests/student-study-requests";
import { getStudentCurrentStudyProgram } from "../../utils/reactQueriesConstants";
import { ArrowDownOutlined, UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Student = () => {
	const { t, i18n } = useTranslation();
	const isProfessor = useIsProfessor();
	const isCompany = useIsCompany();
	const student = useSelector(selectStudent);
	const studentId = useSelector(selectStudentId);
	const loading = useSelector(selectLoading);
	const dispatch = useDispatch();
	const history = useHistory();

	const { data: currentStudyProgram } = useQuery(
		[getStudentCurrentStudyProgram],
		() => getCurrentStudentStudyProgram(),
		{
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		console.log(student, studentId);
		if (!loading && !student && studentId) {
			dispatch(studentLoad());
			getStudentById(studentId)
				.then((data) => {
					dispatch(studentGetSucceeded(data));
				})
				.catch((ex) => {
					if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
						openNotification(
							t("account.error"),
							t("account.notFoundStudent"),
							NOTIFICATION_TYPES.ERROR
						);
					} else {
						openNotification(
							t("account.error"),
							t("account.unknownError"),
							NOTIFICATION_TYPES.ERROR
						);
					}
				});
		}
	}, [loading, student, studentId, dispatch, t]);

	return (
		<Spin size="large" spinning={loading} tip={t("account.loading")}>
			<div className="flex items-center gap-2 ml-2 mt-2">
				<ArrowDownOutlined onClick={() => history.goBack()} className="rotate-90 text-4xl text-primary" />
			</div>
			<div className="flex flex-col pt-20 pb-10 items-center">
				<div className="relative w-full pb-10">
					<div className="absolute w-full top-32 md:top-14 bottom-0 bg-primary"></div>

					<div className="relative grid md:grid-rows-[auto_1fr] grid-cols-1 lg:grid-cols-[218px_auto] gap-12 max-w-[727px] w-full mx-auto">
						<div className="row-span-2 flex flex-col gap-6 items-center">
							<Avatar
								size={218}
								src={student?.fullResImage ?? student?.lowResImage}
								icon={<UserOutlined />}
							/>
						</div>

						<div className="font-bold text-primary text-2xl order-first lg:order-none text-center lg:text-left">
							{student?.name}
						</div>

						<div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-fit text-white order-last md:order-none mx-auto text-center md:text-left">
							<div className="font-bold">{t("students.faculty")}</div>
							<div>
								{i18n.language === "ro"
									? student?.facultyNameRo
									: student?.facultyNameEn}
							</div>

							{student?.email && (
								<>
									{" "}
									<div className="font-bold self-center">
										{t("students.email")}
									</div>
									<div className="flex items-center">
										{student?.email}
										<Button
											type="text"
											style={{
												backgroundColor: "transparent",
											}}
											onClick={() => {
												navigator.clipboard
													.writeText(student?.email || "")
													.then(() => {
														openNotification(
															t("profile.emailCopied"),
															t("profile.emailCopiedMessage"),
															NOTIFICATION_TYPES.SUCCESS
														);
													});
											}}
										>
											<FontAwesomeIcon
												icon={solid("copy")}
												className="text-2xl text-white"
												title={t("account.copyToClipboard")}
											/>
										</Button>
									</div>
								</>
							)}

							{student?.personalEmail && (
								<>
									{" "}
									<div className="font-bold self-center">
										{t("students.personalEmail")}
									</div>
									<div className="flex items-center">
										{student?.personalEmail}
										<Button
											type="text"
											style={{
												backgroundColor: "transparent",
											}}
											onClick={() => {
												navigator.clipboard
													.writeText(student?.personalEmail || "")
													.then(() => {
														openNotification(
															t("profile.emailCopied"),
															t("profile.emailCopiedMessage"),
															NOTIFICATION_TYPES.SUCCESS
														);
													});
											}}
										>
											<FontAwesomeIcon
												icon={solid("copy")}
												className="text-2xl text-white"
												title={t("account.copyToClipboard")}
											/>
										</Button>
									</div>
								</>
							)}

							<div className="font-bold">{t("students.phone")}</div>
							<div>{student?.phone}</div>

							<div className="font-bold">{t("students.studyProgram")}</div>
							<div> {currentStudyProgram?.studyProgram?.name}</div>

							<div className="font-bold">{t("students.group")}</div>
							<div>{student?.group}</div>

							<div className="font-bold">{t("account.year")}</div>
							<div>{t("account." + student?.year)}</div>

							<div className="md:col-span-2">
								<div className="flex items-center gap-2">
									{student?.cvUrl && (
										<Button
											type="text"
											href={student?.cvUrl}
											target="_blank"
											rel="noreferrer"
											style={{
												backgroundColor: "transparent",
												float: "left",
												padding: "0",
											}}
											title={"CV"}
										>
											<FontAwesomeIcon
												icon={solid("file-lines")}
												className="text-2xl text-white"
											/>
										</Button>
									)}

									{student?.linkedInUrl && (
										<Button
											type="text"
											href={student?.linkedInUrl!}
											target="_blank"
											rel="noreferrer"
											style={{
												backgroundColor: "transparent",
												float: "left",
												padding: "0",
											}}
											title={t("students.linkedinUrl")}
										>
											<FontAwesomeIcon
												icon={brands("linkedin")}
												className="text-2xl text-white"
											/>
										</Button>
									)}

									{student?.gitUrl && (
										<Button
											type="text"
											href={student?.gitUrl}
											target="_blank"
											rel="noreferrer"
											style={{
												backgroundColor: "transparent",
												float: "left",
												padding: "0",
											}}
											title={t("students.githubUrl")}
										>
											<FontAwesomeIcon
												icon={solid("code-branch")}
												className="text-2xl text-white"
											/>
										</Button>
									)}

									{student?.email && !isCompany && student.id !== getUserId() && (
										<Button
											type="text"
											href={
												"https://teams.microsoft.com/l/chat/0/0?users=" +
												student?.email
											}
											target="_blank"
											rel="noreferrer"
											style={{
												backgroundColor: "transparent",
												float: "left",
												padding: "0",
											}}
											title={t("students.teams")}
										>
											<FontAwesomeIcon
												icon={solid("comment")}
												className="text-2xl text-white"
											/>
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				{student?.userSkills && student?.userSkills.length > 0 && (
					<Col
						span={window.innerWidth > 1215 ? 10 : 24}
						style={{ alignContent: "center", padding: "1rem 0rem" }}
					>
						<Typography
							style={{
								fontSize: 25,
								float: "left",
								marginRight: "5%",
								paddingLeft: "15px",
							}}
						>
							<Typography.Text strong={true}>
								{t("profile.skills")}
							</Typography.Text>
						</Typography>
						<SkillEndorsements
							skills={student?.userSkills}
							enableButtons={isProfessor || false}
						/>
					</Col>
				)}

				<div
					className="max-w-[727px] w-full mx-auto px-4"
					dangerouslySetInnerHTML={{ __html: student?.description || "" }}
				></div>

				<Appreciations />
			</div>
		</Spin>
	);
};

export default Student;
