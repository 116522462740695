import { useState } from "react";
import { Button, Form, Input, Modal, Row, Spin } from "antd";
import CustomForm from "../../CustomComponents/CustomForm";
import { theme } from "../../theme";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { useHistory } from "react-router-dom";
import { Tabs } from "antd";
import { Redirect } from "react-router-dom";
import { getBaseUrl, isUserLogged } from "../../utils/utilFunctions";
import {
	loginCompany,
	resendValidationEmail,
} from "../../Requests/company-requests";
import { CompanyLoginResponseDTORequestResponse, ErrorCodes } from "../../Api";
import { useTranslation } from "react-i18next";
import CustomModalFooter from "../../Containers/CustomModalFooter";
import { StatusCodes } from "http-status-codes";
import {
	changeCompanyUserPassword,
	sendResetPasswordLink,
} from "../../Requests/company-users-requests";
import { getErrorFromResponse } from "../../utils/responseUtils";
import { Routes } from "../../utils/routes";
import SimpleLayout from "../../layouts/Simple";
import { InfoCircleOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

//TO DO: if a company has profile not completed, redirect to profile
const Authenticate = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const [state, setState] = useState({ email: "", password: "" });
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [token, setToken] = useState("");
	const [refreshToken, setRefreshToken] = useState("");
	const [isEmailNotValidated, setIsEmailNotValidated] = useState(false);
	const [changePasswordForm] = Form.useForm<{
		actualPassword: string;
		newPassword: string;
		confirmPassword: string;
	}>();
	const [resetPasswordForm] = Form.useForm<{
		email: string;
	}>();
	const [authenticationForm] = Form.useForm<{
		email: string;
		password: string;
	}>();

	const handleChange = (event: any, field: any) => {
		event.persist();
		if (field === "email") {
			setIsEmailNotValidated(false);
		}

		setState((prevState) => {
			return {
				...prevState,
				[field]: event.target.value,
			};
		});
	};

	const verifyCredentials = (event: any) => {
		if (event.key === "Enter") {
			handleAuthenticate();
		}
	};

	const handleAuthenticate = () => {
		authenticationForm.validateFields().then(() => {
			const reqBody = {
				email: state.email,
				password: state.password,
			};

			loginCompany(reqBody)
				.then((response: CompanyLoginResponseDTORequestResponse) => {
					const actualResponse = response?.response;

					if (actualResponse?.requirePasswordChange) {
						setToken(actualResponse?.token);
						setRefreshToken(actualResponse?.refreshToken);
						setShowChangePasswordModal(true);
					} else {
						localStorage.setItem("token", actualResponse?.token!);
						localStorage.setItem("refreshToken", actualResponse?.refreshToken!);
						// TODO: Redirect to dashboard page when it is created
						history.push(Routes.HOME);
					}
				})
				.catch(async (error) => {
					const errorMessage = await getErrorFromResponse(error);

					let notificationMessage;

					switch (errorMessage?.code) {
						case ErrorCodes.UserNotFound:
							notificationMessage = t("loginText.userNotFound");
							break;
						case ErrorCodes.InvalidCredentials:
							notificationMessage = t("loginText.invalidCredentials");
							break;
						case ErrorCodes.CompanyNotValidated:
							notificationMessage = t("loginText.companyNotValidated");
							break;
						case ErrorCodes.EmailNotValidated:
							notificationMessage = t("loginText.emailNotValidated");
							setIsEmailNotValidated(true);
							break;
						default:
							notificationMessage = t("account.unknownError");
							break;
					}
					openNotification(
						t("loginText.login"),
						notificationMessage,
						NOTIFICATION_TYPES.ERROR
					);
				});
		});
	};

	const handleCloseChangePasswordModal = () => {
		setShowChangePasswordModal(false);
		localStorage.setItem("token", token);
		localStorage.setItem("refreshToken", refreshToken);
		history.push("/");
	};

	const savePasswordChangeError = (ex: any) => {
		if (ex.status && ex.status === StatusCodes.CONFLICT) {
			openNotification(
				t("account.conflict"),
				t("account.passwordsConflict"),
				NOTIFICATION_TYPES.ERROR
			);
		} else if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
			openNotification(
				t("account.error"),
				t("account.wrongPassword"),
				NOTIFICATION_TYPES.ERROR
			);
		} else if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
			openNotification(
				t("account.error"),
				t("account.userNotFound"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("account.error"),
				t("account.unknownError"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const validateChangePasswordFields = () => {
		const formData = changePasswordForm.getFieldsValue();

		if (formData.newPassword !== formData.confirmPassword) {
			openNotification(
				t("signupText.register"),
				t("signupText.messages.mismatchedPasswords"),
				NOTIFICATION_TYPES.ERROR
			);
			return false;
		}
		return true;
	};

	const handlePasswordChange = () => {
		if (!validateChangePasswordFields()) {
			return;
		}

		const formData = changePasswordForm.getFieldsValue();

		setSpinning(true);

		const reqBody = {
			email: state.email,
			actualPassword: formData.actualPassword,
			newPassword: formData.newPassword,
		};

		changeCompanyUserPassword(reqBody)
			.then(() => {
				setShowChangePasswordModal(false);

				openNotification(
					t("account.passwordChangeTitle"),
					t("account.passwordChangeSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);

				localStorage.setItem("token", token);
				localStorage.setItem("refreshToken", refreshToken);
				history.push("/");
			})
			.catch(savePasswordChangeError)
			.finally(() => {
				setSpinning(false);
			});
	};

	const handlePasswordResetRequest = () => {
		const formData = resetPasswordForm.getFieldsValue();
		setSpinning(true);

		const reqBody = {
			email: formData.email,
		};

		sendResetPasswordLink(reqBody)
			.then(() => {
				resetPasswordForm.resetFields();
				setShowResetPasswordModal(false);
				openNotification(
					t("account.passwordResetTitle"),
					t("account.passwordResetSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("account.passwordResetTitle"),
					t("account.userNotFound"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
			});
	};

	const handleResendValidationEmailRequest = () => {
		const formData = authenticationForm.getFieldsValue();
		setSpinning(true);

		resendValidationEmail(formData.email)
			.then(() => {
				setIsEmailNotValidated(false);
				openNotification(
					t("account.resendValidationEmail"),
					t("account.resendValidationEmailSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(() => {
				openNotification(
					t("account.resendValidationEmail"),
					t("account.userNotFound"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
			});
	};

	if (isUserLogged()) {
		// TODO: Redirect to dashboard page when it is created
		return <Redirect to={Routes.HOME} />;
	} else {
		return (
			<SimpleLayout>
				<Tabs defaultActiveKey="1" centered>
					<TabPane tab={t("loginText.academicUser")} key="1">
						<form className="grow">
							<div className="flex flex-col gap-6 items-center pb-10">
								<span>{t("loginText.loginWithCredentials")}</span>

								<a
									href={`${
										process.env.REACT_APP_AUTH_API_URL
									}/auth?clientAction=login&clientName=${getBaseUrl()}`}
									className="flex items-center gap-2 bg-[#775CDC] hover:bg-[#8a6fea] transition-all text-white rounded-md px-6 py-2 w-full justify-center max-w-xs"
								>
									<img
										src="/images/logo-upb.png"
										alt="logo"
										className="w-8 h-8 object-contain"
									/>
									<span className="font-bold">{t("loginText.login")}</span>
								</a>

								<a
									href={`https://my.upb.ro/tutorials/my.upb.ro_Ghid_activare_cont.pdf`}
									target="_blank"
									rel="noreferrer"
									className="flex items-center gap-2 bg-gray-500 hover:bg-gray-400 transition-all text-white rounded-md px-6 py-2 w-full justify-center max-w-xs"
								>
									<InfoCircleOutlined />

									<span className="font-bold">{t("loginText.guide")}</span>
								</a>
							</div>
						</form>
					</TabPane>
					<TabPane tab={t("loginText.company")} key="2">
						<Form.Provider>
							<Form
								layout={theme.layout}
								form={authenticationForm}
								className="mx-auto max-w-md px-4 pb-10"
							>
								<Form.Item
									name="email"
									label={t("loginText.email") + ":"}
									rules={[
										{
											type: "email",
											message: t("usersText.invalidField", {
												field: t("account.email"),
											}),
										},
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("loginText.email"),
											}),
										},
									]}
								>
									<Input
										onChange={(event: any) => handleChange(event, "email")}
									/>
								</Form.Item>

								<Form.Item
									name="password"
									label={t("loginText.password") + ":"}
									rules={[
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("loginText.password"),
											}),
										},
									]}
								>
									<Input.Password
										onChange={(event: any) => handleChange(event, "password")}
										onKeyPress={verifyCredentials}
									/>
								</Form.Item>

								<div className="flex justify-center gap-4">
									<Button onClick={handleAuthenticate}>
										{t("loginText.login")}
									</Button>
									<Button onClick={() => setShowResetPasswordModal(true)}>
										{t("loginText.passwordForgotten")}
									</Button>
									{isEmailNotValidated && (
										<Button
											disabled={spinning}
											onClick={handleResendValidationEmailRequest}
										>
											<Spin spinning={spinning}>
												{t("loginText.resendValidationEmail")}
											</Spin>
										</Button>
									)}
								</div>
							</Form>
						</Form.Provider>
					</TabPane>
				</Tabs>

				<Modal
					open={showChangePasswordModal}
					onOk={() => changePasswordForm.submit()}
					onCancel={handleCloseChangePasswordModal}
					cancelText={t("account.notNow")}
					title={t("account.passwordChange")}
					width={"30%"}
					footer={
						<CustomModalFooter
							handleClose={handleCloseChangePasswordModal}
							spinning={spinning}
							handleSave={() => changePasswordForm.submit()}
							confirmButtonName={t("account.passwordChange")}
							cancelButtonName={t("account.notNow")}
						/>
					}
				>
					<Row style={{ marginBottom: "20px" }}>
						{t("account.passwordChangeRequired")}
					</Row>
					<Spin spinning={spinning}>
						<Form.Provider onFormFinish={handlePasswordChange}>
							<CustomForm layout={theme.layout} form={changePasswordForm}>
								<Form.Item
									name="actualPassword"
									label={`${t("account.actualPassword")}:`}
									rules={[
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("account.actualPassword"),
											}),
											whitespace: true,
										},
									]}
								>
									<Input type={"password"} />
								</Form.Item>

								<Form.Item
									name="newPassword"
									label={`${t("account.newPassword")}:`}
									rules={[
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("account.newPassword"),
											}),
											whitespace: true,
										},
										{
											min: 4,
											message: t("signupText.messages.lengthPasswordError"),
										},
									]}
								>
									<Input type={"password"} />
								</Form.Item>
								<Form.Item
									name="newPassword"
									label={`${t("account.newPassword")}:`}
									rules={[
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("account.newPassword"),
											}),
											whitespace: true,
										},
										{
											min: 4,
											message: t("signupText.messages.lengthPasswordError"),
										},
									]}
								>
									<Input type={"password"} />
								</Form.Item>

								<Form.Item
									name="confirmPassword"
									label={`${t("account.confirmPassword")}:`}
									rules={[
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("account.confirmPassword"),
											}),
											whitespace: true,
										},
										{
											min: 4,
											message: t("signupText.messages.lengthPasswordError"),
										},
									]}
								>
									<Input type={"password"} />
								</Form.Item>
							</CustomForm>
						</Form.Provider>
					</Spin>
				</Modal>
				<Modal
					open={showResetPasswordModal}
					onOk={() => resetPasswordForm.submit()}
					onCancel={() => setShowResetPasswordModal(false)}
					cancelText={t("account.notNow")}
					title={t("account.resetPassword")}
					width={"30%"}
					footer={
						<CustomModalFooter
							handleClose={() => setShowResetPasswordModal(false)}
							spinning={spinning}
							handleSave={() => resetPasswordForm.submit()}
							confirmButtonName={t("account.sendResetPasswordLink")}
							cancelButtonName={t("account.cancel")}
						/>
					}
				>
					<Row style={{ marginBottom: "20px" }}>
						{t("account.resetPasswordMessage")}
					</Row>
					<Spin spinning={spinning}>
						<Form.Provider onFormFinish={handlePasswordResetRequest}>
							<CustomForm layout={theme.layout} form={resetPasswordForm}>
								<Form.Item
									name="email"
									label={t("loginText.email") + ":"}
									rules={[
										{
											type: "email",
											message: t("usersText.invalidField", {
												field: t("account.email"),
											}),
										},
										{
											required: true,
											message: t("usersText.requiredField", {
												field: t("loginText.email"),
											}),
										},
									]}
								>
									<Input type={"email"} />
								</Form.Item>
							</CustomForm>
						</Form.Provider>
					</Spin>
				</Modal>
			</SimpleLayout>
		);
	}
};

export default Authenticate;
