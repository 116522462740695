import { InternshipListItemProps } from "./InternshipListItem.types";
import {
	Avatar,
	Button,
	Checkbox,
	Dropdown,
	MenuProps,
	Modal,
	Select,
	Switch,
} from "antd";
import styles from "../../../Internships.module.scss";
import {
	CompanyInternshipState,
	InternshipDTO,
	InternshipEngagementType,
	InternshipType,
	Role,
} from "../../../../../Api";
import InternshipActions from "../../../InternshipActions";
import { getUserId, isUserLogged } from "../../../../../utils/utilFunctions";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ConfirmationModal from "../../../../../Containers/ConfirmationModal";
import RejectInternship from "../../../RejectInternship";
import { CheckOutlined, CloseOutlined, MoreOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { InternshipApplicantsTable } from "../../tables/InternshipApplicantsTable/InternshipApplicantsTable";
import { useMemo, useState } from "react";
import { InternshipMyStudentsTable } from "../../tables/InternshipMyStudentsTable/InternshipMyStudentsTable";

const { Option } = Select;

export const InternshipListItem = (props: InternshipListItemProps) => {
	const { t } = useTranslation();
	const [showApplicantsTable, setShowApplicantsTable] = useState(false);
	const [showMyStudentsTable, setShowMyStudentsTable] = useState(false);
	const history = useHistory();

	const onChange = (event: any, id: any) => {
		props.actions.updateInternshipCoordinator(event, id);
	};

	const onChangeStudent = (event: any, id: any) => {
		props.actions.enrollStudent(event, id);
	};

	const isEditAllowed = (internship: InternshipDTO) => {
		return (
			internship.state === CompanyInternshipState.Draft ||
			internship.state === CompanyInternshipState.Approved
		);
	};

	const isCompanyTrusted = (internship: InternshipDTO) => {
		const faculty = internship.faculties[0];
		const companyProtocols = internship.companyDocuments;

		return (
			companyProtocols?.filter(
				(e) => e.facultyId === faculty.id && e.skipOfferValidation
			).length === 1
		);
	};

	const onChangeStudyPrograms = (event: any, id: any, type: string) => {
		props.actions.updateStudyPrograms(event, id, type);
	};

	enum DropdownKeys {
		EDIT = "1",
		MARK_COMPLETE = "2",
		DELETE = "3",
		DUPLICATE = "4",
		APPLICANTS = "5",
	}

	const items = useMemo(() => {
		const dropdownList: MenuProps["items"] = [];

		if (
			(props.isProfessor &&
				props.location.pathname !== "/propuneri-companii" &&
				props.activeTab === "1") ||
			(props.isDepartmentSecretary && props.activeTab === "4") ||
			(props.isCompany && isEditAllowed(props.item))
		)
			dropdownList.push({
				key: DropdownKeys.EDIT,
				icon: <FontAwesomeIcon icon={solid("edit")} />,
				label: t("internships.edit"),
			});

		if (props.isCompany && props.item.state === CompanyInternshipState.Draft)
			dropdownList.push({
				key: DropdownKeys.MARK_COMPLETE,
				icon: <FontAwesomeIcon icon={solid("circle-check")} />,
				label: isCompanyTrusted(props.item)
					? t("internships.finalize")
					: t("internships.markComplete"),
			});

		if (
			(props.isProfessor &&
				props.location.pathname !== "/propuneri-companii" &&
				props.activeTab === "1") ||
			(props.isCompany &&
				(props.item.state !== CompanyInternshipState.Approved ||
					(props.item.engagementType !==
						InternshipEngagementType.BachelorsInternship &&
						props.item.engagementType !==
							InternshipEngagementType.MastersInternship)))
		)
			dropdownList.push({
				key: DropdownKeys.DELETE,
				icon: (
					<FontAwesomeIcon
						icon={solid("trash")}
						style={{ paddingRight: "5%" }}
					/>
				),
				disabled: props.item.enrolledStudentsCount > 0,
				label:
					props.item.enrolledStudentsCount > 0
						? t("internships.deletionNotAllowed")
						: t("internships.deleteInternship"),
			});

		if ((props.isProfessor && props.activeTab === "1") || props.isCompany)
			dropdownList.push({
				key: DropdownKeys.DUPLICATE,
				icon: (
					<FontAwesomeIcon
						icon={solid("clone")}
						style={{ paddingRight: "5%" }}
					/>
				),
				label: t("internships.duplicateInternship"),
			});

		if (
			(props.isProfessor &&
				props.location.pathname !== "/propuneri-companii" &&
				props.activeTab === "1") ||
			(props.isCompany &&
				props.item.state === CompanyInternshipState.Approved) ||
			(props.isDepartmentSecretary && props.activeTab === "4")
		)
			dropdownList.push({
				key: DropdownKeys.APPLICANTS,
				icon: <FontAwesomeIcon icon={solid("users")} />,
				label: t("internships.applicants"),
			});
		return dropdownList;
	}, [props, t, DropdownKeys]);

	const handleDropdownClick: MenuProps["onClick"] = (e) => {
		const key = e.key;
		if (key === DropdownKeys.EDIT) {
			history.push({
				pathname: "/editare-propunere/" + props.item.id,
				state: {
					activeTab: props.activeTab,
					currentPage: props.currentPage,
					pageSize: props.pageSize,
					filters: props.checkedKeys,
					searchTerm: props.searchTerm,
					sortDirection: props.sortDirection,
					sortField: props.sortField,
				},
			});
		}

		if (key === DropdownKeys.MARK_COMPLETE) {
			props.actions.setSelectedInternshipId(props.item.id);
			props.actions.setMarkCompleteModalVisibility(true);
			props.actions.setIsCompanyTrusted(isCompanyTrusted(props.item));
		}

		if (key === DropdownKeys.DELETE) {
			props.actions.setSelectedInternshipId(props.item.id);
			props.actions.setDeleteModalVisibility(true);
		}

		if (key === DropdownKeys.DUPLICATE) {
			history.push({
				pathname: "/editare-propunere/" + props.item.id,
				state: {
					activeTab: props.activeTab,
					currentPage: props.currentPage,
					pageSize: props.pageSize,
					filters: props.checkedKeys,
					searchTerm: props.searchTerm,
					sortDirection: props.sortDirection,
					sortField: props.sortField,
					isDuplicating: true,
				},
			});
		}

		if (key === DropdownKeys.APPLICANTS) {
			setShowApplicantsTable((v) => !v);
			setShowMyStudentsTable(false);
		}
	};

	return (
		<div className="bg-white rounded-md shadow-md p-4 flex flex-col gap-4">
			{(props.isProfessor && props.activeTab === "1" && !props.item.isValid) ||
			(props.isFacultyAdmin &&
				props.activeTab === "3" &&
				!props.item.isValid) ||
			(props.isDepartmentDirector &&
				props.activeTab === "4" &&
				!props.item.isValid) ||
			(props.isStudyProgramCoordinator &&
				props.activeTab === "5" &&
				!props.item.isValid) ||
			(props.isFacultyAdmin &&
				props.activeTab === "6" &&
				props.item.state === CompanyInternshipState.Invalid) ||
			(props.isCompany &&
				props.item.state === CompanyInternshipState.Invalid) ? (
				<span>{t("internships.invalidProposal")}</span>
			) : null}

			<div className="flex justify-between gap-6">
				<div className="flex flex-col gap-2">
					<Link
						to={{
							pathname: `/profil`,
							state: {
								id:
									props.location.pathname === "/propuneri-facultati"
										? props.item.professorId
										: props.location.pathname === "/propuneri-companii"
										? props.item.companyId
										: props.isProfessor
										? props.activeTab === "2" || props.activeTab === "6"
											? props.item.companyId
											: props.item.professorId
										: props.item.companyId,
								type:
									props.location.pathname === "/propuneri-facultati"
										? InternshipType.ByProfessor
										: props.location.pathname === "/propuneri-companii"
										? InternshipType.ByCompany
										: props.isProfessor
										? props.activeTab === "2" || props.activeTab === "6"
											? InternshipType.ByCompany
											: InternshipType.ByProfessor
										: InternshipType.ByCompany,
								userType: props.areFacultyInternships
									? Role.Professor
									: Role.Company,
								filters: props.checkedKeys,
								searchTerm: props.searchTerm,
								sortField: props.sortField,
								sortDirection: props.sortDirection,
								currentPage: props.currentPage,
								pageSize: props.pageSize,
								activeTab: props.activeTab,
								origin: props.location.pathname,
							},
						}}
					>
						{props.item.type === InternshipType.ByCompany && (
							<Avatar src={props.item.recruiterAvatar} />
						)}
						{((props.isProfessor && props.activeTab !== "1") ||
							!props.isProfessor) && (
							<span className="text-secondary">
								{props.item.type === InternshipType.ByProfessor
									? t("abbreviations." + props.item.professionalTitle) + " "
									: ""}{" "}
								{props.item.recruiterName}
							</span>
						)}
					</Link>

					<Link
						to={{
							pathname: "/propunere/" + props.item.id,
							search: "?type=" + props.item.type,
							state: {
								id: props.item.id,
								type: props.item.type,
								filters: props.checkedKeys,
								searchTerm: props.searchTerm,
								sortField: props.sortField,
								sortDirection: props.sortDirection,
								currentPage: props.currentPage,
								pageSize: props.pageSize,
								activeTab: props.activeTab,
								origin: props.location.pathname,
							},
						}}
						onClick={() =>
							props.actions.updateViewCount(props.item.id, props.item.type)
						}
						className="text-lg text-secondary font-bold line-clamp-2 overflow-ellipsis"
					>
						{props.item.internshipName}
					</Link>
				</div>

				{items.length > 0 && (
					<div>
						<Dropdown
							menu={{
								items,
								onClick: handleDropdownClick,
								style: { maxWidth: "360px" },
							}}
						>
							<MoreOutlined className="text-2xl" />
						</Dropdown>

						<ConfirmationModal
							modalText={
								isCompanyTrusted(props.item)
									? t("internships.finalizeMessage")
									: t("internships.markCompleteMessage")
							}
							handleFunction={() => props.actions.sendForApproval()}
							modalVisibility={props.state.markCompleteModalVisibility}
							title=""
							changeModalVisibility={() =>
								props.actions.setMarkCompleteModalVisibility(false)
							}
							spinning={props.state.spinning}
						/>

						<ConfirmationModal
							modalText={t("internships.deleteInternshipMessage")}
							handleFunction={props.actions.deleteEntry}
							modalVisibility={props.state.deleteModalVisibility}
							title=""
							changeModalVisibility={() =>
								props.actions.setDeleteModalVisibility(false)
							}
							spinning={props.state.spinning}
						/>
					</div>
				)}
			</div>

			<div className="flex flex-wrap items-center gap-2">
				{((props.isFacultyAdmin && props.activeTab === "3") ||
					(props.isDepartmentDirector && props.activeTab === "4") ||
					(props.isStudyProgramCoordinator && props.activeTab === "5")) &&
				props.item.engagementType !==
					InternshipEngagementType.ResearchInternship ? (
					<div className="flex items-center gap-2">
						<Switch
							checked={props.item.isValid}
							onChange={(checked: boolean) =>
								props.actions.onChangeProfessorInternshipValidation(
									checked,
									props.item
								)
							}
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}
							title={
								props.item.isValid
									? t("internships.invalidationToggle")
									: t("internships.validationToggle")
							}
						/>
						<span>
							{props.item.isValid
								? t("internships.invalidationToggle")
								: t("internships.validationToggle")}
						</span>
					</div>
				) : null}

				{props.isFacultyAdmin &&
				props.activeTab === "6" &&
				(props.item.state === CompanyInternshipState.Approved ||
					props.item.state === CompanyInternshipState.Invalid) ? (
					<div className={styles.alignmentContainer}>
						<Switch
							checked={props.item.state === CompanyInternshipState.Approved}
							onChange={(checked: boolean) =>
								props.actions.onChangeCompanyInternshipValidation(
									checked,
									props.item
								)
							}
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}
							title={
								props.item.isValid
									? t("internships.invalidationToggle")
									: t("internships.validationToggle")
							}
						/>
					</div>
				) : null}
			</div>

			{props.item.skillsRequired?.length > 0 && (
				<div className="flex flex-wrap gap-1 items-center">
					{props.item.skillsRequired?.map((skill) => (
						<span
							className="bg-primary rounded-full px-2 py-1 text-white"
							key={skill}
						>
							{skill}
						</span>
					))}
				</div>
			)}

			<div className="line-clamp-5 overflow-ellipsis">
				{props.item.shortDescription}
			</div>

			<InternshipActions item={props.item} activeTab={props.activeTab} />

			<div className="mt-auto">
				{isUserLogged() && !props.isStudent && (
					<>
						<div className="flex flex-col gap-2 w-full">
							{props.isDepartmentSecretary &&
							props.activeTab === "4" &&
							props.item.enrolledStudentsCount <
								props.item.initialAvailablePositions &&
							(props.item.engagementType ===
								InternshipEngagementType.BachelorsInternship ||
								props.item.engagementType ===
									InternshipEngagementType.MastersInternship) ? (
								<Select
									className="w-full"
									placeholder={t("admin.selectStudent")}
									showSearch
									value={null}
									optionFilterProp="children"
									filterOption={(input, option) =>
										option?.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0 ||
										option?.props.value
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									onChange={(event) =>
										onChangeStudent(event, props.item.internshipId)
									}
								>
									{props.item.engagementType ===
									InternshipEngagementType.BachelorsInternship
										? props.state.availableBachelorStudents?.map(
												(option: any) => (
													<Option key={option.id}>{option.name}</Option>
												)
										  )
										: props.state.availableMasterStudents?.map(
												(option: any) => (
													<Option key={option.id}>{option.name}</Option>
												)
										  )}
								</Select>
							) : null}

							{props.item.isViewOnly &&
							props.activeTab === "1" &&
							props.item.enrolledStudentsCount <
								props.item.initialAvailablePositions &&
							(props.item.engagementType ===
								InternshipEngagementType.BachelorsInternship ||
								props.item.engagementType ===
									InternshipEngagementType.MastersInternship) ? (
								<Select
									className="w-full"
									placeholder={t("admin.selectStudent")}
									showSearch
									value={null}
									optionFilterProp="children"
									filterOption={(input, option) =>
										option?.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0 ||
										option?.props.value
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									onChange={(event) =>
										onChangeStudent(event, props.item.internshipId)
									}
								>
									{props.item.engagementType ===
									InternshipEngagementType.BachelorsInternship
										? props.state.availableBachelorStudents?.map(
												(option: any) => (
													<Option key={option.id}>{option.name}</Option>
												)
										  )
										: props.state.availableMasterStudents?.map(
												(option: any) => (
													<Option key={option.id}>{option.name}</Option>
												)
										  )}
								</Select>
							) : null}

							{props.isFacultyAdmin &&
								(props.item.engagementType ===
									InternshipEngagementType.Internship ||
									props.item.engagementType ===
										InternshipEngagementType.Scholarship ||
									props.item.engagementType === InternshipEngagementType.Job ||
									props.item.engagementType ===
										InternshipEngagementType.SummerSchool) &&
								props.item.state === CompanyInternshipState.PendingApproval && (
									<div className="flex flex-col gap-2">
										<div className="flex flex-col">
											<Button
												onClick={() => {
													props.actions.setSelectedInternshipId(props.item.id);
													props.actions.setApproveModalVisibility(true);
												}}
												title={t("internships.approveInternship")}
												icon={
													<FontAwesomeIcon
														icon={solid("circle-check")}
														style={{ paddingRight: "5%" }}
													/>
												}
											>
												{t("internships.approveInternship")}
											</Button>

											<ConfirmationModal
												modalText={t("internships.approveInternshipMessage")}
												handleFunction={props.actions.approve}
												modalVisibility={props.state.approveModalVisibility}
												title=""
												changeModalVisibility={() =>
													props.actions.setApproveModalVisibility(false)
												}
												spinning={props.state.spinning}
											/>
										</div>

										<RejectInternship
											nameButton={"  " + t("internships.rejectInternship")}
											modalText={t("internships.rejectInternshipMessage")}
											title={t("internships.rejectInternship")}
											handleFunction={(description: string) =>
												props.actions.reject(props.item, description)
											}
											modalVisibility={props.state.rejectModalVisibility}
											iconButton={
												<FontAwesomeIcon
													icon={solid("ban")}
													style={{ paddingRight: "5%" }}
												/>
											}
											spinning={props.state.spinning}
										/>
									</div>
								)}

							{props.isFacultyAdmin &&
							props.item.type === InternshipType.ByCompany &&
							(props.item.engagementType ===
								InternshipEngagementType.BachelorsInternship ||
								props.item.engagementType ===
									InternshipEngagementType.MastersInternship) ? (
								<Select
									className="w-full"
									placeholder={t("admin.selectInternshipResponsible")}
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option?.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0 ||
										option?.props.value
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									defaultValue={props.item.facultyCoordinatorId}
									disabled={
										props.item.facultyCoordinatorId !== null &&
										props.item.facultyCoordinatorId !== undefined
									}
									onChange={(event) => onChange(event, props.item.id)}
								>
									{props.professorOptions?.map((option: any) => (
										<Option key={option.id}>{option.name}</Option>
									))}
								</Select>
							) : null}

							{props.isProfessor &&
								!props.isFacultyAdmin &&
								props.item.type === InternshipType.ByCompany &&
								(props.item.engagementType ===
									InternshipEngagementType.BachelorsInternship ||
									props.item.engagementType ===
										InternshipEngagementType.MastersInternship) &&
								props.item.state === CompanyInternshipState.PendingApproval &&
								props.item.facultyCoordinatorId === getUserId() && (
									<div className="flex items-center gap-2">
										<Button
											onClick={() => {
												props.actions.setSelectedInternshipId(props.item.id);
												props.actions.setAcceptCoordinationModalVisibility(
													true
												);
											}}
											title={t("internships.acceptCoordination")}
											icon={
												<FontAwesomeIcon
													icon={solid("circle-check")}
													style={{ paddingRight: "5%" }}
												/>
											}
											type={"text"}
										>
											{t("internships.acceptCoordination")}
										</Button>

										<ConfirmationModal
											modalText={t("internships.acceptCoordinationMessage")}
											handleFunction={
												props.actions.acceptInternshipCoordination
											}
											modalVisibility={
												props.state.acceptCoordinationModalVisibility
											}
											title=""
											changeModalVisibility={() =>
												props.actions.setAcceptCoordinationModalVisibility(
													false
												)
											}
											spinning={props.state.spinning}
										/>

										<Button
											onClick={() => {
												props.actions.setSelectedInternshipId(props.item.id);
												props.actions.setRejectCoordinationModalVisibility(
													true
												);
											}}
											title={t("internships.rejectCoordination")}
											icon={
												<FontAwesomeIcon
													icon={solid("ban")}
													style={{ paddingRight: "5%" }}
												/>
											}
											type={"text"}
										>
											{t("internships.rejectCoordination")}
										</Button>

										<ConfirmationModal
											modalText={t("internships.rejectCoordinationMessage")}
											handleFunction={
												props.actions.rejectInternshipCoordination
											}
											modalVisibility={
												props.state.rejectCoordinationModalVisibility
											}
											title=""
											changeModalVisibility={() =>
												props.actions.setRejectCoordinationModalVisibility(
													false
												)
											}
											spinning={props.state.spinning}
										/>
									</div>
								)}

							{props.isInternshipAdmin &&
							props.activeTab === "6" &&
							props.item.needsApproval === true &&
							props.item.engagementType ===
								InternshipEngagementType.Internship &&
							props.item.state !== CompanyInternshipState.Approved ? (
								<div>
									<Button
										onClick={() => {
											props.actions.setSelectedInternshipId(props.item.id);
											props.actions.setApproveInternshipModalVisibility(true);
										}}
										title={t("internships.approveInternshipForStudyPrograms")}
										icon={
											<FontAwesomeIcon
												icon={solid("circle-check")}
												style={{ paddingRight: "5%" }}
											/>
										}
										shape={"circle"}
										type={"text"}
									>
										{t("internships.approveInternshipForStudyPrograms")}
									</Button>

									<ConfirmationModal
										modalText={t(
											"internships.approveInternshipForStudyProgramsMessage"
										)}
										handleFunction={
											props.actions.approveInternshipForStudyPrograms
										}
										modalVisibility={
											props.state.approveInternshipModalVisibility
										}
										title=""
										changeModalVisibility={() =>
											props.actions.setApproveInternshipModalVisibility(false)
										}
										spinning={props.state.spinning}
									/>
								</div>
							) : null}

							{props.isProfessor &&
								!props.isFacultyAdmin &&
								props.item.type === InternshipType.ByCompany &&
								(props.item.engagementType ===
									InternshipEngagementType.BachelorsInternship ||
									props.item.engagementType ===
										InternshipEngagementType.MastersInternship) &&
								props.item.state === CompanyInternshipState.PendingApproval &&
								!props.item.facultyCoordinatorId && (
									<Checkbox
										onChange={() => onChange(getUserId(), props.item.id)}
									>
										{t("internships.becomeProfessorCoordinator")}
									</Checkbox>
								)}

							{props.isDean && props.activeTab !== "1" ? (
								<Select
									className="w-full"
									mode="tags"
									maxTagCount={2}
									placeholder={t("internships.addInternshipForm.studyPrograms")}
									defaultValue={props.item.studyPrograms?.map((e: any) => e.id)}
									onChange={(event) =>
										onChangeStudyPrograms(
											event,
											props.item.id,
											props.activeTab === "6" ? "company" : "faculty"
										)
									}
									notFoundContent={t(
										"internships.addInternshipForm.notFoundContent"
									)}
								>
									{props.studyProgramsList?.map((option: any) => (
										<Option key={option.id}>{option.name}</Option>
									))}
								</Select>
							) : null}

							{(props.isProfessor &&
								props.location.pathname !== "/propuneri-companii" &&
								props.activeTab === "1") ||
							(props.isCompany &&
								props.item.state === CompanyInternshipState.Approved) ||
							(props.isDepartmentSecretary && props.activeTab === "4") ? (
								<Button
									className="w-full"
									title={t("header.myStudents")}
									onClick={() => {
										setShowApplicantsTable(false);
										setShowMyStudentsTable((v) => !v);
									}}
								>
									<FontAwesomeIcon icon={solid("users")} /> &nbsp;
									{t("header.myStudents")}
								</Button>
							) : null}
						</div>

						<Modal
							className="w-full lg:w-4/5"
							open={showApplicantsTable}
							onCancel={() => setShowApplicantsTable(false)}
							footer={null}
						>
							{showApplicantsTable && (
								<InternshipApplicantsTable internship={props.item} />
							)}
						</Modal>

						<Modal
							className="w-full lg:w-4/5"
							open={showMyStudentsTable}
							onCancel={() => setShowMyStudentsTable(false)}
							footer={null}
						>
							{showMyStudentsTable && (
								<InternshipMyStudentsTable internship={props.item} />
							)}
						</Modal>
					</>
				)}
			</div>
		</div>
	);
};
