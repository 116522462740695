// @ts-nocheck

import { Tabs } from "antd";
import ProfileTabForm from "./ProfileTabForm";
import PersonalTabForm from "./PersonalTabForm";

export default function EditStudent() {
	const tabs = [
		{
			label: "Date Profil",
			key: "0",
			children: <ProfileTabForm />,
		},
		{
			label: "Date personale",
			key: "1",
			children: <PersonalTabForm />,
		},
	];

	return (
		<div className="max-w-[900px] mx-auto px-4 py-20">
			<Tabs items={tabs} />
		</div>
	);
}
