import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import {
	useIsAdministrativeAccordsResponsible,
	useIsDean,
	useIsFacultyContactsResponsible,
	useIsRector,
} from "../../../utils/utilFunctions";
import { Link, useHistory, useLocation } from "react-router-dom";
import { CompanyDTO, Role } from "../../../Api";
import { useQuery, useQueryClient } from "react-query";
import {
	getAllCompanies,
	getCompaniesQuery,
	getCompanyFilters,
} from "../../../utils/reactQueriesConstants";
import {
	deleteCompany,
	getCompaniesFilter,
	getCompaniesV2,
} from "../../../Requests/company-requests";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import {
	Button,
	Dropdown,
	Menu,
	MenuProps,
	Pagination,
	Table,
	Typography,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
	downloadAllCompaniesContactsReport,
	downloadCompanyContactsReport,
	downloadCompanyFacultiesAccordReport,
} from "../../../utils/downloadReportUtils";
import { getAllCompaniesForFacultyV2 } from "../../../Requests/faculty-requests";
import { ActionKeyEnum, CompaniesViewProps } from "./CompaniesView.types";
import { AddUpdateCompanyModalForm } from "../../Forms";
import Filters from "../../Filters";
import useQueryFilters from "../../../Hooks/useQueryFilters";

export const CompaniesView = (props: CompaniesViewProps) => {
	const { t } = useTranslation();
	const isRector = useIsRector();
	const isDean = useIsDean();
	const isAdministrativeAccordsResponsible =
		useIsAdministrativeAccordsResponsible();
	const isFacultyContactsResponsible = useIsFacultyContactsResponsible();
	const location = useLocation();
	const history = useHistory();
	const [selectedCompany, setSelectedCompany] = useState<CompanyDTO | null>(
		null
	);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const queryClient = useQueryClient();
	const invalidateCompaniesQuery = async () => {
		await queryClient.invalidateQueries(getCompaniesQuery);
	};
	const query = useQueryFilters({});

	let locale = {
		emptyText: t("tableText.noCompanies"),
	};

	const handleDelete = (id: string) => {
		deleteCompany(id)
			.then(() => {
				invalidateCompaniesQuery();

				openNotification(
					t("companies.companyDeleted"),
					t("companies.companyDeleted"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((ex: any) => {
				if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
					openNotification(
						t("account.conflict"),
						t("companies.notFoundCompany"),
						NOTIFICATION_TYPES.ERROR
					);
				} else if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
					openNotification(
						t("account.error"),
						t("account.notAllowed"),
						NOTIFICATION_TYPES.ERROR
					);
				} else {
					openNotification(
						t("account.error"),
						t("account.unknownError"),
						NOTIFICATION_TYPES.ERROR
					);
				}
			});
	};

	const getActions = (record: CompanyDTO): ItemType[] | undefined => {
		let actions: MenuProps["items"] = [];

		if (record.isContact) {
			actions.push({
				label: t("companies.edit"),
				key: ActionKeyEnum.EditCompany + "Companies",
				icon: (
					<FontAwesomeIcon
						icon={solid("pen-to-square")}
						style={{ fontSize: "15px" }}
					/>
				),
				onClick: () => {
					setSelectedCompany(record);
					setIsModalVisible(true);
					setIsEdit(true);
				},
			});

			actions.push({
				label: t("companies.delete"),
				key: ActionKeyEnum.DeleteCompany + "Companies",
				icon: (
					<FontAwesomeIcon icon={solid("trash")} style={{ fontSize: "15px" }} />
				),
				onClick: () => {
					handleDelete(record?.id!);
				},
			});
		}

		actions.push({
			label: t("companies.contacts"),
			key: ActionKeyEnum.Contacts + "Companies",
			icon: (
				<FontAwesomeIcon
					icon={solid("user-group")}
					style={{ fontSize: "15px" }}
				/>
			),
			onClick: () =>
				history.push("contacte/" + record.id, {
					// currentPage,
					// pageSize,
					// searchTerm,
					// filters: checkedKeys,
				}),
		});

		actions.push({
			label: t("companies.contactsReport"),
			key: ActionKeyEnum.ContactsReport + "Companies",
			icon: (
				<FontAwesomeIcon
					icon={solid("download")}
					style={{ fontSize: "15px" }}
				/>
			),
			onClick: () =>
				downloadCompanyContactsReport(
					t("companies.contactsReport") + ` ${record.name}`,
					t,
					query.filters["search"] as string,
					record.id
				),
		});

		return actions;
	};

	const companyColumns = [
		{
			title: t("account.name"),
			dataIndex: "name",
			fixed: "left" as "left",
			// sorter: (a: CompanyDTO, b: CompanyDTO) => {
			// 	if (!a.name || !b.name) return -1;
			// 	return a.name.localeCompare(b.name);
			// },
			render: (_text: string | undefined, record: any) => (
				<Link
					to={{
						pathname: `/profil`,
						state: {
							id: record.id,
							userType: Role.Company,
							origin: location.pathname,
							// oldSearchTerm: searchTerm,
							// pageSize: pageSize,
							// currentPage: currentPage,
							// filters: checkedKeys,
						},
					}}
				>
					<b>{record.commercialName}</b>
				</Link>
			),
		},
		{
			title: t("companies.cui"),
			dataIndex: "cui",
			key: "cui",
			// sorter: (a: CompanyDTO, b: CompanyDTO) => {
			// 	if (!a.cui || !b.cui) return -1;
			// 	return a.cui.localeCompare(b.cui);
			// },
		},
		{
			title: t("companies.facultiesOfInterest"),
			dataIndex: "facultiesOfInterestAcronyms",
			hidden: isDean || isFacultyContactsResponsible,
			render: (_text: string | undefined, record: any) => {
				return record.facultiesOfInterestAcronyms?.join(", ");
			},
		},
		{
			title: t("companies.actions"),
			dataIndex: "actions",
			fixed: "right" as "right",
			width: 90,
			render: (_text: string | undefined, record: any) => (
				<div>
					<Dropdown
						placement="bottomLeft"
						overlay={<Menu items={getActions(record)} />}
					>
						<FontAwesomeIcon
							icon={solid("bars")}
							style={{
								cursor: "pointer",
								height: "1rem",
								padding: "6px 0px",
								marginLeft: "13px",
							}}
						/>
					</Dropdown>
				</div>
			),
		},
	].filter((item) => !item.hidden);

	const openNotificationErrorFetchUsers = (ex: any) => {
		if (ex.status) {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.serverFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		} else {
			openNotification(
				t("usersText.errorTexts.failedUserGet"),
				t("usersText.errorTexts.networkFailedDescription"),
				NOTIFICATION_TYPES.ERROR
			);
		}
	};

	const { data: companyData, isLoading: loadingCompanies } = useQuery(
		[
			getAllCompanies,
			isAdministrativeAccordsResponsible,
			isRector,
			isDean,
			isFacultyContactsResponsible,
			query.filters,
		],
		() => {
			if (isRector || isAdministrativeAccordsResponsible) {
				return getCompaniesV2(query.filters);
			} else if (isDean || isFacultyContactsResponsible) {
				return getAllCompaniesForFacultyV2(query.filters);
			}
		},
		{
			onError: openNotificationErrorFetchUsers,
		}
	);

	const openFilterErrorNotification = (_error: any) => {
		openNotification(
			t("usersText.errorTexts.error"),
			t("usersText.errorTexts.filterDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: companyFilters } = useQuery(
		[getCompanyFilters, isAdministrativeAccordsResponsible, isRector],
		() => {
			if (isRector || isAdministrativeAccordsResponsible)
				return getCompaniesFilter();
		},
		{
			onError: openFilterErrorNotification,
		}
	);

	const onUpdate = useCallback(
		(values: { [key: string]: string[] }) => {
			query.update(values, true);
		},
		[query]
	);

	const onPageUpdate = useCallback(
		(page: number, pageSize: number) => {
			query.update({
				page: page.toString(),
				pageSize: pageSize.toString(),
			});
		},
		[query]
	);

	return (
		<div className="px-4 pb-10 flex flex-col">
			<div className="flex items-center gap-2 flex-wrap">
				<AddUpdateCompanyModalForm
					visibility={isModalVisible}
					record={selectedCompany}
					isEdit={isEdit}
					onCloseCallBack={() => {
						setIsModalVisible(false);
						setIsEdit(false);
						setSelectedCompany(null);
						return;
					}}
				/>

				<Button
					type="primary"
					onClick={() =>
						downloadCompanyFacultiesAccordReport(
							t("companies.companyFacultiesAccordReport"),
							t
						)
					}
					title={t("companies.companyFacultiesAccordReport")}
				>
					{t("companies.companyFacultiesAccordReport")}
				</Button>

				<Button
					type="primary"
					onClick={() =>
						downloadAllCompaniesContactsReport(t("companies.contactsReport"), t)
					}
					title={t("companies.contactsReport")}
				>
					{t("companies.contactsReport")}
				</Button>
			</div>

			<Filters
				filters={companyFilters ?? []}
				searchFields={[{ name: "search", label: t("search.byName") }]}
				onUpdate={onUpdate}
				hasSort={false}
			/>

			<div className="flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{companyData?.totalCount} rezultate
					</Typography.Title>
					{!loadingCompanies && (
						<Pagination
							defaultCurrent={companyData?.page}
							defaultPageSize={companyData?.pageSize}
							total={companyData?.totalCount}
							onChange={onPageUpdate}
							locale={{ items_per_page: t("pagination") }}
						/>
					)}
				</div>

				<Table
					locale={locale}
					columns={companyColumns}
					dataSource={companyData?.data ?? []}
					pagination={false}
					scroll={{ x: "calc(700px + 50%)", y: 420 }}
					rowKey={(record) => record.id!}
				/>
			</div>
		</div>
	);
};
