import { Controller, useForm } from "react-hook-form";
import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { getStudentPersonalDataForStudent } from "../../../../Requests/student-personal-data-requests";
import { getUserId } from "../../../../utils/utilFunctions";
import { getStudentPersonalData } from "../../../../utils/reactQueriesConstants";
import { StudentPersonalDataDTO } from "../../../../Api";

const PersonalTabForm = () => {
	const { t } = useTranslation();
	const { data: studentState } = useQuery(
		[getStudentPersonalData],
		() => {
			return getStudentPersonalDataForStudent(getUserId());
		},
		{
			refetchOnWindowFocus: false,
		}
	);
	const { control, reset, handleSubmit } = useForm<StudentPersonalDataDTO>({
		defaultValues: studentState,
	});

	useEffect(() => {
		reset(studentState);
	}, [reset, studentState]);

	const handleSave = (data: StudentPersonalDataDTO) => {
		console.log(data);
	};

	return (
		<Form layout="vertical" className="flex flex-col gap-6">
			<div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 w-full">
				<Controller
					control={control}
					name="cnp"
					render={({ field }) => (
						<Form.Item label="CNP">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="passport"
					render={({ field }) => (
						<Form.Item label="Pasaport">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="residencePermit"
					render={({ field }) => (
						<Form.Item label="Permis de sedere">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="birthPlace"
					render={({ field }) => (
						<Form.Item label="Locul nasterii">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="city"
					render={({ field }) => (
						<Form.Item label="Oras">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="county"
					render={({ field }) => (
						<Form.Item label="Judet">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="streetName"
					render={({ field }) => (
						<Form.Item label="Nume strada">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="streetNumber"
					render={({ field }) => (
						<Form.Item label="Numar oras">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="building"
					render={({ field }) => (
						<Form.Item label="Bloc">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="floor"
					render={({ field }) => (
						<Form.Item label="Etaj">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="apartment"
					render={({ field }) => (
						<Form.Item label="Ap.">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="identitySeries"
					render={({ field }) => (
						<Form.Item label="Serie buletin">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>

				<Controller
					control={control}
					name="identityNumber"
					render={({ field }) => (
						<Form.Item label="Numar buletin">
							<Input value={field.value!} onChange={field.onChange} />
						</Form.Item>
					)}
				/>
			</div>

			<div className="flex justify-end">
				<Button type="primary" onClick={handleSubmit(handleSave)}>
					{t("account.save")}
				</Button>
			</div>
		</Form>
	);
};

export default PersonalTabForm;
